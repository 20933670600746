import React, { useState } from "react";
import OptionSwiper from "../OptionSwiper";
import Sublocation from "../lists/locationsList";
import Equipements from "../lists/equipementsList";
import Interventions from "../lists/interventionsList";
import { localisationOptions } from "../../constant/constant";
import LocalisationTable from "../localisation/LocalisationTable";
import StocksList from "../lists/stocksList";
import StaticMap from "../StaticMap";
export default () => {
  const [activeTab, setActiveTab] = useState("cart");
  return (
    <div className="w-[500px] flex flex-col flex-1 gap-2">
      <OptionSwiper
        options={localisationOptions}
        {...{ activeTab, setActiveTab }}
      />
      <div className="overflow-auto">
        <RenderTabs activeTab={activeTab} />
      </div>
    </div>
  );
};

const RenderTabs = ({ activeTab }) => {
  switch (activeTab) {
    case "cart":
      return <StaticMap />;
    case "sublocation":
      return <Sublocation />;
    case "equipements":
      return <Equipements />;
    case "interventions":
      return <Interventions />;
    case "documents":
      return <LocalisationTable />;
    case "stocks":
      return <StocksList />;
  }
};
