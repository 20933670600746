import React, { useState } from "react";
import BigSidebar from "../../components/BigSidebar";
import { Navigate, Outlet } from "react-router-dom";
import { useAppContext } from "../../context/Context";
import StoreModal from "../../components/StoreModal";

const SharedLayout = () => {
  const { sidebar, userData, showModal } = useAppContext();
  const [expanded, setExpanded] = useState(false);

  if (!userData) {
    return <Navigate to={"/signin"} />;
  }
  return (
    <div className="bg-white text-dark">
      <StoreModal showModal={showModal} />

      <div className="flex">
        <BigSidebar
          expanded={expanded}
          setExpanded={setExpanded}
          sidebar={sidebar}
        />
        <div
          className={`${expanded ? "ml-[240px]" : "ml-[64px]"
            } flex-[7] min-h-0 bg-white-light rounded-tl-3xl shadow-3xl shadow-inner`}
        >
          <Outlet context={[expanded]} />
        </div>
      </div>
    </div>
  );
};

export default SharedLayout;
