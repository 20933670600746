import React from 'react'
import { tabsClientPreview } from '../../constant/constant'
import InputField from '../InputField'
import Icon from '../Icon'
import PhoneInputField from '../PhoneInput'
import './style.css'
import DrawerTabs from './DrawerTabs'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useTranslation } from "react-i18next";

const ClientDrawer = ({ setSelectedTab, selectedTab, formData, handleChange, handelAntDValues, handleSubmit, handleLocationChange }) => {
    const { t } = useTranslation();
    return (
        <div className='flex flex-col gap-5'>
            <DrawerTabs data={tabsClientPreview} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            <form onSubmit={handleSubmit} className='flex flex-col gap-7'>
                <InputField handleChange={handleChange} value={formData.client} type={"text"} name={"client"} placeholder={t("client.drawer.client")} />
                <div className='flex gap-5 w-full'>
                    <InputField handleChange={handleChange} value={formData.firstName} type={"text"} name={"firstName"} placeholder={t("name")} />
                    <InputField handleChange={handleChange} value={formData.name} type={"text"} name={"name"} placeholder={t("lastName")} />
                </div>
                {/* <InputField handleChange={handleChange} value={formData.address} type={"text"} name={"address"} placeholder={t("interventionAdress")} /> */}
                <div className='relative'>
                    
                    <div className={`absolute left-3 transition-all -top-3 bg-white text-sm  px-1 text-dark font-medium z-10 `} >
                        {t("address")}
                    </div>
                    <GooglePlacesAutocomplete
                        selectProps={{
                        value: formData.address,
                        onChange: handleLocationChange,
                        styles: {
                            input: (provided) => ({
                            ...provided,
                            margin: '8px',
                            }),
                            placeholder: (provided) => ({
                            ...provided,
                            margin: '8px',
                            }),
                            option: (provided) => ({
                            ...provided,
                            }),
                            singleValue: (provided) => ({
                            ...provided,
                            margin: '8px',
                            }),
                            control: (provided) => ({
                                ...provided,
                                borderRadius: '8px',
                            }),
                        },
                        }}
                    />
                </div>
               
                
                <InputField handleChange={handleChange} value={formData.email} type={"email"} name={"email"} placeholder={t("email")} />
                <div className='flex gap-5'>
                    <PhoneInputField handleChange={handelAntDValues} value={formData.cellNumber} type={"number"} name={"cellNumber"} placeholder={"23-32-323-2"} />
                    <PhoneInputField handleChange={handelAntDValues} value={formData.landlineNumber} type={"number"} name={"landlineNumber"} placeholder={"23-32-323-2"} />
                </div>
            </form>
        </div>
    )
}

export default ClientDrawer