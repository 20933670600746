import React, { useState } from "react";
import OptionSwiper from "../OptionSwiper";
import Sublocation from "../lists/locationsList";
import Equipements from "../lists/equipementsList";
import Interventions from "../lists/interventionsList";
import { clientsOption } from "../../constant/constant";
import IntervantionsDetails from "../clients/IntervantionsDetails";

export default ({ interventions }) => {
  const [activeTab, setActiveTab] = useState("interventions");
  return (
    <div className="w-[500px] flex flex-col flex-1 gap-2">
      <OptionSwiper options={clientsOption} {...{ activeTab, setActiveTab }} />
      <div className="overflow-auto">
        <RenderTabs activeTab={activeTab} interventions={interventions} />
      </div>
    </div>
  );
};

const RenderTabs = ({ activeTab, userDetail, interventions }) => {
  switch (activeTab) {
    case "locations":
      return <Sublocation />;
    case "equipement":
      return <Equipements />;
    case "interventions":
      // return <Interventions interventions={interventions} />;
      return <IntervantionsDetails />;
    default:
      return <div>{activeTab}</div>;
  }
};
