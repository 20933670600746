import React from 'react';
import { Drawer } from 'antd';
const CustomDrawer = ({ children, onClose, open, width, footerEle, title }) => {

    return (
        <>
            <Drawer title={title} footer={footerEle} onClose={onClose} width={width} open={open}>
                {children}
            </Drawer>
        </>
    );
};
export default CustomDrawer;