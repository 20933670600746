import React from "react";
import { Collapse } from "antd";
import Icon from "./Icon";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setCurrentOpenedIntervenant } from "../redux/slices/appConfigSlice";

const IntervenantsCard = ({ intervenants, editIntervenant, deleteIntervenant }) => {

   const dispatch = useDispatch();

   const handleCollapseChange = (openKeys) => {
      const openItems = intervenants.filter(intervenant => openKeys.includes(intervenant._id));
      const intervenant = openItems?.[0] || null;
      dispatch(setCurrentOpenedIntervenant(intervenant));
   };

   return (
      <Collapse
         collapsible="header"
         defaultActiveKey={["1"]}
         expandIconPosition={"end"}
         accordion
         className="collapsy"
         items={refactorClientList(intervenants, editIntervenant, deleteIntervenant)}
         onChange={handleCollapseChange}
      />
   );
};

export default IntervenantsCard;

const refactorClientList = (intervenants, editIntervenant, deleteIntervenant) => {
   return intervenants?.map((intervenant) => {
      const key = intervenant._id;
      const label = (
         <div
            className={`flex flex-col transition ease-in cursor-pointer w-full`}
         >
            <div className="flex items-center gap-2">
               {intervenant.image ? (
                  <img className="w-12 rounded-md" src={`${intervenant.image}`} alt="image" />
               ) : (
                  <div className="w-12 rounded-md bg-blue-950 h-12 text-white flex items-center justify-center text-xl">
                     {intervenant.firstName.charAt(0)}
                  </div>
               )}
               
                  {/* TODO: use intervenant type to test instead */}
               <p className="text-[16px] font-bold">{intervenant?.companyName ? intervenant?.companyName : `${intervenant?.firstName} ${intervenant?.lastName}`} </p>
            </div>
            {/* <div className="mt-2 flex items-center gap-2">
               <div onClick={() => editIntervenant(intervenant)}>
                  <Icon name={"Pencil"} size={18} color={"gray"} />
               </div>
               <div onClick={() => deleteIntervenant(intervenant)}>
                  <Icon name={"Trash2"} size={18} color={"red"} />
               </div>
            </div> */}
         </div>
      );
      const children = <IntervenantDetail intervenant={intervenant} />;

      return {
         key,
         label,
         children,
      };
   });
};

const IntervenantDetail = ({ intervenant }) => {
   const { t } = useTranslation();
   return (
      <div className='flex flex-col w-full gap-5'>
         <div className='flex flex-col gap-5'>
            <div className='py-4 w-full flex gap-4'>
               <div className='px-1 flex flex-col gap-4'>
                  {/* TODO: use intervenant type to test instead */}
                  {intervenant?.companyName && 
                     <div className='flex items-center gap-4'>
                        <Icon name={"Building2"} color="gray" />
                        <div className=''>
                           <h4 className='text-slate-700 font-bold text-[14px] '>Company</h4>
                           <p className='text-gray-500 text-[12px]'>{intervenant?.companyName }</p>
                        </div>
                     </div>
                  }
                  <div className='flex items-center gap-4'>
                     <Icon name={"Mail"} color="gray" />
                     <div className=''>
                        <h4 className='text-slate-700 font-bold text-[14px] '>Email</h4>
                        <p className='text-gray-500 text-[12px]'>{intervenant?.email || "-"}</p>
                     </div>
                  </div>

                  <div className='flex items-center gap-4'>
                     <Icon name={"Phone"} color="gray" />
                     <div className=''>
                        <h4 className='text-slate-700 font-bold text-[14px] '>{t("phoneNumber")}</h4>
                        <p className='text-gray-500 text-[12px]'>{intervenant?.phoneNumber || "-"}</p>
                     </div>
                  </div>

                  <div className='flex items-center gap-4'>
                     <Icon name={"ShieldPlus"} color="gray" />
                     <div className=''>
                        <h4 className='text-slate-700 font-bold text-[14px] '>Professions</h4>
                        <p className='text-gray-500 text-[12px]'>{intervenant?.professions || "-"}</p>
                     </div>
                  </div>

                  <div className='flex items-center gap-4'>
                     <Icon name={"Link"} color="gray" />
                     <div className=''>
                        <h4 className='text-slate-700 font-bold text-[14px] '>Zones</h4>
                        <p className='text-gray-500 text-[12px]'>{intervenant?.zones || "-"}</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
