import { Outlet } from "react-router";
import useOrgDetail from "../hooks/useOrgDetail";

const MainOrgLayout = () => {

   useOrgDetail();

   return <Outlet />
};

export default MainOrgLayout;