import React, { useState } from "react";
import Page from "./Page";
import Icon from "../components/Icon";
import { equipmentOption, options } from "../constant/constant";
import OptionSwiper from "../components/OptionSwiper";
import CustomDrawer from "../components/CustomDrawer";
import EquipmentDrawer from "../components/drawer/EquipmentDrawer";
import DrawerHeader from "../components/drawer/DrawerHeader";
import FooterEle from "../components/drawer/FooterEle";
import EquipmentsList from "../components/lists/equipementsList";
import api from "../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { addEquipment, deleteEquipment, updateEquipment } from "../redux/slices/appConfigSlice";
import CustomModal from "../components/modals/CustomModal";
import ConfirmationModal from "../components/modals/ConfirmationModal";

const Equipment = () => {

  const { userDetail } = useSelector(state => state.appConfigReducer);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editEquipment, setEditEquipment] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const initialFormState = {
    description: "",
    name: "",
    place: options[0].value,
    profession: options[2].value,
    brand: "",
    category: [],
    model: "",
    serialNumber: "",
    year: "",
  }

  const [formData, setFormData] = useState(initialFormState);

  const [file, setFile] = useState(null);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (e.target.name === "addressCheckbox") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handelAntDValues = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onCloseModal = () => {
    setSelectedEquipment(null);
    setShowModal(false);
  }

  const onCloseDrawer = () => {
    setEditEquipment(false);
    setSelectedEquipment(null);
    setDrawerOpen(false)
  }

  const onEditEquipment = (equipment) => {

    const data = {};
    for (let key in equipment) {
      if (formData.hasOwnProperty(key)) {
        data[key] = equipment[key];
      }
    }

    setFormData(data);
    setEditEquipment(true);
    setSelectedEquipment(equipment);

    setFile(null)
    setDrawerOpen(true);
  }

  const onDeleteEquipment = (equipment) => {
    setSelectedEquipment(equipment);
    setShowModal(true);
  }

  const handleDeleteEquipment = async () => {
    if (!selectedEquipment || !selectedEquipment?._id) return;

    try {
      const res = await api.delete(`/equipment/${selectedEquipment._id}`);
      dispatch(deleteEquipment(selectedEquipment._id))
      setFile(null);
      setShowModal(false);
    } catch (error) {
      console.log("Error occured while deleting equipment", error);
    }
  }

  const handleEditEquipment = async () => {
    if (!editEquipment || !selectedEquipment || !selectedEquipment?._id) return;

    const equipmentFormData = new FormData();

    Object.keys(formData).forEach(key => {
      if (Array.isArray(formData[key])) {
        formData[key].forEach((item, index) => {
          equipmentFormData.append(`${key}[${index}]`, item);
        });
      } else {
        equipmentFormData.append(key, formData[key]);
      }
    });

    if (file) equipmentFormData.append("image", file);

    try {
      const res = await api.put(`/equipment/${selectedEquipment._id}`, equipmentFormData);
      dispatch(updateEquipment(res.data))
      setFormData(initialFormState);
      setFile(null);
      setDrawerOpen(false);
    } catch (error) {
      console.log("Error occured while updating equipment", error);
    }
  }

  const handleSubmit = async () => {
    if (editEquipment) {
      handleEditEquipment();
      return;
    }

    for (let key in formData) {
      if (key == "category") {
        if (!formData[key].length) return;
      }
      else {
        if (!formData[key]) return;
      }
    }

    if (!file) return;

    const equipmentFormData = new FormData();

    Object.keys(formData).forEach(key => {
      if (Array.isArray(formData[key])) {
        formData[key].forEach((item, index) => {
          equipmentFormData.append(`${key}[${index}]`, item);
        });
      } else {
        equipmentFormData.append(key, formData[key]);
      }
    });

    equipmentFormData.append("image", file);

    try {
      const res = await api.post("/equipment", equipmentFormData);
      dispatch(addEquipment(res.data))
      setFormData(initialFormState);
      setFile(null);
      setDrawerOpen(false);
    } catch (error) {
      console.log("Error occured while creating equipment", error);
    }
  };

  return (
    <Page>
      <div className="flex w-full h-[calc(100vh-25px)] gap-5">
        <div className="w-full md:w-[500px] lg:w-[400px] xl:w-[500px] rounded-lg  flex flex-col gap-4 p-0">
          <div className="flex items-center justify-between h-16">
            <h1 className="text-[#090F4E] text-2xl font-bold leading-10">
              Equipment
            </h1>
            <button
              onClick={() => setDrawerOpen(true)}
              className="p-2 bg-orange hover:shadow-lg h-10  transition ease-in text-white rounded flex items-center justify-center"
            >
              <Icon name="Plus" />
            </button>
          </div>
          <EquipmentsList
            equiptment={userDetail?.equipment}
            editEquipment={onEditEquipment}
            deleteEquipment={onDeleteEquipment}
          />
        </div>
        <div className="w-[500px] flex-1">
          <OptionSwiper options={equipmentOption} />
        </div>
      </div>
      <CustomDrawer
        open={drawerOpen}
        width={600}
        onClose={onCloseDrawer}
        footerEle={
          <FooterEle
            label={"Image de couv erture"}
            icon={"Image"}
            handleSubmit={handleSubmit}
            file={file}
            setFile={setFile}
            edit={editEquipment}
          />
        }
        title={
          <DrawerHeader icon={"Lightbulb"} title={"Création de l'equipement"} />
        }
      >
        <EquipmentDrawer
          handleChange={handleChange}
          formData={formData}
          handelAntDValues={handelAntDValues}
          options={options}
        />
      </CustomDrawer>

      <CustomModal open={showModal}>
        <ConfirmationModal
          text="Are you sure you want to delete this equipment ?"
          onCancel={onCloseModal}
          onOk={handleDeleteEquipment}
        />
      </CustomModal>
    </Page>
  );
};

export default Equipment;
