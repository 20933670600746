import { configureStore } from "@reduxjs/toolkit";
import clientReducer from "./slices/clientSlice";
import appConfigReducer from "./slices/appConfigSlice";
import interventionReducer from "./slices/interventionSlice";
import locationReducer from "./slices/locationSlice";

export default configureStore({
  reducer: {
    clientReducer,
    appConfigReducer,
    interventionReducer,
    locationReducer,
  },
});
