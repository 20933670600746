import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../Icon";
import SelectButton from "../SelectButton";
import InputComp from "../InputComp";
import { buttonInterventions } from "../../constant/constant";
import IntervenantInterventionNavigator from "./navigators/IntervenantInterventionNavigator";
import { setInterventions, setSelectedIntervention } from "../../redux/slices/interventionSlice";
import { Tag } from "antd";

const AssignedInterventions = () => {

   const { userDetail } = useSelector(state => state.appConfigReducer);
   const { interventions, selectedIntervention } = useSelector(state => state.interventionReducer);

   const [selectedBtn, setSelectedBtn] = useState(0);
   const [inputData, setInputData] = useState("");
   const [checkedButtons, setCheckedButtons] = useState([]);

   const dispatch = useDispatch();

   const onClickHandler = (intervention) => {
      dispatch(setSelectedIntervention(intervention))
   }

   const statusTagColor = (status="new") => {
      if(status == 'new') return 'magenta';
      if(status == 'in progress') return 'orange';
      if(status == 'completed') return 'green';
      if(status == 'cancelled') return 'red';
    }

   useEffect(() => {
      if (userDetail && userDetail?.interventions) {
         dispatch(setInterventions(userDetail.interventions));
      }
   }, [userDetail])

   return (
      <div className="h-screen p-3 flex gap-7 w-full">
         <div className="h-full flex flex-col gap-4">
            <h1 className="text-[#090F4E] text-2xl font-bold leading-10 py-3">
               Interventions
            </h1>
            <div className="h-full rounded-lg shadow Clientscrollbar bg-white flex flex-col gap-4 w-[500px] overflow-auto">

               <div className="flex flex-col gap-4 w-full p-4">
                  <InputComp setInputData={setInputData} inputData={inputData} />
                  <SelectButton
                     setSelecteBtn={setSelectedBtn}
                     selecteBtn={selectedBtn}
                     data={buttonInterventions}
                     checkedButtons={checkedButtons}
                     setCheckedButtons={setCheckedButtons}
                  />
                  {/* <button className="rounded-full px-5 py-1 w-fit border-[1.4px] text-[12px] flex justify-center items-center gap-2">
                     A assigner
                  </button> */}
               </div>

               {
                  interventions?.map((intervention) => {
                     return (
                        <div
                           key={intervention._id}
                           className={`flex transition ease-in cursor-pointer relative w-full items-center px-4 py-8 border-b border-gray-200 hover:bg-gray-100 ${selectedIntervention?._id == intervention._id && "bg-gray-100"}`}
                           onClick={() => onClickHandler(intervention)}
                        >
                           <div className="flex flex-col">
                              <div className="flex items-center gap-2"> 
                              <h3 className="text-[16px] font-bold">{intervention?.title}</h3>
                              <Tag color={statusTagColor(intervention?.status)}>{intervention?.status}</Tag>
                              </div>
                              <p>{intervention?.description}</p>
                              {
                                 userDetail &&
                                 <p>{`${userDetail?.firstName} ${userDetail?.lastName}`}</p>
                              }
                              <div className="flex w-full justify-self-auto gap-1 ">
                                 <p className="text-[12px] font-bold flex gap-1 items-center text-gray-500 absolute bottom-2 right-2">
                                    <Icon name={"MapPin"} size={15} color={"gray"} />
                                    {intervention?.place}
                                 </p>
                              </div>
                           </div>
                        </div>
                     )
                  })
               }
            </div>
         </div>

         <div className="flex-1 flex">
            <IntervenantInterventionNavigator />
         </div>
      </div>

   )
};

export default AssignedInterventions;
