import React from "react";
import Button from "../components/Button";
import Preview from "../components/previews/Preview";

const Page = ({ title, actions = [], children }) => {
  return (
    <div className="h-[calc(100vh-70px)]">
      <div className="p-3 flex flex-col gap-7 w-full">
        {/* <div className="flex justify-between items-center">
          <h1 className="text-dark text-2xl font-bold leading-10">
            {title}
          </h1>
          <div className="flex items-center justify-center gap-4">
            {actions.map(({ action, name, type, icon }, index) => {
              return (
                <Button
                  key={index}
                  onClick={action}
                  value={name}
                  type={type}
                  icon={icon}
                />
              );
            })}
          </div>
        </div> */}
        {/* keep them for later */}
        {/*  <div className="flex gap-3 flex-wrap justify-between">
          <CampaignCard />
          <CampaignCard />
          <CampaignCard />
          <CampaignCard />
          <CampaignCard />
        </div> */}
        {children}
        <Preview />
      </div>
    </div>
  );
};

export default Page;
