import React from 'react';
import { AlignRight } from 'lucide-react';
import './style.css';
import { Checkbox, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
const SelectButton = ({ selecteBtn, setSelecteBtn, data, checkedButtons, setCheckedButtons }) => {
  const { t } = useTranslation();
  return (
    <div className='flex gap-4  items-center'>
      <AlignRight />
      <div className='flex gap-4 w-full  overflow-x-auto select-scrollbar'>
        {data?.map((d, i) => (
          <button
            key={i}
            onClick={() => setSelecteBtn(i)}
            className={`rounded-full px-5 py-1 border-[1.4px] text-[12px] flex justify-center items-center gap-2 ${selecteBtn === i
              ? 'bg-orange text-white'
              : 'border-gray-400 bg-white text-black'
              } mb-2 `}
          >
            <ConfigProvider
              theme={{
                token: {
                  borderRadiusSM: 20,
                },
              }}
            >
              {d.checkbox && <Checkbox checked={checkedButtons.includes(d.code)} onChange={() => {
                if (checkedButtons?.includes(d.code)) {
                  let arr = checkedButtons.filter(btn => btn !== d.code);
                  setCheckedButtons(arr)
                } else {
                  setCheckedButtons([...checkedButtons, d.code])
                }
              }} ></Checkbox>}
            </ConfigProvider>
            {d.label ? t(d.label) : d.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectButton;
