import React from "react";
import Icon from "../Icon";

const IndividualIntervantion = ({ data }) => {
  return (
    <div>
      <div className="ms-[50px] pb-4 px-1 flex flex-col gap-4">
        <div className="flex items-center gap-4">
          <div>
            <Icon name={"ShieldAlert"} color="gray" />
          </div>
          <div>
            <h4 class="text-slate-700 font-bold text-[14px] ">
              {data?.criticality}
            </h4>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div>
            <Icon name={"BookType"} color="gray" />
          </div>
          <div>
            <h4 class="text-slate-700 font-bold text-[14px] ">{data?.title}</h4>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div>
            <Icon name={"Quote"} color="gray" />
          </div>
          <div>
            <h4 class="text-slate-700 font-bold text-[14px] ">
              {data?.description}
            </h4>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div>
            <Icon name={"MapPin"} color="gray" />
          </div>
          <div>
            <h4 class="text-slate-700 font-bold text-[14px] ">{data?.place}</h4>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          {
            data?.firstName &&
            <div className="flex gap-4">
              <div>
                <Icon name={"User"} color="gray" />
              </div>

              <div>
                <h4 class="text-slate-700 font-bold text-[14px] ">
                  {`${data?.firstName} ${data?.lastName}`}
                </h4>
              </div>
            </div>
          }
          {data?.startDate && (
            <div className="flex items-center gap-4">
              <div>
                <Icon name={"Calendar"} color="gray" />
              </div>
              <div>
                <h4 class="text-slate-700 font-bold text-[14px] ">
                  Start:- {data.startDate}
                </h4>
              </div>
            </div>
          )}

          {data?.endDate && (
            <div className="flex items-center gap-4">
              <div>
                <Icon name={"Calendar"} color="gray" />
              </div>
              <div>
                <h4 class="text-slate-700 font-bold text-[14px] ">
                  End:- {data.endDate}
                </h4>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IndividualIntervantion;
