import { Navigate, Outlet } from "react-router";
import useHasRole from "../hooks/useHasRole";

const RolebasedLayout = ({ roles }) => {

   const hasRole = useHasRole(roles);

   return hasRole ? <Outlet /> : <Navigate to="/not-found" />;
};

export default RolebasedLayout;
