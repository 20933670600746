import Icon from "../components/Icon";

export const tabsLabel = {
  clients: {
    label: "clients",
    code: "clients",
    icon: "Building2"
  },
  projectManagers: {
    locked: true,
    label: "projectManagers",
    code: "projectManagers",
    icon: "BriefcaseBusiness"
  },
  documents: {
    locked: true,
    label: "documents",
    code: "documents",
    icon: "FileStack"
  },
  locations: {
    label: "locations_other",
    code: "locations",
    icon: "MapPin"
  },
  interventions: {
    label: "interventions",
    code: "interventions",
    icon: "SquareGanttChart"
  },
  equipements: {
    locked: true,
    label: "equipements",
    code: "equipements",
    icon: "Lightbulb"
  },
  maintenances: {
    locked: true,
    label: "maintenances",
    code: "maintenances",
    icon: "Wrench"
  },
  automations: {
    locked: true,
    label: "automations",
    code: "automations",
    icon: "Bot"
  },
  contracts: {
    locked: true,
    label: "contracts",
    code: "contracts",
    icon: "FileBadge"
  },
  intervenants: {
    label: "intervenants_other",
    code: "intervenants",
    icon: "Users"
  }
}

export const filtersLabels = {
  category: {
    label: "category",
    code: "category",
  },
  type: {
    label: "type",
    code: "type",
  },
}
// ========  filter buttons ========
export const tabsClientPreview = [
  {
    id: 1,
    code:"PROFESSIONAL",
    name: "professionalLabel",
  },
  {
    id: 2,
    code:"INDIVIDUAL",
    name: "individualLabel",
  },
];

export const intervenantTabs = [
  {
    id: 1,
    code: "TECHNICIAN",
    name: "technicians_one",
  },
  {
    id: 2,
    code: "SERVICE_PROVIDER",
    name: "serviceProvider",
  },
];

export const buttonClients = [
  filtersLabels.category,
  filtersLabels.type,
];

export const localisationButton = [
  filtersLabels.category,
  {
    id: 2,
    name: "Région",
  },
  {
    id: 3,
    name: "Département",
  },
  {
    id: 4,
    name: "Champ",
  },
];

export const buttonIntervanants = [
  {
    id: 1,
    name: "Métier",
  },
  {
    id: 2,
    name: "Département",
  },
  {
    id: 3,
    name: "Enterprises",
  },
  {
    id: 4,
    name: "Technicians",
  },
  {
    id: 5,
    name: "Département",
  },
  {
    id: 6,
    name: "Département",
  },
];

export const buttonEquipment = [
  {
    id: 1,
    name: "Recherch",
  },
  {
    id: 2,
    name: "Catégorie",
  },
  {
    id: 3,
    name: "Sous_catégorie",
  },
  {
    id: 4,
    name: "Etat",
  },
  {
    id: 5,
    name: "Lieu",
  },
  {
    id: 6,
    name: "Métier",
  },
];

export const buttonInterventions = [
  {
    id: 1,
    name: "En_cours",
    checkbox: true,
  },
  {
    id: 2,
    name: "Clos",
    checkbox: true,
  },
  {
    id: 3,
    name: "Maintenances",
    checkbox: true,
  },
  {
    id: 4,
    name: "Intervenants",
  },
  {
    id: 5,
    name: "Etat",
  },
];

// option for Option swiper components

export const clientsOption = [
  tabsLabel.interventions,
  tabsLabel.locations,
  tabsLabel.equipements,
  tabsLabel.projectManagers,
  tabsLabel.documents,
];

export const intervanantsOptions = [
  tabsLabel.interventions,
  tabsLabel.maintenances,
  tabsLabel.automations,
  tabsLabel.contracts,
];

export const localisationOptions = [
  {
    id: 1,
    label: "Carte",
    code: "cart",
    icon: <Icon name={"MapPinned"} size={15} />,
  },
  {
    id: 2,
    label: "Sous-lieux",
    code: "sublocation",
    icon: <Icon name={"HeartPulse"} size={15} />,
  },
  tabsLabel.documents,
  tabsLabel.equipements,
  tabsLabel.interventions,
  tabsLabel.maintenances,
  {
    id: 7,
    label: "Garanties",
    code: "waranty",
    icon: <Icon name={"BadgeCheck"} size={15} />,
  },
  {
    id: 8,
    label: "Baux",
    code: "baux",
    icon: <Icon name={"Home"} size={15} />,
  },
  {
    id: 9,
    label: "Utilisateurs",
    code: "users",
    icon: <Icon name={"User"} size={15} />,
  },
  {
    id: 10,
    label: "Stocks",
    code: "stocks",
    icon: <Icon name={"ShoppingBag"} size={15} />,
  },
];
export const documentArray = [
  {
    id: 1,
    name: "File1",
    user: "John",
    size: "10MB",
    date: "2024-03-29",
  },
  {
    id: 2,
    name: "File2",
    user: "Alice",
    size: "5MB",
    date: "2024-03-28",
  },
  {
    id: 3,
    name: "File3",
    user: "Bob",
    size: "15MB",
    date: "2024-03-27",
  },
  {
    id: 4,
    name: "File4",
    user: "Emily",
    size: "20MB",
    date: "2024-03-26",
  },
  {
    id: 5,
    name: "File5",
    user: "Sarah",
    size: "8MB",
    date: "2024-03-25",
  },
  {
    id: 6,
    name: "File6",
    user: "David",
    size: "12MB",
    date: "2024-03-24",
  },
  {
    id: 7,
    name: "File7",
    user: "Emma",
    size: "18MB",
    date: "2024-03-23",
  },
  {
    id: 8,
    name: "File8",
    user: "Michael",
    size: "7MB",
    date: "2024-03-22",
  },
  {
    id: 9,
    name: "File9",
    user: "Olivia",
    size: "22MB",
    date: "2024-03-21",
  },
  {
    id: 10,
    name: "File10",
    user: "James",
    size: "14MB",
    date: "2024-03-20",
  },
];

export const equipmentOption = [
  {
    id: 1,
    label: "Sous-équipements",
    icon: <Icon name={"MapPinned"} size={15} />,
  },
  tabsLabel.documents,
  {
    id: 3,
    label: "QR Code",
    icon: <Icon name={"QrCode"} size={15} />,
  },
  {
    id: 4,
    label: "Garanties",
    icon: <Icon name={"BadgeCheck"} size={15} />,
  },
  {
    id: 5,
    label: "Interventions",
    icon: <Icon name={"NotepadText"} size={15} />,
  },
  {
    id: 6,
    label: "Maintenances",
    icon: <Icon name={"HeartPulse"} size={15} />,
  },
  {
    id: 7,
    label: "Stocks",
    icon: <Icon name={"ShoppingBag"} size={15} />,
  },
  {
    id: 8,
    label: "Baux",
    icon: <Icon name={"Home"} size={15} />,
  },
  {
    id: 9,
    label: "Utilisa",
    icon: <Icon name={"User"} size={15} />,
  },
];

export const interventionOptions = [
  {
    id: 3,
    label: "Details",
    code: "details",
    icon: <Icon name={"ReceiptText"} size={15} />,
  },
  {
    id: 1,
    label: "Utilisateurs",
    code: "users",
    icon: <Icon name={"MapPinned"} size={15} />,
  },
  tabsLabel.documents,
  {
    id: 4,
    label: "Stocks",
    code: "stocks",
    icon: <Icon name={"BadgeCheck"} size={15} />,
  },
  {
    id: 5,
    label: "Données",
    code: "data",
    icon: <Icon name={"NotepadText"} size={15} />,
  },
  {
    id: 6,
    label: "Notes",
    code: "notes",
    icon: <Icon name={"HeartPulse"} size={15} />,
  },
  {
    id: 7,
    label: "Liens",
    code: "links",
    icon: <Icon name={"ShoppingBag"} size={15} />,
  },
  {
    id: 8,
    label: "Historique",
    code: "history",
    icon: <Icon name={"Home"} size={15} />,
  },
  {
    id: 9,
    label: "Rapports",
    code: "reports",
    icon: <Icon name={"QrCode"} size={15} />,
  },
];

// Details for each page
export const clientList = [
  {
    id: 1,
    status: "pending",
    clientName: "Go Sport",
    avatar:
      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
    details: [
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "example@xyz.com",
      },
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 2231 2121",
      },

      {
        name: "Interface Publique",
        icon: <Icon name={"Link"} color="gray" />,
        value:
          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      },
    ],
  },
  {
    id: 2,
    status: "active",
    clientName: "PLAN Demo",
    avatar:
      "https://img.freepik.com/free-photo/realistic-b-letter-with-leaves_23-2150458489.jpg",
    details: [
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "example@xyz.com",
      },
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 2231 2121",
      },

      {
        name: "Interface Publique",
        icon: <Icon name={"Link"} color="gray" />,
        value:
          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      },
    ],
  },
  {
    id: 3,
    status: "deactive",
    clientName: "Grand Frais",
    avatar:
      "https://i.pinimg.com/736x/11/69/6a/11696a79c0895e164359cbab5c3073b5.jpg",
    details: [
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "example@xyz.com",
      },
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 2231 2121",
      },

      {
        name: "Interface Publique",
        icon: <Icon name={"Link"} color="gray" />,
        value:
          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      },
    ],
  },
];

export const intervenantsClients = [
  {
    id: 1,
    clientName: "Clim'Party Climatisation",
    status: "active",
    avatar: null,
    subtitle: "Climatisation",
    details: [
      {
        name: "Gestionnaire principal",
        icon: <Icon name={"CircleUserRound"} color="gray" />,
        value: "Nom Prénom",
      },
      {
        name: "Zone(s) d'intervention",
        icon: <Icon name={"MapPin"} color="gray" />,
        value:
          "75 Paris91 Essonne92-Hauts-de-Seine93-Seine-Saint-Denis95-Val-d'oise94 Val-de-Marne",
      },
      {
        name: "Métiers",
        icon: <Icon name={"Briefcase"} color="gray" />,
        value: "Climatisation",
      },
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 2231 2121",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "example@xyz.com",
      },
    ],
  },
  {
    id: 2,
    clientName: "TVX PLOMBERIE 2",
    status: "active",
    avatar: null,
    subtitle: "Travaux magasin, 1 autres",
    details: [
      {
        name: "Gestionnaire principal",
        icon: <Icon name={"CircleUserRound"} color="gray" />,
        value: "Nom Prénom",
      },
      {
        name: "Zone(s) d'intervention",
        icon: <Icon name={"MapPin"} color="gray" />,
        value:
          "75 Paris91 Essonne92-Hauts-de-Seine93-Seine-Saint-Denis95-Val-d'oise94 Val-de-Marne",
      },
      {
        name: "Métiers",
        icon: <Icon name={"Briefcase"} color="gray" />,
        value: "Climatisation",
      },
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 2231 2121",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "example@xyz.com",
      },
    ],
  },
  {
    id: 3,
    clientName: "TVX ELEC 2",
    status: "active",
    avatar: null,
    subtitle: "Travaux magasin, 1 autres",
    details: [
      {
        name: "Gestionnaire principal",
        icon: <Icon name={"CircleUserRound"} color="gray" />,
        value: "Nom Prénom",
      },
      {
        name: "Zone(s) d'intervention",
        icon: <Icon name={"MapPin"} color="gray" />,
        value:
          "75 Paris91 Essonne92-Hauts-de-Seine93-Seine-Saint-Denis95-Val-d'oise94 Val-de-Marne",
      },
      {
        name: "Métiers",
        icon: <Icon name={"Briefcase"} color="gray" />,
        value: "Climatisation",
      },
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 2231 2121",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "example@xyz.com",
      },
    ],
  },
  {
    id: 4,
    clientName: "TVX DEMOLITION 2",
    status: "deactive",
    avatar: null,
    subtitle: "Travaux magasin, 1 autres",
    details: [
      {
        name: "Gestionnaire principal",
        icon: <Icon name={"CircleUserRound"} color="gray" />,
        value: "Nom Prénom",
      },
      {
        name: "Zone(s) d'intervention",
        icon: <Icon name={"MapPin"} color="gray" />,
        value:
          "75 Paris91 Essonne92-Hauts-de-Seine93-Seine-Saint-Denis95-Val-d'oise94 Val-de-Marne",
      },
      {
        name: "Métiers",
        icon: <Icon name={"Briefcase"} color="gray" />,
        value: "Climatisation",
      },
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 2231 2121",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "example@xyz.com",
      },
    ],
  },
  {
    id: 5,
    clientName: "TVX RELAMPING 2",
    status: "pending",
    avatar: null,
    subtitle: "Travaux magasin, 1 autres",
    details: [
      {
        name: "Gestionnaire principal",
        icon: <Icon name={"CircleUserRound"} color="gray" />,
        value: "Nom Prénom",
      },
      {
        name: "Zone(s) d'intervention",
        icon: <Icon name={"MapPin"} color="gray" />,
        value:
          "75 Paris91 Essonne92-Hauts-de-Seine93-Seine-Saint-Denis95-Val-d'oise94 Val-de-Marne",
      },
      {
        name: "Métiers",
        icon: <Icon name={"Briefcase"} color="gray" />,
        value: "Climatisation",
      },
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 2231 2121",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "example@xyz.com",
      },
    ],
  },
  {
    id: 6,
    clientName: "BUDGET TRAVAUX 2022/2023",
    status: "pending",
    avatar: null,
    subtitle: "Travaux magasin",
    details: [
      {
        name: "Gestionnaire principal",
        icon: <Icon name={"CircleUserRound"} color="gray" />,
        value: "Nom Prénom",
      },
      {
        name: "Zone(s) d'intervention",
        icon: <Icon name={"MapPin"} color="gray" />,
        value:
          "75 Paris91 Essonne92-Hauts-de-Seine93-Seine-Saint-Denis95-Val-d'oise94 Val-de-Marne",
      },
      {
        name: "Métiers",
        icon: <Icon name={"Briefcase"} color="gray" />,
        value: "Climatisation",
      },
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 2231 2121",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "example@xyz.com",
      },
    ],
  },
  {
    id: 7,
    clientName: "TVX DEMOLITION",
    status: "pending",
    avatar: null,
    subtitle: "Travaux magasin, 1 autres",
    details: [
      {
        name: "Gestionnaire principal",
        icon: <Icon name={"CircleUserRound"} color="gray" />,
        value: "Nom Prénom",
      },
      {
        name: "Zone(s) d'intervention",
        icon: <Icon name={"MapPin"} color="gray" />,
        value:
          "75 Paris91 Essonne92-Hauts-de-Seine93-Seine-Saint-Denis95-Val-d'oise94 Val-de-Marne",
      },
      {
        name: "Métiers",
        icon: <Icon name={"Briefcase"} color="gray" />,
        value: "Climatisation",
      },
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 2231 2121",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "example@xyz.com",
      },
    ],
  },
];

export const LocationData = [
  {
    id: 1,
    title: "#1",
    subtitle: "Magasin Treuil",
    paragraph: "34 Rue Yves Toudic, 75010 Paris, France",
    picture: "/images/dummyLocationIMG.png",
    details: [
      {
        name: "Téléphone",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "11+33 498 97 56-47",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "john@treuil.com",
      },
      {
        name: "Adresse",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "34 Rue Yves Toudic, 75010 Pans, France",
      },
      {
        name: "Région",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "Ile-de-France",
      },
      {
        name: "Département",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "Paris",
      },
    ],
  },
  {
    id: 2,
    title: "#2",
    subtitle: "Magasin 1",
    paragraph: "2300 Yonge St, Toronto, ON MAP 1E4, Canada",
    picture: "/images/dummyLocationIMG.png",
    details: [
      {
        name: "Téléphone",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "11+33 498 97 56-47",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "john@treuil.com",
      },
      {
        name: "Adresse",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "34 Rue Yves Toudic, 75010 Pans, France",
      },
      {
        name: "Région",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "Ile-de-France",
      },
      {
        name: "Département",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "Paris",
      },
    ],
  },
  {
    id: 3,
    title: "#3",
    subtitle: "Magasin 2",
    paragraph: "25 Rue des Renaudes, 75017 Paris, France",
    picture: "/images/dummyLocationIMG.png",
    details: [
      {
        name: "Téléphone",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "11+33 498 97 56-47",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "john@treuil.com",
      },
      {
        name: "Adresse",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "34 Rue Yves Toudic, 75010 Pans, France",
      },
      {
        name: "Région",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "Ile-de-France",
      },
      {
        name: "Département",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "Paris",
      },
    ],
  },
  {
    id: 4,
    title: "#4",
    subtitle: "Magasin 3",
    paragraph: "15 Rue de Vaugirard, 75006 Paris, France",
    picture: "/images/dummyLocationIMG.png",
    details: [
      {
        name: "Téléphone",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "11+33 498 97 56-47",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "john@treuil.com",
      },
      {
        name: "Adresse",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "34 Rue Yves Toudic, 75010 Pans, France",
      },
      {
        name: "Région",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "Ile-de-France",
      },
      {
        name: "Département",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "Paris",
      },
    ],
  },
  {
    id: 5,
    title: "#Immeuble Principal",
    subtitle: "Siège",
    paragraph: "12 Rue de Lyon, 75012 Paris, France",
    picture: "/images/dummyLocationIMG.png",
    details: [
      {
        name: "Téléphone",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "11+33 498 97 56-47",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "john@treuil.com",
      },
      {
        name: "Adresse",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "34 Rue Yves Toudic, 75010 Pans, France",
      },
      {
        name: "Région",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "Ile-de-France",
      },
      {
        name: "Département",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "Paris",
      },
    ],
  },
];

export const equipmentList = [
  {
    id: 1,
    title: "Machine à coudre",
    subtitle: "Magasin Treuil",
    paragraph: "34 Rue Yves Toudic, 75010 Paris, France",
    picture: "/images/dummyEquipment.png",
    details: [
      {
        name: "66 Machine qui sert à coudre des vétements",
        icon: <Icon name={"Quote"} color="gray" />,
      },
      {
        name: "Magasin Treuill",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "34 Rué Yves Toudic, 75010 Paris, France",
      },
    ],
  },
  {
    id: 2,
    title: "Climatisation",
    subtitle: "Magasin 1",
    paragraph: "2300 Yonge St, Toronto, ON M4P 154, Canada",
    picture: "/images/dummyEquipment.png",
    details: [
      {
        name: "66 Machine qui sert à coudre des vétements",
        icon: <Icon name={"Quote"} color="gray" />,
      },
      {
        name: "Magasin Treuill",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "34 Rué Yves Toudic, 75010 Paris, France",
      },
    ],
  },
  {
    id: 3,
    title: "Radiateur",
    subtitle: "Magasin 1",
    paragraph: "2300 Yonge St, Toronto, ON M4P 1E4, Canada",
    picture: "/images/dummyEquipment.png",
    details: [
      {
        name: "66 Machine qui sert à coudre des vétements",
        icon: <Icon name={"Quote"} color="gray" />,
      },
      {
        name: "Magasin Treuill",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "34 Rué Yves Toudic, 75010 Paris, France",
      },
    ],
  },
];

export const interventionsData = [
  {
    id: 1,
    title: "Probleme de climatisation",
    subtitle: "Climatisation",
    subtitle2: "Magasin 1 M4P 154",
    paragraph: "#21 En attente d'assignation",
    date: "Ma climatisation fuit",
    details: [
      {
        name: "66 Ma climatisation fuit",
        icon: <Icon name={"Quote"} color="gray" />,
      },
      {
        name: "Magasin 1",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "2300 Yonge St, Toronto, ON MAP 1E4, Canada",
      },
      {
        name: "Climatisation",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "Sous garantie (1)",
      },
      {
        name: "Date de passage programme Aucune date",
        icon: <Icon name={"Calendar"} color="gray" />,
        value: "Accone Dates",
      },
      {
        name: "Date de'intervention",
        icon: <Icon name={"Calendar"} color="gray" />,
        value: "Acune Dates",
      },
    ],
  },
  {
    id: 2,
    title: "lorem ipsum dolor sit amet",
    subtitle: "Climatisation",
    subtitle2: "Magasin 1 M4P 154",
    paragraph: "#21 En attente d'assignation",
    date: "Ma climatisation fuit",
    details: [
      {
        name: "67 Ma climatisation fuit",
        icon: <Icon name={"Quote"} color="gray" />,
      },
      {
        name: "lorem",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "2300 Yonge St, Toronto, ON MAP 1E4, Canada",
      },
      {
        name: "Climatisation",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "Sous garantie (1)",
      },
      {
        name: "Date de passage programme Aucune date",
        icon: <Icon name={"Calendar"} color="gray" />,
        value: "Accone Dates",
      },
      {
        name: "Date de'intervention",
        icon: <Icon name={"Calendar"} color="gray" />,
        value: "Acune Dates",
      },
    ],
  },
  {
    id: 3,
    title: "lorem ipsum 1 dolor sit amet",
    subtitle: "Climatisation",
    subtitle2: "Magasin 1 M4P 154",
    paragraph: "#21 En attente d'assignation",
    date: "Ma climatisation fuit",
    details: [
      {
        name: "68 Ma climatisation fuit",
        icon: <Icon name={"Quote"} color="gray" />,
      },
      {
        name: "Magasin 2",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "2300 Yonge St, Toronto, ON MAP 1E4, Canada",
      },
      {
        name: "Climatisation",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "Sous garantie (1)",
      },
      {
        name: "Date de passage programme Aucune date",
        icon: <Icon name={"Calendar"} color="gray" />,
        value: "Accone Dates",
      },
      {
        name: "Date de'intervention",
        icon: <Icon name={"Calendar"} color="gray" />,
        value: "Acune Dates",
      },
    ],
  },
  {
    id: 4,
    title: "lorem ipsum 2 dolor sit amet",
    subtitle: "Climatisation",
    subtitle2: "Magasin 1 M4P 154",
    paragraph: "#21 En attente d'assignation",
    date: "Ma climatisation fuit",
    details: [
      {
        name: "69 Ma climatisation fuit",
        icon: <Icon name={"Quote"} color="gray" />,
      },
      {
        name: "Magasin 4",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "2300 Yonge St, Toronto, ON MAP 1E4, Canada",
      },
      {
        name: "Climatisation",
        icon: <Icon name={"MapPin"} color="gray" />,
        value: "Sous garantie (1)",
      },
      {
        name: "Date de passage programme Aucune date",
        icon: <Icon name={"Calendar"} color="gray" />,
        value: "Accone Dates",
      },
      {
        name: "Date de'intervention",
        icon: <Icon name={"Calendar"} color="gray" />,
        value: "Acune Dates",
      },
    ],
  },
];

//  tabs for intervanants
export const tabsIntervanants = [
  {
    key: "1",
    label: (
      <div className="flex gap-3">
        <Icon name={"UserRound"} />
        <p>Techniciens</p>
      </div>
    ),
  },
  {
    key: "2",
    label: (
      <div className="flex gap-3">
        <Icon name={"SquareUser"} />
        <p>Entreprises</p>
      </div>
    ),
  },
];
// tabs for interventionas
export const tabsInterventions = [
  {
    value: "FAIBLE",
    label: "FAIBLE",
  },
  {
    value: "MOYEN 1",
    label: "MOYEN 1",
  },
  {
    value: "HAUTE 24h",
    label: "HAUTE 24h",
  },
  {
    value: "CRITIQUE 2h",
    label: "CRITIQUE 2h",
  },
];

//  dummy texts and objects =================

export const dummyText =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam consectetur velit ac urna consectetur, eget varius lorem ultricies. Vivamus vitae neque et metus varius tincidunt. Integer sollicitudin felis nec leo consectetur, nec ultrices sem condimentum. Duis fringilla fringilla ligula, ac lobortis tortor venenatis nec. Sed sed velit id justo mollis hendrerit. Morbi tempor justo ac nisi sollicitudin volutpat. Sed euismod ante ut felis rhoncus tempus. Nullam sagittis, ligula eget fermentum tristique, purus sapien dignissim justo, sed consequat neque sapien in lectus. Ut laoreet euismod nisl, nec tincidunt sem faucibus quis. Sed auctor auctor nisl, in ultricies felis bibendum id. Sed nec leo quis orci malesuada ultrices.Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris luctus odio quis mi rutrum, non finibus libero tincidunt. Aenean posuere mi quis dui varius, vel tincidunt nisi commodo. Fusce nec libero mauris. Nulla facilisi. Mauris ac felis tristique, sagittis ipsum eget, pulvinar enim. Proin auctor libero id massa feugiat consequat. Donec ut sodales magna. Integer in hendrerit ex. Sed vitae fermentum elit. Phasellus fermentum urna a sapien aliquam, ut ultricies mauris consectetur. Sed non nulla nec magna varius vehicula. Suspendisse potenti.";

export const options = [
  {
    label: "Maedow",
    value: "Maedow",
  },
  {
    label: "Sports",
    value: "Sports",
  },
  {
    label: "Hillstation",
    value: "Hillstation",
  },
  {
    label: "Hiking",
    value: "Hiking",
  },
  {
    label: "Snow",
    value: "Snow",
  },
  {
    label: "Beach",
    value: "Beach",
  },
  {
    label: "Mountains",
    value: "Mountains",
  },
];
//for user list

export const buttonUserList = [
  {
    id: 1,
    name: "Rible",
    // checkbox: true,
  },
  {
    id: 2,
    name: "Lieu",
    // checkbox: true,
  },
];
export const userListOptions = [
  {
    id: 1,
    label: "Details",
    code: "details",
    icon: <Icon name={"ReceiptText"} size={15} />,
  },
  {
    id: 2,
    label: "Lieux",
    code: "location",
    icon: <Icon name={"MapPinned"} size={15} />,
  },
  {
    id: 3,
    label: "Interventions",
    code: "interventions",
    icon: <Icon name={"NotepadText"} size={15} />,
  },
];
export const ParticularUserOptions = [
  {
    id: 1,
    name: "category",
    // checkbox: true,
  },
  {
    id: 2,
    name: "Lieu",
    // checkbox: true,
  },
  {
    id: 3,
    name: "Department",
    // checkbox: true,
  },
  {
    id: 3,
    name: "Champ",
    // checkbox: true,
  },
];
export const userListData = [
  {
    id: 1,
    status: "pending",
    clientName: "EMMANUELLE BORDE",
    role: "Collaborator",
    avatar:
      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
    details: [
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 2535 6121",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "examnuelleborde@xyz.com",
      },

      {
        icon: <Icon name={"Clock"} color="gray" />,
        value: `${new Date()}`,
      },
    ],
  },
  {
    id: 2,
    status: "active",
    clientName: "JULIA CH",
    role: "Validators",
    avatar:
      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
    details: [
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 6231 2721",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "juliach@xyz.com",
      },

      {
        icon: <Icon name={"Clock"} color="gray" />,
        value: `${new Date()}`,
      },
    ],
  },
  {
    id: 3,
    status: "pending",
    clientName: "Rim CHABBI",
    role: "Collaborator",
    avatar:
      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
    details: [
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 8241 2527",
      },

      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "riachabbi@xyz.com",
      },

      {
        icon: <Icon name={"Clock"} color="gray" />,
        value: `${new Date()}`,
      },
    ],
  },
  {
    id: 4,
    status: "active",
    clientName: "Service CLIENT",
    role: "Collaborator",
    avatar:
      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
    details: [
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 2231 2121",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "example@xyz.com",
      },

      {
        icon: <Icon name={"Clock"} color="gray" />,
        value: `${new Date()}`,
      },
    ],
  },
  {
    id: 6,
    status: "active",
    clientName: "JEAN FITZGERALD",
    role: "Collaborator",
    avatar:
      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
    details: [
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 5431 2121",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "example@xyz.com",
      },

      {
        icon: <Icon name={"Clock"} color="gray" />,
        value: `${new Date()}`,
      },
    ],
  },
  {
    id: 5,
    status: "active",
    clientName: "Blaise CHAVANNE",
    role: "Validators",
    avatar:
      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
    details: [
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 2231 2121",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "example@xyz.com",
      },

      {
        icon: <Icon name={"Clock"} color="gray" />,
        value: `${new Date()}`,
      },
    ],
  },
  {
    id: 7,
    status: "active",
    clientName: "Brune CHRETIEN",
    role: "Collaborator",
    avatar:
      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
    details: [
      {
        name: "Portable",
        icon: <Icon name={"Phone"} color="gray" />,
        value: "21 3431 2121",
      },
      {
        name: "Email",
        icon: <Icon name={"Mail"} color="gray" />,
        value: "example@xyz.com",
      },

      {
        icon: <Icon name={"Clock"} color="gray" />,
        value: `${new Date()}`,
      },
    ],
  },
];
// FOR PARAMETERS
export const parametersData = [
  {
    key: "Compatibility",
    description:
      "Indicates the level of compatibility with different systems or standards.",
    icon: "Calculator",
  },
  {
    key: "Agenda",
    description:
      "Outlines the main points or topics to be discussed or addressed.",
    icon: "CalendarDays",
  },
  {
    key: "Contrast",
    description:
      "Refers to the difference in color or intensity between two or more things.",
    icon: "LineChart",
  },
  {
    key: "Resolution",
    description:
      "The number of distinct pixels that can be displayed in each dimension.",
    icon: "HeartOff",
  },
  {
    key: "RefreshRate",
    description:
      "The number of times the screen updates with new images each second.",
    icon: "Home",
  },
  {
    key: "ResponseTime",
    description: "The time it takes for a system to react to a stimulus.",
    icon: "Home",
  },
  {
    key: "Efficiency",
    description:
      "The ability to accomplish a task with the least amount of wasted time and effort.",
    icon: "Wrench",
  },
  {
    key: "Performance",
    description: "The ability of a system to perform its required functions.",
    icon: "Home",
  },
  {
    key: "Scalability",
    description:
      "The ability of a system, network, or process to handle a growing amount of work.",
    icon: "Scale",
  },
];
