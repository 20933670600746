import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GoEye, GoEyeClosed } from "react-icons/go";
import api from "../utils/api";
import Button from "./Button";
import { useTranslation } from "react-i18next";
const InputForm = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { id } = useParams();
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    // termsAndConditions: false,
    // isSocialLogIn: false,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormData((prev) => (prev = { ...prev, [name]: value }));
  };

  const isFormDataFilled = () => {
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (formData[key] === "") {
          return false;
        }
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { name, email, password, confirmPassword } = formData;

      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]*$/;

      if (!passwordRegex.test(password)) {
        setError(
          "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character"
        );
        return;
      }

      if (password != confirmPassword) {
        setError("Password and confirm password should be same");
        return;
      }

      setIsLoading(true);
      setError("");

      const body = {
        name,
        email,
        password,
      };

      await api.post("/org/OrgSignup", body);
      navigate("/signin");
    } catch (error) {
      console.log(error?.message);
      setError(error?.response?.data?.message);
    }

    setIsLoading(false);
  }; 

  return (

    <div className=" bg-white flex flex-col h-screen  justify-center items-center flex-1 ">
      <div className="max-w-[450px] h-screen  justify-center items-center bg-white sm:rounded-[29px] flex gap-3 py-10 px-[60px] ">
        <div className=" flex flex-col gap-5">
          <div className="flex flex-col gap-5">
            <h1 className="text-[30px] font-medium text-center text-dark leading-[52px]">
              {t("signup.title")}
            </h1>
            <p className="text-center text-[15px] leading-7 font-normal text-dark ">
              {t("signup.subtitle")}{" "}
            </p>
          </div>
          <form onSubmit={handleSubmit} className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-4">
                <label
                  htmlFor="name"
                  className="text-[16px] font-medium leading-6 text-dark"
                >
                  {t("signup.name")}
                </label>
                <div className="w-full px-4 py-[14px] rounded-lg border bg-white border-[#CAD1DB] focus-within:border-orange ">
                  <input
                    className="text-dark bg-transparent text-base font-normal leading-6 outline-none w-[90%] "
                    type="text"
                    value={formData.name}
                    name="name"
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <label
                  htmlFor="fullname"
                  className="text-[16px] font-medium leading-6 text-dark"
                >
                  {t("signup.email")}
                </label>
                <div
                  className={`w-full px-4 py-[14px] rounded-lg border  border-[#CAD1DB]   ${
                    emailDisabled
                      ? "bg-white"
                      : "bg-white focus-within:border-orange "
                  }`}
                >
                  <input
                    className={`text-[#5A5C73] bg-transparent text-base font-normal leading-6 outline-none w-[90%] `}
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    // disabled={emailDisabled}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <label
                  htmlFor="password"
                  className="text-[16px] font-medium leading-6 text-dark"
                >
                  {t("signup.password")}
                </label>
                <div className="w-full flex justify-between items-center px-4 py-[14px] bg-white rounded-lg border border-[#CAD1DB]  focus-within:border-orange ">
                  <input
                    className="text-[#5A5C73] bg-transparent text-base font-normal leading-6 outline-none w-[90%]"
                    type={showPassword ? "text" : "password"}
                    value={formData.password}
                    name="password"
                    onChange={handleChange}
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <GoEye className="text-2xl" />
                    ) : (
                      <GoEyeClosed className="text-2xl" />
                    )}
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <label
                  htmlFor="password"
                  className="text-[16px] font-medium leading-6 text-dark"
                >
                  {t("signup.confirmPassword")}
                </label>
                <div className="w-full flex justify-between items-center px-4 py-[14px] bg-white rounded-lg border border-[#CAD1DB]  focus-within:border-orange ">
                  <input
                    className="text-[#5A5C73] bg-transparent text-base font-normal leading-6 outline-none w-[90%]"
                    type={showConfirmPassword ? "text" : "password"}
                    value={formData.confirmPassword}
                    name="confirmPassword"
                    onChange={handleChange}
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <GoEye className="text-2xl" />
                    ) : (
                      <GoEyeClosed className="text-2xl" />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <Button
                type="primary"
                value={t("signup.signUp")}
                disabled={!isFormDataFilled() || isLoading}
                className="w-full bg-orange disabled:bg-gray-700 text-white text-center font-medium text-[16px] leading-6 h-14 rounded-lg"
              />

              <p className="text-red-700">{error}</p>
            </div>
            <div className="flex flex-col gap-6">
              <p className="w-full mt-2 text-center text-[14px] font-small leading-6 text-[#2b4d75]">
              {t("signup.loginMessage")}{" "}
                <Link to={"/signin"} className="text-orange">
                {t("signup.login")}
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      
      <div className="bg-[#f1f4f8] w-full p-4">
          <p className="w-full mt-2 text-center text-[14px] font-small leading-6 text-[#6d777f]">
            2024 PLANIGRAMME. All Rights Reserved |{" "}
            <Link
              className="text-orange"
              onClick={() => window.open("/privacy-policy", "_blank")}
            >
              Privacy policy
            </Link>
          </p>
        </div>
    </div>
  );
};

export default InputForm;
