import React from "react";

import { useAppContext } from "../context/Context";
import Page from "./Page";

const Home = () => {
  const { userData } = useAppContext();

  const { fullname } = userData;

  const actions = [];

  const getName = () => {
    if (fullname) return fullname.split(" ")[0];
    return "";
  };
  
  return <Page title={`Welcome back ${getName()} 👋`} actions={actions}>
  </Page>;
};

export default Home;
