import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAllIntervenants } from "../redux/slices/appConfigSlice";
import api from "../utils/api";

const useAllIntervenants = () => {
   const { allIntervenants } = useSelector(state => state.appConfigReducer);
   const dispatch = useDispatch();

   const fetchAllIntervenants = async () => {
      try {

         const res = await api.get("/intervenant/getAllIntervenant");
         dispatch(setAllIntervenants(res.data));

      } catch (error) {
         console.log("Error occured while fetching all intervenants", error);
      }
   }

   useEffect(() => {
      if (!allIntervenants) {
         fetchAllIntervenants();
      }
   }, [allIntervenants])
};

export default useAllIntervenants;
