import React, { useEffect, useState } from "react";
import Switcher from "./Switcher";
import { useTableContext } from "../../context/TableContext";
import Icon from "../Icon";

const TableData = ({ data }) => {
  const {
    selectedItems,
    tableConfiguration: configuration,
    selectItem,
    selectable,
    setSelectedItem,
  } = useTableContext();
  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => {
    setIsSelected(!!selectedItems.find(({ id }) => id === data.id));
  }, [selectedItems]);

  return (
    <tr
      className={`tableRow hover:bg-[#EDF5FF] cursor-pointer transition ${
        isSelected ? "bg-[#EDF5FF]" : ""
      }`}
    >
      {selectable && (
        <td className=" w-20 ">
          <input
            type="checkbox"
            className="w-full h-4 m-[2px]"
            checked={isSelected}
            onChange={() => selectItem(data)}
          />
        </td>
      )}

      {configuration.map((cell) => {
        switch (cell.type) {
          case "status":
            return <StatusCell {...{ data, ...cell }} />;
          case "html":
            return <ImageText {...{ data, setSelectedItem, ...cell }} />;
          case "switch":
            return <SwitchCell {...{ data, ...cell }} />;
          case "text":
            return <TextCell {...{ data, ...cell }} />;
          case "actions":
            return <ActionsCell {...{ data, ...cell }} />;
        }
      })}
    </tr>
  );
};

const StatusCell = ({ data, value }) => {
  let color = "#CAD1DB";
  const test = false;
  if (["ready", "active", "accepted"].indexOf(data[value]) !== -1) {
    color = "#34B86D";
  } else if (["pending", "warning", "inactive"].indexOf(data[value]) !== -1) {
    color = "#FFA800";
  } else if (["blocked", "error", "removed"].indexOf(data[value]) !== -1) {
    color = "#F5432C";
  }
  return (
    <td className="capitalize font-semibold">
      <div className="flex items-center ">
        <div
          className={`h-2 w-2 rounded-lg bg-[${color}] mx-2 ${
            test && "bg-[#34B86D] bg-[#FFA800] bg-[#F5432C] "
          }`}
        ></div>
        <div
          className={`text-[${color}]  ${
            test && "text-[#34B86D] text-[#FFA800] text-[#F5432C] "
          }`}
        >
          {" "}
          {data[value]}
        </div>
      </div>
    </td>
  );
};

const ImageText = ({ data, value, setSelectedItem }) => {
  return (
    <td
      className="flex items-center gap-2"
      onClick={() => setSelectedItem(data)}
    >
      <div className="w-10 h-10 flex items-center justify-center">
        {data[value[0]] ? (
          <img className="rounded" src={data[value[0]]} alt="" />
        ) : (
          <Icon name="Image" color={"#CAD1DB"} size="40" />
        )}
      </div>
      {data[value[1]]}
    </td>
  );
};

const SwitchCell = ({ data, value }, onChange) => {
  return (
    <td>
      <Switcher state={data[value]} onChange={onChange} />
    </td>
  );
};

const TextCell = ({ data, value, link }) => {
  const handleClick = () => {
    if (link && data[link]) window.open(data[link], "_blank");
  };
  return (
    <td
      className={
        link && data[link] ? "cursor-pointer underline underline-offset-1 " : ""
      }
    >
      <div className="flex items-center" onClick={handleClick}>
        {data[value]}
        {link && data[link] ? (
          <Icon name="ExternalLink" size="14" />
        ) : undefined}
      </div>
    </td>
  );
};

const ActionsCell = () => {
  return (
    <td>
      <div className="mx-4 flex justify-end">
        <Icon name="MoreHorizontal" />
      </div>
    </td>
  );
};

export default TableData;
