import React, { useEffect, useState } from "react";
import Page from "./Page";
import CustomDrawer from "../components/CustomDrawer";
import IntervenantsDrawer from "../components/drawer/IntervenantsDrawer";
import Icon from "../components/Icon";
import DrawerHeader from "../components/drawer/DrawerHeader";
import FooterEle from "../components/drawer/FooterEle";
import IntervenantsList from "../components/lists/intervenantsList";
import IntervenantsNavigator from "../components/navigators/IntervenantsNavigator";
import api from "../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { addIntervenant, deleteIntervenant, updateIntervenant } from "../redux/slices/appConfigSlice";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import CustomModal from "../components/modals/CustomModal";
import { intervenantTabs } from "../constant/constant";
import useAllLocations from "../hooks/useAllLocations";
import { useTranslation } from 'react-i18next'

const Intervenants = () => {
  const { t } = useTranslation();
  useAllLocations();
  const { userDetail, allLocations } = useSelector(state => state.appConfigReducer);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [editIntervenant, setEditIntervenant] = useState(false);
  const [selectedIntervenant, setSelectedIntervenant] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(intervenantTabs[0].code);
  const [locations, setLocations] = useState([]);

  const dispatch = useDispatch();

  const initialFormState = {
    enterprise: "",
    firstName: "",
    name: "",
    professions: "",
    email: "",
    zone: [],
    cellNumber: "",
    landlineNumber: "",
    yes: false,
  }
  const [formData, setFormData] = useState(initialFormState);

  const onTabChangeHandler = (value) => {
    setSelectedTab(value);
    setFormData((prev) => prev = { ...prev, type: value });
  }

  const handleChange = (e) => {
    if (e.target.name === "yes") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handeAntDValues = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onCloseModal = () => {
    setSelectedIntervenant(null);
    setShowModal(false);
  }

  const onCloseDrawer = () => {
    setEditIntervenant(false);
    setSelectedIntervenant(null);
    setDrawerOpen(false)
    setFormData(initialFormState)
  }

  const onEditIntervenant = (intervenant) => {
    const data = {
      enterprise: intervenant?.companyName || "",
      firstName: intervenant?.firstName || "",
      name: intervenant?.lastName || "",
      professions: intervenant?.professions || "",
      email: intervenant?.email || "",
      zone: intervenant?.zones || "",
      cellNumber: intervenant?.phoneNumber || "",
      landlineNumber: intervenant?.landLine || "",
      yes: intervenant?.hasPP || false
    }

    setFormData(data);
    setEditIntervenant(true);
    setSelectedIntervenant(intervenant);

    setFile(null)
    setDrawerOpen(true);
  }

  const onDeleteIntervenant = (intervenant) => {
    setSelectedIntervenant(intervenant);
    setShowModal(true);
  }

  const handleDeleteIntervenant = async () => {
    if (!selectedIntervenant || !selectedIntervenant?._id) return;

    try {
      const res = await api.delete(`/intervenant/${selectedIntervenant._id}`);
      dispatch(deleteIntervenant(selectedIntervenant._id))
      setFile(null);
      setShowModal(false);
    } catch (error) {
      console.log("Error occured while deleting intervenant", error);
    }
  }

  const handleEditIntervenant = async () => {
    if (!editIntervenant || !selectedIntervenant || !selectedIntervenant?._id) return;

    const {
      enterprise,
      firstName,
      name,
      professions,
      email,
      zone,
      cellNumber,
      landlineNumber,
      yes
    } = formData;

    const data = {
      companyName: enterprise,
      firstName,
      lastName: name,
      email,
      phoneNumber: cellNumber,
      landLine: landlineNumber,
      professions,
      zones: zone,
      hasPP: yes
    };

    const intervenantFormData = new FormData();

    for (const key in data) {
      intervenantFormData.append(key, data[key]);
    }

    if (file) intervenantFormData.append("image", file);

    try {
      const res = await api.put(`/intervenant/${selectedIntervenant._id}`, intervenantFormData);
      dispatch(updateIntervenant(res.data))
      setFormData(initialFormState);
      setFile(null);
      setDrawerOpen(false);
    } catch (error) {
      console.log("Error occured while updating intervenant", error);
    }
  }

  const handleSubmit = async () => {

    if (editIntervenant) {
      handleEditIntervenant();
      return;
    }

    const {
      enterprise,
      firstName,
      name,
      professions,
      email,
      zone,
      cellNumber,
      landlineNumber,
      yes,
    } = formData;

    if(selectedTab == intervenantTabs[0].name){
      if (
        !firstName ||
        !name
      )
        return;
    }
    else if(selectedTab == intervenantTabs[1].name){
      if (
        !enterprise
      )
        return;
    }

    if (!file) return;

    const intervenantFormData = new FormData();

    const data = {
      firstName,
      lastName: name,
      email,
      phoneNumber: cellNumber,
      landLine: landlineNumber,
      professions,
      zones: zone,
      hasPP: yes,
      type: selectedTab,
    };

    if (data.type == intervenantTabs[1].name) {
      data.companyName = enterprise;
    }

    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        data[key].forEach((item, index) => {
          intervenantFormData.append(`${key}[${index}]`, item);
        });
      } else {
        intervenantFormData.append(key, data[key]);
      }
    });

    intervenantFormData.append("image", file);

    try {
      const res = await api.post("/intervenant", intervenantFormData);
      dispatch(addIntervenant(res.data))
      setFormData(initialFormState);
      setFile(null);
      setDrawerOpen(false);
    } catch (error) {
      console.log("Error occured while creating intervenant", error);
    }
  }

  const fetchAllLocations = async () => {
    const currentLocations = allLocations;
    setLocations(() => {
      const updatedLocations = [];
      updatedLocations.push({ label: "All", value: "All" })
      const set = new Set();
      currentLocations.forEach((location) => {
        if (!set.has(location.place)) {
          updatedLocations.push({
            label: location.place,
            value: location.place,
          })
          set.add(location.place);
        }
      })
      return updatedLocations;
    })
  }

  useEffect(() => {
    setFormData(initialFormState);
  }, [selectedTab])

  useEffect(() => {
    if (allLocations) {
      fetchAllLocations();
    }
  }, [allLocations])

  return (
    <Page>
      <div className="flex w-full h-[calc(100vh-25px)] gap-5">
        <div className="w-full md:w-[500px] lg:w-[400px] xl:w-[500px] rounded-lg  flex flex-col gap-4 p-0">
          <div className="flex items-center justify-between h-16 ">
            <h1 className="text-[#090F4E] text-2xl font-bold leading-10 capitalize">
              {t("intervenants_other")}
            </h1>
            <button
              onClick={() => setDrawerOpen(true)}
              className="p-2 bg-orange hover:shadow-lg h-10  transition ease-in text-white rounded flex items-center justify-center"
            >
              <Icon name="Plus" />
            </button>
          </div>
          <IntervenantsList
            intervenants={userDetail?.intervenant}
            editIntervenant={onEditIntervenant}
            deleteIntervenant={onDeleteIntervenant}
          />
        </div>
        <IntervenantsNavigator />
      </div>

      <CustomDrawer
        open={drawerOpen}
        width={600}
        onClose={onCloseDrawer}
        footerEle={
          <FooterEle
            label={t("intervenant.title")}
            icon={"Image"}
            file={file}
            setFile={setFile}
            handleSubmit={handleSubmit}
            edit={editIntervenant}
          />}
        title={
          <DrawerHeader
            icon={"UsersRound"}
            title={t("intervenant.title")}
          />
        }
      >
        <IntervenantsDrawer
          formData={formData}
          handleChange={handleChange}
          handeAntDValues={handeAntDValues}
          selectedTab={selectedTab}
          setSelectedTab={onTabChangeHandler}
          locations={locations}
          edit={editIntervenant}
        />
      </CustomDrawer>

      <CustomModal open={showModal}>
        <ConfirmationModal
          text="Are you sure you want to delete this intervenant ?"
          onCancel={onCloseModal}
          onOk={handleDeleteIntervenant}
        />
      </CustomModal>
    </Page>
  );
};

export default Intervenants;
