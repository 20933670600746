import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAllLocations } from "../redux/slices/appConfigSlice";
import api from "../utils/api";

const useAllLocations = () => {
   const { allLocations } = useSelector(state => state.appConfigReducer);
   const dispatch = useDispatch();

   const fetchAllLocations = async () => {
      try {

         const res = await api.get("/location/getAllLocations");
         dispatch(setAllLocations(res.data));

      } catch (error) {
         console.log("Error occured while fetching all locations", error);
      }
   }

   useEffect(() => {
      if (!allLocations) {
         fetchAllLocations();
      }
   }, [allLocations])
};

export default useAllLocations;
