import React from "react";
import { Collapse, Tag } from "antd";
import IndividualIntervantion from "./clients/IndividualIntervantion";
import interventionStatusTagColor from "../utils/interventionStatusTagColor";
import Icon from "./Icon";
const IntervantionsCollapse = ({ intervantionList }) => {
  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={[]}
      expandIconPosition={"end"}
      accordion
      className="collapsy"
      items={refactorIntervantionList(intervantionList)}
    />
  );
};

export default IntervantionsCollapse;

//  function to return the clientList array in valid to map that array intro the antD accrordion

const refactorIntervantionList = (intervantionList) => {
  return intervantionList?.map((data) => {
    const key = data.id;
    const label = (
      <div
        className={`flex transition ease-in cursor-pointer w-full mx-[20px] items-center gap-4`}
      >
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <h3 className="text-[16px] font-bold">{data?.title}</h3>
            <Tag color={interventionStatusTagColor(data?.status)}>
              {data?.status}
            </Tag>
          </div>
          {
            data?.firstName &&
            <p>{`${data?.firstName} ${data?.lastName}`}</p>
          }

          <div className="flex w-full justify-self-auto gap-1 ">
            <p className="text-[12px] font-bold flex gap-1 items-center text-gray-500 absolute bottom-2 right-[35px]">
              <Icon name={"MapPin"} size={15} color={"gray"} />
              {data?.place}
            </p>
          </div>
        </div>
      </div>
    );
    const children = <IndividualIntervantion data={data} />;

    return {
      key,
      label,
      children,
    };
  });
};
