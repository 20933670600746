import React, { useState } from "react";
import OptionSwiper from "../OptionSwiper";
import Sublocation from "../lists/locationsList";
import Equipements from "../lists/equipementsList";
import Interventions from "../lists/interventionsList";
import { intervanantsOptions } from "../../constant/constant";
import AutomationList from "../lists/automationList";
import Intervenant_Interventions from "./Intervenant_Interventions";

export default () => {
  const [activeTab, setActiveTab] = useState("interventions");
  return (
    <div className="w-full md:w-[500px] lg:w-[400px] xl:w-[500px] flex flex-col flex-1 gap-2">
      <OptionSwiper
        options={intervanantsOptions}
        sliderSettings={{ slidesToShow: 4 }}
        {...{ activeTab, setActiveTab }}                                                   
      />
      <div className="overflow-auto">
        <RenderTabs activeTab={activeTab} />
      </div>
    </div>
  );
};

const RenderTabs = ({ activeTab }) => {
  switch (activeTab) {
    case "interventions":
      return <Intervenant_Interventions />
    case "maintenance":
      return <div> Comming soon </div>;
    case "automation":
      return (
        <div>
          <AutomationList />
        </div>
      );
    case "contracts":
      return <div> Comming soon </div>;
  }
};
