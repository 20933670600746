import React, { useEffect, useState } from 'react';
import { TableContextProvider } from '../context/TableContext';
import Page from './Page';
import Card from '../components/Card';
import Icon from '../components/Icon';

import TableContainer from '../components/table/TableContainer';
import PublisherModal from '../components/modals/PublisherModal';
import { staffConfiguration as tableConfiguration } from '../constant/tablesParam';
import api from '../utils/api';
import { mockedStaff } from '../components/table/dummyData';
import { useAppContext } from '../context/Context';
import { Navigate } from 'react-router-dom';
import Input from '../components/Input';
const Staff = () => {
  const { userData } = useAppContext();
  const [showStaffModal, setShowStaffModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const updateUsers = invitedUsers.map((obj) => ({ ...obj, status: 'active' }));
  const actions = [
    {
      name: 'Invite',
      action: () => setShowStaffModal(true),
      type: 'primary',
      icon: {
        name: 'UserPlus',
        color: 'white',
      },
    },
  ];

  const emptyState = {
    actions,
    title: 'Invite your first team member',
    subtitle: 'Invite collaborators to your team to manage your organization',
    image: 'desk.png',
  };

  const fetchInvitedUsers = async () => {
    if (userData.role === 'admin') {
      try {
        const { data } = await api.get('/get-users');
        setInvitedUsers(data.data);
        console.log(data.data);
      } catch (error) {
        console.log(error, 'error dataaa');
      }
    } else {
      return;
    }
  };
  useEffect(() => {
    fetchInvitedUsers();
  }, []);

  return userData.role === 'admin' ? (
    <Page title='Staff' actions={actions}>
      <div className='flex gap-4'>
        <div className='w-[400px]'>
          <Card title='Staff' className='h-[90vh]'>
            <div>
              <Input label={'Search'} icon={{ name: 'Search' }} />
            </div>
            <div className='flex gap-2 capitalize '>
              {['admin', 'manager', 'technicien'].map((role) => {
                return (
                  <div
                    className={`border-2 cursor-pointer hover:shadow-lg rounded-full px-1 text-[12px] font-bold ${
                      role === 'admin' ? ' border-orange text-orange' : ''
                    }  ${role === 'manager' ? ' border-dark text-dark' : ''}  ${
                      role === 'technicien'
                        ? ' border-[#16a34a] text-[#16a34a]'
                        : ''
                    }`}
                  >
                    {role}
                  </div>
                );
              })}
            </div>
            <div>
              {mockedStaff.map((user) => {
                const { fullName, profileImg, status, role } = user;
                return (
                  <div
                    onClick={() => setSelectedItem(user)}
                    className='flex items-center capitalize  border-t p-2  border-[#d4d4d8] hover:bg-[#f1f5f9] cursor-pointer transition'
                  >
                    <div className='w-10 rounded-full relative'>
                      <img
                        className=' rounded-full'
                        src={profileImg}
                        alt='profile'
                      />
                      <div
                        className={`absolute bottom-0 right-0  w-2 h-2 rounded-full shadow ${
                          status == 'actif' ? 'bg-[#22c55e]' : 'bg-[#f59e0b]'
                        }`}
                      ></div>
                    </div>
                    <div className='align-center flex flex-col flex-1 mx-4'>
                      <div className='font-medium'>{fullName}</div>
                      <div></div>
                    </div>
                    <div
                      className={`border-2 rounded-full px-1 text-[12px] font-bold ${
                        role === 'admin' ? ' border-orange text-orange' : ''
                      }  ${
                        role === 'manager' ? ' border-dark text-dark' : ''
                      }  ${
                        role === 'technicien'
                          ? ' border-[#16a34a] text-[#16a34a]'
                          : ''
                      }`}
                    >
                      {role}
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>
        </div>
        <div className='flex-1'>
          {selectedItem ? (
            <Card title='Preview'></Card>
          ) : (
            <div className='flex items-center justify-center h-[90vh]'>
              <div className='w-40 flex items-center justify-center flex-col'>
                <div className=''>
                  <Icon name='Contact' size={90} color='#F87B1B' />
                </div>
                <div className='items-center justify-center font-medium text-[22px]'>
                  Select a Staff
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <TableContextProvider
        {...{ tableConfiguration, data: updateUsers, emptyState }}
      >
        <PublisherModal
          setShowStaffModal={setShowStaffModal}
          fetchInvitedUsers={fetchInvitedUsers}
          showStaffModal={showStaffModal}
        />
        <TableContainer title={"Accounts"} />
      </TableContextProvider> */}
    </Page>
  ) : (
    <Navigate to={'/'} />
  );
};

export default Staff;
