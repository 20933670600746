import react from "react";
import Icon from './Icon'


const Card = ({ children, title, icon, className="" }) => {
  return (
    <div className={`bg-white px-4 py-3 flex flex-col gap-4 rounded-lg shadow ${className}`}>
      {(icon || title) && (
          <div className="flex items-center">
            {icon ? <div className={"pr-2"}><Icon name={icon} size="18" color="#090F4E" /></div> : undefined}
            {title ? (
              <label
                htmlFor=""
                className="text-[#090F4E] text-[18px]  font-medium"
              >
                {title}
              </label>
            ) : undefined}
          </div>
        )}

      {children}
    </div>
  );
};

export default Card;
