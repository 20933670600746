import React from "react";
import Icon from "./Icon";

const Input = ({
  onChange,
  type = "text",
  value,
  placeholder = "",
  label,
  icon,
  error = "",
  disabled,
}) => {
  return (
    <div className="flex gap-1 flex-col">
      {label && (
        <label
          htmlFor=""
          className="text-dark text-[14px] mx-1  font-medium"
        >
          {label}
        </label>
      )}
      <div>
        <div
          className={`px-2 h-10 justify-center border border-[#CAD1DB] rounded-lg flex items-center gap-2 transition-all 
            ${
              disabled
                ? "bg-white"
                : "bg-white focus-within:border-orange "
            }
            ${error ? "border-[#F5432C]" : ""}
            `}
        >
          {icon && <Icon {...icon} size="18" className={"text-dark"} />}
          <input
            type={type}
            className={`w-full text-[#63657E] text-[14px] h-full outline-none border-none ${
              disabled ? "bg-white" : "bg-white"
            }`}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
          />
        </div>
        {error && (
          <div className="mx-2 font-small text-[12px] text-[#F5432C]">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
