import { useSelector } from 'react-redux';
import { Tag } from 'antd';
import Icon from '../Icon';
import interventionStatusTagColor from '../../utils/interventionStatusTagColor';

const Intervenant_Interventions = () => {
  const { currentOpenedIntervenant } = useSelector(state => state.appConfigReducer);

  return (
    <div>
      {currentOpenedIntervenant?.interventions?.map((intervention, index) => (
        <div
          key={index}
          className={`flex transition ease-in cursor-pointer relative w-full items-center px-4 py-8 border-b border-gray-200 hover:bg-gray-100 bg-white`}
        >
          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <h3 className="text-[16px] font-bold">{intervention?.title}</h3>
              <Tag color={interventionStatusTagColor(intervention?.status)}>{intervention?.status}</Tag>
            </div>
            <p>{intervention?.description}</p>
            {intervention?.intervenant && (
              <p>{`${intervention?.intervenant?.firstName} ${intervention?.intervenant?.lastName}`}</p>
            )}
            <div className="flex w-full justify-self-auto gap-1">
              <p className="text-[12px] font-bold flex gap-1 items-center text-gray-500 absolute bottom-2 right-2">
                <Icon name={"MapPin"} size={15} color={"gray"} />
                {intervention?.place}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Intervenant_Interventions;
