import React, { useEffect, useState } from "react";
import { localisationButton } from "../../constant/constant";
import SelectButton from "../SelectButton";
import InputComp from "../InputComp";
import LocalisationAcc from "../accordion/LocalisationAcc";
import { useSelector } from "react-redux";

export default () => {
  const [selectedBtn, setSelectedBtn] = useState(0);
  const [inputData, setInputData] = useState("");
  const [location, setLocation] = useState([]);
  const { activeClient } = useSelector((state) => state.clientReducer);

  useEffect(() => {
    if (activeClient) {
      setLocation(activeClient?.clientOrgId?.location || []);
    }
  }, [activeClient])

  if (!activeClient) return <></>

  return (
    <div className="h-full rounded-lg shadow Clientscrollbar bg-white flex flex-col gap-4 w-full overflow-auto">
      <div className="flex flex-col gap-4 w-full p-4">
        <InputComp setInputData={setInputData} inputData={inputData} />
        <SelectButton
          setSelecteBtn={setSelectedBtn}
          selecteBtn={selectedBtn}
          data={localisationButton}
        />
      </div>
      <LocalisationAcc location={location} />
    </div>
  );
};
