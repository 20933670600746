import React, { useState } from "react";
import SelectButton from "../SelectButton";
import InputComp from "../InputComp";
import { LocationData, buttonEquipment } from "../../constant/constant";
import Icon from "../Icon";
import { Divider } from "antd";
export default () => {
  const [selectedBtn, setSelectedBtn] = useState(0);
  const [inputData, setInputData] = useState("");
  return (
    <div className="h-full p-4 rounded-lg shadow Clientscrollbar bg-white flex flex-col gap-4 w-full overflow-auto">
      <div className="flex flex-col gap-4 w-full p-4">
        <InputComp setInputData={setInputData} inputData={inputData} />
        <SelectButton
          setSelecteBtn={setSelectedBtn}
          selecteBtn={selectedBtn}
          data={buttonEquipment}
        />
      </div>
      <div>
        {LocationData.map((location, index) => {
          return (
            <>
              <div
                key={location.id}
                className="flex gap-4 justify-between items-center"
              >
                <div className="flex gap-4 items-center">
                  <input
                    className="text-5xl w-4 h-4 font-bold"
                    type="checkbox"
                  />
                  <div className="flex flex-col">
                    <h1 className="text-[16px] font-bold">
                      {location.subtitle}
                    </h1>
                    <p>{location.paragraph}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <button className="text-gray-500 hover:text-gray-700 ml-4 relative">
                    <Icon size="25" name={"ClipboardList"} color="gray" />
                    <span className="absolute top-[-5px] right-[-5px] bg-blue-950	h-4 w-4 text-white text-xs rounded-full px-1 ">
                      1
                    </span>
                  </button>
                  <button className="text-gray-500 hover:text-gray-700 ml-2">
                    <Icon size="25" name="MoreVertical" color="gray" />
                  </button>
                </div>
              </div>
              <Divider />
            </>
          );
        })}
      </div>
    </div>
  );
};
