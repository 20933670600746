import React from "react";

import Button from "./Button";
const EmptyState = ({ title = "", subtitle = "", image= "no-data.png", actions = [] }) => {
  return (
    <div className="flex items-center justify-center w-full text-secondary h-[600px]">
      <div className="flex items-center justify-center flex-1">
        <div className="flex flex-col justify-center w-1/2">
          <div className="my-8  items-center justify-center">
            <div className=" my-1 text-dark text-lg text-bold">{title}</div>
            <div>{subtitle}</div>
          </div>
          <div className="my-4 flex items-center  gap-4">
            {actions.map(({ action, name, type, icon }, index) => {
              return (
                <Button
                  key={index}
                  onClick={action}
                  value={name}
                  type={type}
                  icon={icon}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center flex-1">
        <img src={`images/${image}`} />
      </div>
      
    </div>
  );
};

export default EmptyState;
