import React from 'react';
import InputField from '../InputField';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.css';
const EquipmentDrawer = ({
  formData,
  handleChange,
  handelAntDValues,
  options,
}) => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-7'>
        <div className='flex flex-col gap-5'>
          <InputField
            handleChange={handleChange}
            value={formData.name}
            type={'text'}
            name={'name'}
            placeholder={"Nom de l'équipement"}
          />
          <InputField
            handleChange={handleChange}
            value={formData.description}
            type={'textarea'}
            name={'description'}
            placeholder={'Description'}
          />
          <Select
            mode='tags'
            style={{
              width: '100%',
              height: '3rem',
            }}
            placeholder='Catégorie'
            onChange={(value) => handelAntDValues(value, 'category')}
            options={options}
            value={formData?.category || []}
          />
        </div>
      </div>
      <div
        className="flex flex-col gap-5"
      >
        {/* <InputField
          handleChange={handleChange}
          value={formData.place}
          type={'text'}
          name={'place'}
          placeholder={'Lieu'}
        /> */}
        <Select
          style={{
            width: '100%',
            height: '3rem',
          }}
          options={options}
          placeholder="Lieu"
          onChange={(value) => handelAntDValues(value, 'place')}
          value={formData?.place}
        />
        <Select
          style={{
            width: '100%',
            height: '3rem',
          }}
          options={options}
          onChange={(value) => handelAntDValues(value, 'profession')}
          value={formData?.profession}
        />
        <InputField
          handleChange={handleChange}
          value={formData.brand}
          type={'text'}
          name={'brand'}
          placeholder={'Marque'}
        />
        <InputField
          handleChange={handleChange}
          value={formData.model}
          type={'text'}
          name={'model'}
          placeholder={'Modeld'}
        />
        <InputField
          handleChange={handleChange}
          value={formData.serialNumber}
          type={'text'}
          name={'serialNumber'}
          placeholder={'Numéro de série'}
        />
        <InputField
          handleChange={handleChange}
          value={formData.year}
          type={'text'}
          name={'year'}
          placeholder={'Année'}
        />
      </div>
    </div>
  );
};

export default EquipmentDrawer;
