import React from 'react'
import Icon from '../Icon'

const DrawerHeader = ({ title, icon, color = "bg-white" }) => {
    return (
        <div className={`flex h-full gap-4 items-center ${color}`}>
            <Icon name={icon} />
            <p>{title}</p>
        </div>
    )
}

export default DrawerHeader