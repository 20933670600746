import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GoEye, GoEyeClosed } from "react-icons/go";
import api from "../utils/api";
import Button from "../components/Button";

const IntervenantCreatePassword = () => {
   const { id } = useParams();
   const [showTempPassword, setShowTempPassword] = useState(false);
   const [showPassword, setShowPassword] = useState(false);
   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const navigate = useNavigate();
   const [error, setError] = useState("");
   const [formData, setFormData] = useState({
      tempPassword: "",
      password: "",
      confirmPassword: "",
   });

   const handleChange = (e) => {
      const name = e.target.name;
      const value = e.target.value;

      setFormData((prev) => prev = { ...prev, [name]: value })
   };

   const isFormDataFilled = () => {
      for (const key in formData) {
         if (formData.hasOwnProperty(key)) {
            if (formData[key] === "") {
               return false;
            }
         }
      }
      return true;
   };

   const handleSubmit = async (e) => {
      e.preventDefault();
      try {
         const { tempPassword, password, confirmPassword } = formData;

         if (!id || !tempPassword || !password || !confirmPassword) return;

         const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]*$/;

         if (!passwordRegex.test(password)) {
            setError("Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character");
            return;
         }

         if (password != confirmPassword) {
            setError("Password and confirm password should be same");
            return;
         }

         setIsLoading(true);
         setError("");

         const body = {
            password,
            tempPassword
         }

         const res = await api.post(`/intervenant/create-password/${id}`, body);
         navigate("/signin");
      } catch (error) {
         console.log(error?.message);
         setError(error?.response?.data?.message);
      }

      setIsLoading(false);
   };

   return (
      <div className="min-h-screen bg-dark flex justify-center items-center flex-1">
         <div className="w-[450px] max-w-[450px] bg-white sm:rounded-[29px] flex gap-3 py-10 px-[60px] shadow-xl">
            <div className="w-full flex flex-col gap-5">
               <div className="flex flex-col gap-5">
                  <h1 className="text-[30px] font-medium text-center text-dark leading-[52px]">
                     PLANIGRAMME
                  </h1>
                  <p className="text-center text-[15px] leading-7 font-normal text-dark ">
                     Create your password{" "}
                  </p>
               </div>
               <form onSubmit={handleSubmit} className="flex flex-col gap-6">
                  <div className="flex flex-col gap-4">
                     <div className="flex flex-col gap-4">
                        <label
                           htmlFor="password"
                           className="text-[16px] font-medium leading-6 text-dark"
                        >
                           Temp Password
                        </label>
                        <div className="w-full flex justify-between items-center px-4 py-[14px] bg-white rounded-lg border border-[#CAD1DB]  focus-within:border-orange ">
                           <input
                              className="text-[#5A5C73] bg-transparent text-base font-normal leading-6 outline-none w-[90%]"
                              type={showTempPassword ? "text" : "password"}
                              value={formData.tempPassword}
                              name="tempPassword"
                              onChange={handleChange}
                              required
                           />
                           <button type="button" onClick={() => setShowTempPassword(!showTempPassword)}>
                              {showTempPassword ? (
                                 <GoEye className="text-2xl" />
                              ) : (
                                 <GoEyeClosed className="text-2xl" />
                              )}
                           </button>
                        </div>

                     </div>
                     <div className="flex flex-col gap-4">
                        <label
                           htmlFor="password"
                           className="text-[16px] font-medium leading-6 text-dark"
                        >
                           Password
                        </label>
                        <div className="w-full flex justify-between items-center px-4 py-[14px] bg-white rounded-lg border border-[#CAD1DB]  focus-within:border-orange ">
                           <input
                              className="text-[#5A5C73] bg-transparent text-base font-normal leading-6 outline-none w-[90%]"
                              type={showPassword ? "text" : "password"}
                              value={formData.password}
                              name="password"
                              onChange={handleChange}
                              required
                           />
                           <button type="button" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? (
                                 <GoEye className="text-2xl" />
                              ) : (
                                 <GoEyeClosed className="text-2xl" />
                              )}
                           </button>
                        </div>

                     </div>
                     <div className="flex flex-col gap-4">
                        <label
                           htmlFor="password"
                           className="text-[16px] font-medium leading-6 text-dark"
                        >
                           Confirm Password
                        </label>
                        <div className="w-full flex justify-between items-center px-4 py-[14px] bg-white rounded-lg border border-[#CAD1DB]  focus-within:border-orange ">
                           <input
                              className="text-[#5A5C73] bg-transparent text-base font-normal leading-6 outline-none w-[90%]"
                              type={showConfirmPassword ? "text" : "password"}
                              value={formData.confirmPassword}
                              name="confirmPassword"
                              onChange={handleChange}
                              required
                           />
                           <button type="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                              {showConfirmPassword ? (
                                 <GoEye className="text-2xl" />
                              ) : (
                                 <GoEyeClosed className="text-2xl" />
                              )}
                           </button>
                        </div>

                     </div>
                  </div>
                  <div className="flex flex-col gap-3">
                     <Button
                        type="primary"
                        value={"Create password"}
                        disabled={!isFormDataFilled() || isLoading}
                        className="w-full bg-orange disabled:bg-gray-700 text-white text-center font-medium text-[16px] leading-6 h-14 rounded-lg"
                     />

                     <p className="text-red-700">{error}</p>
                  </div>
               </form>
            </div>
         </div>
      </div>
   );
};

export default IntervenantCreatePassword;