import React from "react";
import { intervenantsClients } from "../../constant/constant";
import Icon from "../Icon";
import { Switch, Divider } from "antd";
export default () => {
  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };
  return (
    <div className="bg-white rounded-lg p-4">
      {intervenantsClients.map((user) => (
        <>
          <div
            key={user.id}
            className="flex justify-between gap-3 items-center p-4 bg-white rounded-md"
          >
            <div className="flex items-center">
              {user.avatar ? (
                <img
                  className="w-10 rounded-md mr-2"
                  src={user.avatar}
                  alt="image"
                />
              ) : (
                <div className="w-10 rounded-md bg-gray-500 h-10 text-white flex items-center justify-center text-xl mr-2">
                  {user.clientName.charAt(0)}
                </div>
              )}
              <div className="text-xs text-gray-500">
                <div className="font-bold">{user.clientName} </div>
                <div className="flex flex-row items-center gap-1">
                  <Icon size="14" name={"Navigation"} color="gray" />
                  2km
                </div>
              </div>
            </div>
            <div className="flex  items-center">
              <Switch
                className="toggle text-gray-500 bg-gray-200"
                size="small"
                defaultChecked
                onChange={(checked) => onChange(checked, user.id)}
              />
              <button className="text-gray-500 hover:text-gray-700 ml-2">
                <Icon size="16" name={"FilePenLine"} color="gray" />
              </button>
              <button className="text-gray-500 hover:text-gray-700 ml-2">
                <Icon size="16" name={"Trash2"} color="gray" />
              </button>
            </div>
          </div>
          <Divider className="m-0" />
        </>
      ))}
    </div>
  );
};
