import React from "react";
import { Outlet } from "react-router";
import useIntervenantDetail from "../hooks/useIntervenantDetail";

const MainIntervenantLayout = () => {

   useIntervenantDetail();

   return <Outlet />
};

export default MainIntervenantLayout;
