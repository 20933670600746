import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import Icon from "./Icon";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 7,
  slidesToScroll: 3,
};

const OptionSwiper = ({
  options,
  activeTab,
  setActiveTab,
  sliderSettings = {},
}) => {
  const { t } = useTranslation();

  const handleTabClick = (option) => {
    if (option.locked) {
      return;
    }
    setActiveTab(option.code);
  }

  return (
    <div className=" rounded-lg px-14 py-1 shadow-md linearBG">
      <div className="w-full">
        <div className="">
          <Slider
            {...{
              ...settings,
              slidesToShow: Math.min(7, options.length),
              ...sliderSettings,
            }}
          >
            {options.map((option) => (
              <div
                key={option}
                className="flex flex-col items-center justify-center py-4"
                onClick={() => handleTabClick(option)}
              >
                <div
                  className={`flex flex-col items-center cursor-pointer ${
                    activeTab && option?.code === activeTab
                      ? "text-white"
                      : "text-[#7b9cbf]"
                  }`}
                >
                  {typeof option.icon === "string" ? (
                    <Icon name={ option.locked ? "Lock" : option.icon} size={15} />
                  ) : (
                    option.icon
                  )}
                  <p className=" sm:text-[8px] xl:text-[10px] lg:text-[7px] 2xl:text-[12px]">
                    {t(option.label)}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default OptionSwiper;
