import React from "react";
import Icon from "./Icon";

const Button = ({
  type = "primary",
  onClick,
  icon,
  value,
  disabled,
  className="",
  loading,
}) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };
  return (
    <button
      className={`
            flex rounded-lg gap-2 px-3 py-2 transition justify-center items-center ${className} 
            ${
              type === "primary" && !disabled
                ? " bg-orange text-[#fff] cursor-pointer "
                : ""
            }
            ${
              type === "secondary" && !disabled
                ? "  text-[#0D6EFD] cursor-pointer "
                : ""
            }
            ${
              type === "danger" && !disabled
                ? "  text-[#F5432C] cursor-pointer "
                : ""
            }
            ${
              disabled || loading
                ? " bg-[#CAD1DB] text-[#8E8F96] cursor-not-allowed	"
                : ""
            }
        `}
      onClick={handleClick}
    >
      {icon && <Icon name={icon.name} color={icon.color} />}
      <div className="">{loading ? "Loading..." : value}</div>
    </button>
  );
};

export default Button;
