import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUserDetail } from "../redux/slices/appConfigSlice";
import api from "../utils/api";

const useClientDetail = () => {
   const [loading, setLoading] = useState(true);

   const dispatch = useDispatch();

   const fetchUserDetail = async () => {
      setLoading(true);

      try {
         const res = await api.get("/client/GetClientDetail");
         const userDetail = res.data.user;
         dispatch(setUserDetail(userDetail));
      } catch (error) {
         console.log("Error occured file fetching user detail", error);
      }

      setLoading(false);
   }

   useEffect(() => {
      fetchUserDetail();
   }, [])

   return { loading }
};

export default useClientDetail;
