import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { useAppContext } from "../context/Context";
import { addRefresh, addToken, addUser } from "../utils/localStorage";
import Button from "../components/Button";
import api from "../utils/api";
import { useDispatch } from "react-redux";
import { setUserDetail } from "../redux/slices/appConfigSlice";
import HeroImg from "../components/HeroImg";
import { useTranslation } from "react-i18next";
const SignIn = () => {
  const { t } = useTranslation();
  const [eyeOpen, setEyeOpen] = useState(false);
  const { setUserData } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    // termsAndConditions: false,
    isSocialLogIn: false,
  });

  const isFormDataFilled = () => {
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (formData[key] === "") {
          return false;
        }
      }
    }
    return true;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setError("");
      const res = await api.post("/org/login", {
        email: formData.email,
        password: formData.password,
        // isSocialLogIn: formData.isSocialLogIn,
        // termsAndConditions: formData.termsAndConditions,
      });
      setUserData(res.data.user);
      dispatch(setUserDetail(res.data.user));
      addUser(res.data.user);
      addToken(res.data.accessToken);
      addRefresh(res.data.refreshToken);
      setIsLoading(false);
      navigate("/");
    } catch (error) {
      console.log(error);
      setError(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  {
    /* <img className="max-w-2xl" src="/images/calendar.gif" alt="" /> */
  }
  return (
    <div className="h-screen flex">

      <div className="w-[40%] h-full bg-[#fff] flex-col flex justify-center items-center  ">
        <div className="h-full flex justify-center items-center flex-col">
          <div className="w-[400px] bg-[#fff] rounded-[5px] flex gap-3  px-[40px]  ">
            <div className=" flex flex-col gap-5 flex-1">
              <div className="flex flex-col gap-1 items-center">
                {/* <div className="h-20  m-4 ">
                <img src="/images/logo2.png" alt="logo"/>
              </div> */}

                <h1 className="text-[30px] font-medium text-center text-dark leading-[52px]">
                  {t("login.title")}
                </h1>
                <p className="text-center text-[15px] leading-7 font-normal text-dark ">
                  {t("login.subtitle")}
                </p>
              </div>

              <form onSubmit={handleSubmit} className="flex flex-col gap-6">
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-4">
                    <label
                      htmlFor="fullname"
                      className="text-[16px] font-medium leading-6 text-[#090F4E]"
                    >
                      {t("login.email")}
                    </label>
                    <div className="w-full px-4 py-[14px] rounded border bg-white border-[#CAD1DB]  focus-within:border-orange ">
                      <input
                        className="bg-transparent text-[14px] text-dark text-base font-normal leading-6 outline-none w-[90%]"
                        type="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <label
                      htmlFor="password"
                      className="text-[16px] font-medium leading-6 text-[#090F4E]"
                    >
                      {t("login.password")}
                    </label>
                    <div className="w-full flex justify-between items-center bg-white px-4 py-[14px] rounded border border-[#CAD1DB]  focus-within:border-orange ">
                      <input
                        value={formData.password}
                        id="password"
                        onChange={handleChange}
                        className="text-dark text-[14px] bg-transparent text-base font-normal leading-6 outline-none w-[90%]"
                        type={eyeOpen ? "text" : "password"}
                      />
                      <button
                        type="button"
                        onClick={() => setEyeOpen(!eyeOpen)}
                      >
                        {eyeOpen ? (
                          <GoEye className="text-2xl" />
                        ) : (
                          <GoEyeClosed className="text-2xl" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="flex gap-2 ">
                <input
                  type="checkbox"
                  className="w-5 h-5 flex items-center p-[2px]"
                  id="termsAndConditions"
                  value={formData.termsAndConditions}
                  onChange={handleChange}
                />
                <label htmlFor="termsAndConditions" className="text-[14px]">
                  I agree to the terms and conditions and the privacy
                  policy
                </label>
              </div> */}
                <div className="flex flex-col gap-3">
                  <Button
                    disabled={!isFormDataFilled() || isLoading}
                    value={isLoading ? "Loading..." : "Login"}
                    className="w-full font-medium text-[16px] leading-6 h-14 "
                  />
                </div>
                <p className="text-red-700">{error}</p>
              </form>
            </div>
          </div>

            {/* <OAuth /> */}
          {/* <div className="flex flex-col ">
            <p className="w-full mt-2 text-center text-[14px] font-small leading-6 text-[#2b4d75]">
              {t("login.signUpMessage")}{" "}
              <Link to={"/signup"} className="text-orange">
                {t("login.signUp")}
              </Link>
            </p>
          </div> */}
        </div>

        <div className="bg-[#f1f4f8] w-full p-4">
          <p className="w-full mt-2 text-center text-[14px] font-small leading-6 text-[#6d777f]">
            2024 PLANIGRAMME. All Rights Reserved |{" "}
            <Link
              className="text-orange"
              onClick={() => window.open("/privacy-policy", "_blank")}
            >
              Privacy policy
            </Link>
          </p>
        </div>
      </div>
      <HeroImg />
    </div>
  );
};

export default SignIn;
