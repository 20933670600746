import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedIntervention, updateIntervention } from "../../../redux/slices/interventionSlice";
import Icon from "../../Icon";
import api from "../../../utils/api";
import { DatePicker } from "antd";
import moment from "moment";

const AssignedInterventionDetail = () => {
   const { selectedIntervention } = useSelector(state => state.interventionReducer);

   const [startDate, setStartDate] = useState(null);
   const [endDate, setEndDate] = useState(null);
   const [loading, setLoading] = useState(false);

   const dispatch = useDispatch();

   const updateInterventionHandler = async (body) => {
      if (!body || !selectedIntervention?._id) return;
      setLoading(true);
      try {
         const res = await api.put(`/intervention/updateIntervention/${selectedIntervention._id}`, body);
         dispatch(setSelectedIntervention(res.data));
         dispatch(updateIntervention(res.data))
      } catch (error) {
         console.log("error occurred while updating intervention", error);
      }
      setLoading(false);
   };

   const onStartDateChangeHandler = (date) => {
      const body = {
         startDate: date,
      };
      updateInterventionHandler(body);
   };

   const onEndDateChangeHandler = (date) => {
      const body = {
         endDate: date,
      };
      updateInterventionHandler(body);
   };

   const handleCompletion = () => {
      const body = {
         status: "completed",
      };
      updateInterventionHandler(body);
   };

   const handleRejection = () => {
      const body = {
         status: "cancelled",
      };
      updateInterventionHandler(body);
   };

   const handleReset = () => {
      const body = {
         status: "in progress",
      };
      updateInterventionHandler(body);
   };

   const disabledStartDate = (current) => {
      return current && current < moment().startOf('day');
   };

   const disabledEndDate = (current) => {
      return current && startDate && current < startDate.startOf('day');
   };

   useEffect(() => {
      if (selectedIntervention) {
         setStartDate(selectedIntervention.startDate ? moment(selectedIntervention.startDate) : null);
         setEndDate(selectedIntervention.endDate ? moment(selectedIntervention.endDate) : null);
      }
   }, [selectedIntervention]);

   if (!selectedIntervention) return <></>;

   return (
      <div className="bg-white rounded-lg p-4">
         <div className="px-1 flex flex-col gap-4">
            <div className="flex items-center gap-4">
               <div><Icon name={"ShieldAlert"} color="gray" /></div>
               <div>
                  <h4 className="text-slate-700 font-bold text-[14px] ">
                     {selectedIntervention?.criticality}
                  </h4>
               </div>
            </div>
            <div className="flex items-center gap-4">
               <div><Icon name={"BookType"} color="gray" /></div>
               <div>
                  <h4 className="text-slate-700 font-bold text-[14px] ">
                     {selectedIntervention?.title}
                  </h4>
               </div>
            </div>
            <div className="flex items-center gap-4">
               <div><Icon name={"Quote"} color="gray" /></div>
               <div>
                  <h4 className="text-slate-700 font-bold text-[14px] ">
                     {selectedIntervention?.description}
                  </h4>
               </div>
            </div>
            <div className="flex items-center gap-4">
               <div><Icon name={"MapPin"} color="gray" /></div>
               <div>
                  <h4 className="text-slate-700 font-bold text-[14px] ">
                     {selectedIntervention?.place}
                  </h4>
               </div>
            </div>
            <div className="flex flex-col gap-4">
               {
                  selectedIntervention?.intervenant &&
                  <div className="flex gap-4">
                     <div><Icon name={"User"} color="gray" /></div>
                     <div>
                        <h4 className="text-slate-700 font-bold text-[14px] ">
                           {`${selectedIntervention?.intervenant?.firstName} ${selectedIntervention?.intervenant?.lastName}`}
                        </h4>
                     </div>
                  </div>
               }
               <div className="flex-1 p-3 bg-white">
                  <div className="flex flex-col gap-4">
                     <div className="flex gap-4">
                        <DatePicker
                           className="w-full"
                           placeholder="Start Date"
                           disabled={loading}
                           value={startDate}
                           onChange={onStartDateChangeHandler}
                           disabledDate={disabledStartDate}
                        />
                        <DatePicker
                           className="w-full"
                           placeholder="End Date"
                           disabled={loading || !startDate}
                           value={endDate}
                           onChange={onEndDateChangeHandler}
                           disabledDate={disabledEndDate}
                        />
                     </div>
                     {
                        selectedIntervention?.status == "in progress" &&
                        <div className="flex justify-end items-center gap-4">
                        <button
                           className="py-1 px-2 bg-[#EF392A] text-white flex items-center gap-8 rounded-lg shadow-md disabled:bg-[#F5F5F5] disabled:border disabled:text-[#B8B8B8] disabled:cursor-not-allowed"
                           disabled={loading}
                           onClick={handleRejection}
                        >
                           Rejected
                           <Icon name={"ThumbsDown"} size={15} color={"white"} />
                        </button>
                        <button
                           className="py-1 px-2 bg-[#42A646] text-white flex items-center gap-8 rounded-lg shadow-md disabled:bg-[#F5F5F5] disabled:border disabled:text-[#B8B8B8] disabled:cursor-not-allowed"
                           disabled={loading}
                           onClick={handleCompletion}
                        >
                           Completed
                           <Icon name={"ThumbsUp"} size={15} color={"white"} />
                        </button>
                     </div>
                     }
                     {
                        selectedIntervention?.status != "in progress" &&
                        <div className="flex justify-end items-center gap-4">
                        <button
                           className="py-1 px-2 bg-black text-white flex items-center gap-8 rounded-lg shadow-md disabled:bg-[#F5F5F5] disabled:border disabled:text-[#B8B8B8] disabled:cursor-not-allowed"
                           disabled={loading}
                           onClick={handleReset}
                        >
                           Reset
                           <Icon name={"RotateCcw"} size={15} color={"white"} />
                        </button>
                     </div>
                     }
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default AssignedInterventionDetail;