export const validShopifyDomain = (shop) => {
  return /[a-zA-Z0-9][a-zA-Z0-9-]*.myshopify.com$/.test(shop);
};

export const handleLogin = (loginCallback) => {
  window.FB.login(
    function (response) {
      console.log(response);
      if (response) {
        loginCallback(response);
      }
    },
    {
      config_id: "275441358750788",
    }
  );
};
