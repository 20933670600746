import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   clientList: null,
   activeClient: null,
}

const clientSlice = createSlice({
   name: 'clientSlice',
   initialState,
   reducers: {
      setClientList: (state, action) => {
         state.clientList = action.payload;
      },
      addNewClient: (state, action) => {
         state.clientList = [...state.clientList, action.payload];
      },
      setActiveClient: (state, action) => {
         state.activeClient = action.payload;
      }
   }
})

export default clientSlice.reducer;

export const {
   setClientList,
   addNewClient,
   setActiveClient,
} = clientSlice.actions;