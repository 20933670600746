import React from "react";
import InputField from "../InputField";
import PhoneInputField from "../PhoneInput";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

const LocalisationDrawer = ({ formData, handleChange, handelAntDValues, locationClients }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-7">
        <InputField
          handleChange={handleChange}
          value={formData.address}
          type={"text"}
          name={"address"}
          placeholder={t("address")}
        />
        <InputField
          handleChange={handleChange}
          value={formData.placeName}
          type={"text"}
          name={"placeName"}
          placeholder={"Nom du lieu"}
        />
        <InputField
          handleChange={handleChange}
          value={formData.email}
          type={"email"}
          name={"email"}
          placeholder={"Email"}
        />
        <PhoneInputField
          handleChange={handelAntDValues}
          value={formData.phone}
          name={"phone"}
          placeholder={"+33 4 98 97 56 47"}
        />
        <Select
          style={{ width: '100%', height: '3rem' }}
          placeholder="clients"
          options={locationClients}
          value={formData.client || null}
          onChange={(value) => {
            handelAntDValues(value, "client")
          }}
        />
      </div>
    </div>
  );
};

export default LocalisationDrawer;
