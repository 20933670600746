import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import Icon from "../Icon";
import { useTranslation } from "react-i18next";
import { setLocation } from "../../redux/slices/locationSlice";
import { useDispatch } from "react-redux";
const LocalisationAcc = ({
  location,
  editLocation = () => { },
  deleteLocation = () => { },
}) => {
  const [activeLocation, setActiveLocation] = useState(null);
  const dispatch = useDispatch();

  const handleCollapseChange = (key) => {
    const activeLoc = location.find((loc) => loc._id == key);
    if (activeLoc) {
      setActiveLocation(activeLoc.address);
    } else {
      setActiveLocation(null);
    }
  };
  useEffect(() => {
    dispatch(setLocation(activeLocation));
  }, [activeLocation]);

  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={[]}
      expandIconPosition={"end"}
      accordion
      className="collapsy"
      items={refactorLocation(location, editLocation, deleteLocation)}
      onChange={handleCollapseChange}
    />
  );
};

export default LocalisationAcc;

const refactorLocation = (location, editLocation, deleteLocation) => {
  return location?.map((location) => {
    const key = location._id;
    const label = (
      <div
        className={`flex transition ease-in cursor-pointer relative w-full items-center`}
      >
        <div className="flex flex-col">
          <div className="flex items-center gap-4">
            {location?.image && (
              <img
                className="w-12 rounded-md"
                src={`${location.image}`}
                alt="image"
              />
            )}
            <h3 className="text-[16px] font-bold">{location?.place || "-"}</h3>
          </div>
          {/* <div className="mt-2 flex items-center gap-2">
            <div onClick={() => editLocation(location)}>
              <Icon name={"Pencil"} size={18} color={"gray"} />
            </div>
            <div onClick={() => deleteLocation(location)}>
              <Icon name={"Trash2"} size={18} color={"red"} />
            </div>
          </div> */}
        </div>
      </div>
    );
    const children = <AdditionalDetail location={location} />;
    return {
      key,
      label,
      children,
    };
  });
};

const AdditionalDetail = ({ location }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col w-full gap-5">
      <div className="flex flex-col gap-5">
        <div className="py-4 w-full flex gap-4">
          <div className="px-1 flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <Icon name={"Mail"} color="gray" />
              <div className="">
                <h4 className="text-slate-700 font-bold text-[14px] ">Email</h4>
                <p className="text-gray-500 text-[12px]">
                  {location?.email || "-"}
                </p>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <Icon name={"Phone"} color="gray" />
              <div className="">
                <h4 className="text-slate-700 font-bold text-[14px] ">
                  {t("phoneNumber")}
                </h4>
                <p className="text-gray-500 text-[12px]">
                  {location?.phoneNumber || "-"}
                </p>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <Icon name={"Link"} color="gray" />
              <div className="">
                <h4 className="text-slate-700 font-bold text-[14px] ">
                  {t("address")}
                </h4>
                <p className="text-gray-500 text-[12px]">
                  {location?.address || "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
