import React from "react";
import Pagination from "rc-pagination";
import { useTableContext } from "../context/TableContext";
import Icon from "./Icon";

const PaginationComp = () => {
  const {
    countPerPage,
    goToPage,
    currentPage,
    filtredData: fullList,
  } = useTableContext();

  return (
    <div className="flex justify-end">
      <div
        onClick={() => {
          if (currentPage > 1) {
            goToPage(currentPage - 1);
          } else {
            goToPage(Math.ceil(fullList.length / countPerPage));
          }
        }}
        className="px-[2px] py-[2px] border rounded border-[#CAD1DB] bg-white"
      >
        <Icon name={"ChevronLeft"} />
      </div>
      <Pagination
        className="flex gap-4 items-center cursor-pointer pagination"
        pageSize={countPerPage}
        onChange={goToPage}
        current={currentPage}
        total={fullList.length}
        showLessItems
        itemRender={(current, type, element) => {
          if (type === "page") {
            const isActive = current === currentPage;
            return (
              <span
                className={`${isActive
                  ? "bg-[#0D6EFD] text-white"
                  : "bg-white text-[#63657E]"
                  } custom-pagination-item `}
              >
                {current}
              </span>
            );
          }
          return element;
        }}
      />
      <div
        onClick={() => {
          if (currentPage <= Math.round(fullList.length / countPerPage)) {
            goToPage(currentPage + 1);
          } else {
            goToPage(1);
          }
        }}
        className="px-[2px] py-[2px] border rounded border-[#CAD1DB] bg-white"
      >
        <Icon name={"ChevronRight"} />
      </div>
    </div>
  );
};

export default PaginationComp;
