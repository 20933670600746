import React from 'react'
import Icon from './Icon'
import { useTranslation } from 'react-i18next'

const InputComp = ({ inputData, setInputData, filterClients = () => { } }) => {
    const { t } = useTranslation();

    const onChangehandler = (e) => {
        const searchValue = e.target.value;
        setInputData(searchValue);
        filterClients(searchValue);
    }

    return (
        <div className='flex justify-between items-center' >
            <div className='flex gap-2 items-center w-full'>
                <Icon name={"EllipsisVertical"} color='gray' size={20} />
                <input value={inputData} onChange={onChangehandler} className='bg-[#FFF] w-full outline-none py-1 px-1' placeholder={t("search") + "..."} type="text" />
            </div>
            <div className='flex gap-4'>
                <button>
                    <Icon name={"RotateCw"} color='#333634' size={20} />
                </button>
                <button>
                    <Icon name={"SlidersHorizontal"} color='#333634' size={20} />
                </button>
                <button>
                    <Icon name={"Save"} color='#333634' size={20} />
                </button>
            </div>

        </div>
    )
}

export default InputComp