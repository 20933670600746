import React from "react";
import Icon from "./Icon";
import { useAppContext } from "../context/Context";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedIntervention } from "../redux/slices/interventionSlice";
import { Tag } from "antd";
import interventionStatusTagColor from "../utils/interventionStatusTagColor";

const InterventionsData = ({ interventions }) => {
  const { setInterventionId } = useAppContext();
  const dispatch = useDispatch();
  const handleClick = (intervention) => {
    dispatch(setSelectedIntervention(intervention));
  };

  return interventions?.map((intervention) => {
    const key = intervention.id;
    const label = (
      <div
        className={`flex transition ease-in cursor-pointer relative w-full items-center px-4 py-8 border-b border-gray-200 hover:bg-gray-100`}
        onClick={() => handleClick(intervention)}
      >
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <h3 className="text-[16px] font-bold">{intervention?.title}</h3>
            <Tag color={interventionStatusTagColor(intervention?.status)}>
              {intervention?.status}
            </Tag>
          </div>
          <p>{intervention?.description}</p>
          {intervention?.intervenant && (
            <p>{`${intervention?.intervenant?.firstName} ${intervention?.intervenant?.lastName}`}</p>
          )}
          {/* {location.subtitle2 && ( */}
          <div className="flex w-full justify-self-auto gap-1 ">
            <p className="text-[12px] font-bold flex gap-1 items-center text-gray-500 absolute bottom-2 right-2">
              <Icon name={"MapPin"} size={15} color={"gray"} />
              {intervention?.place}
            </p>
            {/* <p className="text-[12px] text-gray-600">{location.date}</p> */}
          </div>
          {/* )} */}
          {/* {location.paragraph && (
            <p className="text-[12px] text-gray-600">{location.paragraph}</p>
          )} */}
        </div>
      </div>
    );
    return <div key={key}>{label}</div>;
  });
};

export default InterventionsData;
