import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../Icon";
import { DatePicker, Select } from "antd";
import api from "../../utils/api";
import { setSelectedIntervention } from "../../redux/slices/interventionSlice";
import { updateIntervention } from "../../redux/slices/appConfigSlice";

const InterventionDetail = () => {
   const { selectedIntervention } = useSelector(state => state.interventionReducer);
   const { allIntervenants } = useSelector(state => state.appConfigReducer);
   const [intervenants, setIntervenants] = useState([]);
   const [currentIntervenant, setCurrentIntervenant] = useState(null);

   const dispatch = useDispatch();

   function convertDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = date.getUTCFullYear();
      
      return `${day}/${month}/${year}`;
    }

   const fetchAllIntervenants = () => {
      const currentIntervenants = allIntervenants;
      const options = [];
      currentIntervenants?.forEach((intervenant) => {
         options.push({
            label: `${intervenant.firstName} ${intervenant.lastName}`,
            value: intervenant._id,
         })
      })
      setIntervenants(options);
   }

   const updateAssignedIntervenant = async () => {
      try {

         const body = {
            intervenant: currentIntervenant
         }

         const res = await api.put(`/intervention/updateAssignedIntervenant/${selectedIntervention._id}`, body);
         dispatch(setSelectedIntervention(res.data));
         dispatch(updateIntervention(res.data));
      } catch (error) {
         console.log("error occured while updating assigned intervenant of itervention", error);
      }
   }

   const onSelectChangeHandler = (value) => {
      setCurrentIntervenant(value);
   }

   const onUpdateAssignedIntervenantHandler = () => {
      if (currentIntervenant == selectedIntervention?.intervenant?._id) return;

      updateAssignedIntervenant();
   }

   useEffect(() => {
      if (allIntervenants) {
         fetchAllIntervenants();
      }
   }, [allIntervenants])

   useEffect(() => {
      if (selectedIntervention && selectedIntervention?.intervenant?._id) {
         setCurrentIntervenant(selectedIntervention.intervenant._id)
      }
   }, [selectedIntervention])

   if (!selectedIntervention) return <></>

   return (
      <div className="bg-white rounded-lg p-4">
         {/* {filteredData.map((intervention) => ( */}
         <div className="px-1 flex flex-col gap-4">
            {/* {intervention.details &&
                intervention.details.map((d, i) => (
                  <div key={i} className="flex items-center gap-4">
                    <div>{d.icon}</div>
                    <div>
                      <h4 class="text-slate-700 font-bold text-[14px] ">
                        {d.name}
                      </h4>
                      <p class="text-gray-500 text-[12px]">{d.value}</p>
                    </div>
                  </div>
                ))} */}

            <div className="flex items-center gap-4">
               <div><Icon name={"ShieldAlert"} color="gray" /></div>
               <div>
                  <h4 class="text-slate-700 font-bold text-[14px] ">
                     {selectedIntervention?.criticality}
                  </h4>
               </div>
            </div>
            <div className="flex items-center gap-4">
               <div><Icon name={"BookType"} color="gray" /></div>
               <div>
                  <h4 class="text-slate-700 font-bold text-[14px] ">
                     {selectedIntervention?.title}
                  </h4>
               </div>
            </div>
            <div className="flex items-center gap-4">
               <div><Icon name={"Quote"} color="gray" /></div>
               <div>
                  <h4 class="text-slate-700 font-bold text-[14px] ">
                     {selectedIntervention?.description}
                  </h4>
               </div>
            </div>
            <div className="flex items-center gap-4">
               <div><Icon name={"MapPin"} color="gray" /></div>
               <div>
                  <h4 class="text-slate-700 font-bold text-[14px] ">
                     {selectedIntervention?.place}
                  </h4>
               </div>
            </div>
            <div className="flex flex-col gap-4">
               {
                  selectedIntervention?.intervenant &&
                  <div className="flex gap-4">
                     <div><Icon name={"User"} color="gray" /></div>

                     <div>
                        <h4 class="text-slate-700 font-bold text-[14px] ">
                           {`${selectedIntervention?.intervenant?.firstName} ${selectedIntervention?.intervenant?.lastName}`}
                        </h4>
                     </div>

                  </div>
               }
               {
                  selectedIntervention?.startDate &&
                  <div className="flex items-center gap-4">
                  <div><Icon name={"Calendar"} color="gray" /></div>
                  <div>
                     <h4 class="text-slate-700 font-bold text-[14px] ">
                        Start:- {convertDate(selectedIntervention?.startDate)}
                     </h4>
                  </div>
               </div>
               }

               {
                  selectedIntervention?.endDate &&
                  <div className="flex items-center gap-4">
                  <div><Icon name={"Calendar"} color="gray" /></div>
                  <div>
                     <h4 class="text-slate-700 font-bold text-[14px] ">
                        End:- {convertDate(selectedIntervention?.endDate)}
                     </h4>
                  </div>
               </div>
               }

               <div className="flex justify-between items-center">
                  <Select
                     style={{
                        width: '300px',
                        height: "3rem"
                     }}
                     placeholder="Utilisateurs"
                     options={intervenants}
                     value={currentIntervenant}
                     onChange={onSelectChangeHandler}
                  />

                  <button
                     className="border p-2 rounded-lg bg-[#F87B1B]/70 hover:bg-[#F87B1B]/90 text-white"
                     onClick={onUpdateAssignedIntervenantHandler}
                  >
                     Update
                  </button>
               </div>
            </div>
         </div>
         {/* ))} */}
      </div>
   )
};

export default InterventionDetail;
