// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-scrollbar::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
    height: 6px; /* Height of the scrollbar */
    background-color: #f5f5f5; /* Background color of the scrollbar */
    border-radius: 3px; /* Rounded corners */
}

/* Style the scrollbar thumb */
.select-scrollbar::-webkit-scrollbar-thumb {
    background-color: #c4c4c4; /* Color of the thumb */
    border-radius: 3px; /* Rounded corners */
}`, "",{"version":3,"sources":["webpack://./src/components/style.css"],"names":[],"mappings":"AAAA;IACI,UAAU,EAAE,2BAA2B;IACvC,WAAW,EAAE,4BAA4B;IACzC,yBAAyB,EAAE,sCAAsC;IACjE,kBAAkB,EAAE,oBAAoB;AAC5C;;AAEA,8BAA8B;AAC9B;IACI,yBAAyB,EAAE,uBAAuB;IAClD,kBAAkB,EAAE,oBAAoB;AAC5C","sourcesContent":[".select-scrollbar::-webkit-scrollbar {\n    width: 6px; /* Width of the scrollbar */\n    height: 6px; /* Height of the scrollbar */\n    background-color: #f5f5f5; /* Background color of the scrollbar */\n    border-radius: 3px; /* Rounded corners */\n}\n\n/* Style the scrollbar thumb */\n.select-scrollbar::-webkit-scrollbar-thumb {\n    background-color: #c4c4c4; /* Color of the thumb */\n    border-radius: 3px; /* Rounded corners */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
