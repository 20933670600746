import React, { useState } from "react";
import SelectButton from "../SelectButton";
import InputComp from "../InputComp";
import {
  buttonIntervanants
} from "../../constant/constant";
import IntervenantsCard from "../IntervenantsCard";

const IntervenantsList = ({ intervenants, editIntervenant = () => { }, deleteIntervenant = () => { } }) => {
  const [selecteBtn, setSelecteBtn] = useState(0);
  const [inputData, setInputData] = useState("");

  return (
    <div className="h-full rounded-lg shadow Clientscrollbar bg-white flex flex-col gap-4 w-full overflow-auto">
      <div className="flex flex-col gap-4 w-full p-4">
        <InputComp setInputData={setInputData} inputData={inputData} />
        <SelectButton
          setSelecteBtn={setSelecteBtn}
          selecteBtn={selecteBtn}
          data={buttonIntervanants}
        />
      </div>
      <IntervenantsCard intervenants={intervenants} editIntervenant={editIntervenant} deleteIntervenant={deleteIntervenant} />
    </div>
  );
};

export default IntervenantsList;
