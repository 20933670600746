import { createContext, useContext, useState, useEffect } from "react";
import api from "../utils/api";
import { getToken, getUser, getLang } from "../utils/localStorage";
const appContext = createContext();

export const ContextProvider = ({ children }) => {
  const [preview, setPreview] = useState();
  const [sidebar, setSidebar] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState(getUser());
  const [lang, setLang] = useState(getLang());
  const [productData, setProductData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(1);
  const [itinterventionId, setInterventionId] = useState(1);
  const [clientList, setClientList] = useState([]);
  // const fetchUser = async () => {
  //   try {
  //     const { data: user } = await api.get("/user");
  //     setUserData(user.data);
  //   } catch (error) {
  //     // TODO: handle error
  //     console.log(error);
  //   }
  // };

  // console.log(userData);

  // useEffect(() => {
  //   if (getToken()) {
  //     fetchUser();
  //   }
  // }, []);

  return (
    <appContext.Provider
      value={{
        sidebar,
        setSidebar,
        userData,
        setUserData,
        setShowModal,
        showModal,
        preview,
        setPreview,
        setProductData,
        productData,
        selectedUserId,
        setSelectedUserId,
        setInterventionId,
        itinterventionId,
        clientList,
        setClientList,
        lang, 
        setLang
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(appContext);
};
