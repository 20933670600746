import React, { useMemo, useState } from "react";
import Page from "./Page";
import Icon from "../components/Icon";
import InputComp from "../components/InputComp";
import SelectButton from "../components/SelectButton";
import LocationNavigator from "../components/navigators/LocattionNavigator";
import { localisationButton } from "../constant/constant";
import LocalisationAcc from "../components/accordion/LocalisationAcc";
import CustomDrawer from "../components/CustomDrawer";
import LocalisationDrawer from "../components/drawer/LocalisationDrawer";
import DrawerHeader from "../components/drawer/DrawerHeader";
import FooterEle from "../components/drawer/FooterEle";
import { useTranslation } from "react-i18next";
import api from "../utils/api";
import { useDispatch, useSelector } from "react-redux";
import {
  addLocation,
  deleteLocation,
  updateLocation,
} from "../redux/slices/appConfigSlice";
import CustomModal from "../components/modals/CustomModal";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import { locationData } from "../components/localisation/locationData";
import useClients from "../hooks/useClients";

const Localisation = () => {

  useClients();

  const { t } = useTranslation();
  const { userDetail } = useSelector((state) => state.appConfigReducer);
  const { clientList } = useSelector((state) => state.clientReducer);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState(0);
  const [inputData, setInputData] = useState("");
  const [editLocation, setEditLocation] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const initialFormState = {
    address: "",
    placeName: "",
    email: "",
    phone: "",
    client: ""
  };

  const [formData, setFormData] = useState(initialFormState);

  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handelAntDValues = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onCloseModal = () => {
    setSelectedLocation(null);
    setShowModal(false);
  };

  const onCloseDrawer = () => {
    setEditLocation(false);
    setSelectedLocation(null);
    setDrawerOpen(false);
  };

  const onEditLocation = (location) => {
    const data = {
      address: location?.address || "",
      placeName: location?.place || "",
      email: location?.email || "",
      phone: location?.phoneNumber || "",
      contact: location?.contactOnSite || "",
    };

    setFormData(data);
    setEditLocation(true);
    setSelectedLocation(location);

    setFile(null);
    setDrawerOpen(true);
  };

  const onDeleteLocation = (location) => {
    setSelectedLocation(location);
    setShowModal(true);
  };

  const handleDeleteLocation = async () => {
    if (!selectedLocation || !selectedLocation?._id) return;

    try {
      const res = await api.delete(`/location/${selectedLocation._id}`);
      dispatch(deleteLocation(selectedLocation._id));
      setFile(null);
      setShowModal(false);
    } catch (error) {
      console.log("Error occured while deleting location", error);
    }
  };

  const handleEditLocation = async () => {
    if (!editLocation || !selectedLocation || !selectedLocation?._id) return;

    const { address, placeName, email, phone } = formData;

    const data = {
      address,
      place: placeName,
      email,
      phoneNumber: phone,
    };

    const locationFormData = new FormData();

    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((item, index) => {
          locationFormData.append(`${key}[${index}]`, item);
        });
      } else {
        locationFormData.append(key, data[key]);
      }
    });

    if (file) locationFormData.append("image", file);

    try {
      const res = await api.put(
        `/location/${selectedLocation._id}`,
        locationFormData
      );
      dispatch(updateLocation(res.data));
      setFormData(initialFormState);
      setFile(null);
      setDrawerOpen(false);
    } catch (error) {
      console.log("Error occured while updating location", error);
    }
  };

  const handleSubmit = async () => {
    if (editLocation) {
      handleEditLocation();
      return;
    }

    const { address, placeName, email, phone, client } = formData;

    if (!address || !placeName || !client) return;

    if (!file) return;

    const locationFormData = new FormData();

    const data = {
      address,
      place: placeName,
      email,
      phoneNumber: phone,
      client
    };

    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((item, index) => {
          locationFormData.append(`${key}[${index}]`, item);
        });
      } else {
        locationFormData.append(key, data[key]);
      }
    });

    locationFormData.append("image", file);

    try {
      const res = await api.post("/location", locationFormData);
      dispatch(addLocation(res.data));
      setFormData(initialFormState);
      setFile(null);
      setDrawerOpen(false);
    } catch (error) {
      console.log("Error occured while creating location", error);
    }
  };

  const locationClients = useMemo(() => {
    if (!clientList) return [];
    return clientList?.map((client) => {
      return {
        value: client._id,
        label: `${client?.firstName} ${client?.lastName}`,
      }
    })
  }, [clientList])

  return (
    <Page>
      <div className="flex w-full h-[calc(100vh-25px)] gap-5">
        <div className="w-full md:w-[500px] lg:w-[400px] xl:w-[500px] rounded-lg  flex flex-col gap-4 p-0">
          <div className="flex items-center justify-between h-16 ">
            <h1 className="text-[#090F4E] text-2xl font-bold leading-10 capitalize">
              {t("locations_other")}
            </h1>
            <button
              onClick={() => setDrawerOpen(true)}
              className="p-2 bg-orange hover:shadow-lg h-10  transition ease-in text-white rounded flex items-center justify-center"
            >
              <Icon name="Plus" />
            </button>
          </div>
          <div className="h-full rounded-lg shadow Clientscrollbar bg-white flex flex-col gap-4 w-full overflow-auto">
            <div className="flex flex-col gap-4 w-full p-4">
              <InputComp setInputData={setInputData} inputData={inputData} />
              <SelectButton
                setSelecteBtn={setSelectedBtn}
                selecteBtn={selectedBtn}
                data={localisationButton}
              />
            </div>
            <LocalisationAcc
              location={userDetail?.location || []}
              // location={locationData}
              editLocation={onEditLocation}
              deleteLocation={onDeleteLocation}
            />
          </div>
        </div>
        <LocationNavigator />
      </div>
      <CustomDrawer
        open={drawerOpen}
        width={600}
        onClose={onCloseDrawer}
        footerEle={
          <FooterEle
            label={t("location.coverImage")}
            icon={"Image"}
            handleSubmit={handleSubmit}
            file={file}
            setFile={setFile}
            edit={editLocation}
          />
        }
        title={<DrawerHeader icon={"MapPinned"} title={t("location.title")} />}
      >
        <LocalisationDrawer
          handleChange={handleChange}
          formData={formData}
          handelAntDValues={handelAntDValues}
          locationClients={locationClients}
        />
      </CustomDrawer>

      <CustomModal open={showModal}>
        <ConfirmationModal
          text="Are you sure you want to delete this location ?"
          onCancel={onCloseModal}
          onOk={handleDeleteLocation}
        />
      </CustomModal>
    </Page>
  );
};

export default Localisation;
