import React from "react";
import { Collapse } from "antd";
import ClientDetail from "./clients/ClientDetail";
import { setActiveClient } from "../redux/slices/clientSlice";
import { useDispatch } from "react-redux";

const UserCard = ({ clientList }) => {

  const dispatch = useDispatch();

  const handleCollapseChange = (openKeys) => {
    const openItems = clientList.filter(client => openKeys.includes(client._id));
    const client = openItems?.[0] || null;
    dispatch(setActiveClient(client));
  };

  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={["1"]}
      expandIconPosition={"end"}
      accordion
      className="collapsy"
      items={refactorClientList(clientList)}
      onChange={handleCollapseChange}
    />
  );
};

export default UserCard;

//  function to return the clientList array in valid to map that array intro the antD accrordion

const refactorClientList = (clientList) => {
  return clientList?.map((client) => {
    const key = client._id;
    const label = (
      <div
        className={`flex transition ease-in cursor-pointer w-full  items-center gap-4 `}
      >
        <div className="relative">
          <div
            className={`w-3 h-3 -bottom-1 -right-1  rounded-full ${client?.status === "active"
              ? "bg-green-700"
              : client?.status === "pending"
                ? "bg-yellow-700"
                : "bg-gray-500"
              }  absolute`}
          ></div>
          {client.image ? (
            <img className="w-12 rounded-md" src={`${client.image}`} alt="image" />
          ) : (
            <div className="w-12 rounded-md bg-blue-950 h-12 text-white flex items-center justify-center text-xl">
              {client.firstName.charAt(0)}
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <p className="text-[16px] font-bold">{client?.firstName} {client?.lastName}</p>
          {client.subtitle && (
            <p className="text-[12px] text-gray-600">{client.subtitle}</p>
          )}
        </div>
      </div>
    );
    const children = <ClientDetail client={client} />;

    return {
      key,
      label,
      children,
    };
  });
};
