import React, { useEffect, useState } from "react";
import { options } from "../constant/constant";
import Icon from "../components/Icon";
import Page from "./Page";
import CustomDrawer from "../components/CustomDrawer";
import InterventionsDrawer from "../components/drawer/InterventionsDrawer";
import DrawerHeader from "../components/drawer/DrawerHeader";
import FooterEle from "../components/drawer/FooterEle";
import InterventionNavigator from "../components/navigators/InterventionNavigator";
import InterventionsList from "../components/lists/interventionsList";
import useAllIntervenants from "../hooks/useAllIntervenants";
import useAllLocations from "../hooks/useAllLocations";
import { useDispatch, useSelector } from "react-redux";
import api from "../utils/api";
import { addIntervention } from "../redux/slices/appConfigSlice";
import { useTranslation } from "react-i18next";
const Interventions = () => {
  useAllLocations();
  useAllIntervenants();
  const { t } = useTranslation();
  const { userDetail, allIntervenants, allLocations } = useSelector(
    (state) => state.appConfigReducer
  );

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [locations, setLocations] = useState([]);
  const [intervenants, setIntervenants] = useState([]);

  const initialFormState = {
    intervenant: "",
    place: "",
    description: "",
  };

  const [formData, setFormData] = useState(initialFormState);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAntDValues = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchAllLocations = () => {
    const currentLocations = allLocations;
    setLocations(() => {
      const updatedLocations = [];
      updatedLocations.push({ label: "All", value: "All" });
      const set = new Set();
      currentLocations.forEach((location) => {
        if (!set.has(location.place)) {
          updatedLocations.push({
            label: location.place,
            value: location.place,
          });
          set.add(location.place);
        }
      });
      return updatedLocations;
    });
  };

  const fetchAllIntervenants = () => {
    const currentIntervenants = allIntervenants;
    const options = [];
    currentIntervenants?.forEach((intervenant) => {
      options.push({
        label: `${intervenant.firstName} ${intervenant.lastName}`,
        value: intervenant._id,
      });
    });
    setIntervenants(options);
  };

  const handleSubmit = async (formData) => {
    try {
      const res = await api.post("/intervention", formData);
      dispatch(addIntervention(res.data));
      setDrawerOpen(false);
    } catch (error) {
      console.log("error occured while creating interventions", error);
    }
  };

  useEffect(() => {
    if (allLocations) {
      fetchAllLocations();
    }
  }, [allLocations]);

  useEffect(() => {
    if (allIntervenants) {
      fetchAllIntervenants();
    }
  }, [allIntervenants]);

  return (
    <Page>
      <div className="flex w-full h-[calc(100vh-25px)] gap-5">
        <div className="w-full md:w-[500px] lg:w-[400px] xl:w-[500px] rounded-lg  flex flex-col gap-4 p-0">
          <div className="flex items-center justify-between h-16">
            <h1 className="text-[#090F4E] text-2xl font-bold leading-10">
              {t("interventions")}
            </h1>
            <button
              onClick={() => setDrawerOpen(true)}
              className="p-2 bg-orange hover:shadow-lg h-10  transition ease-in text-white rounded flex items-center justify-center"
            >
              <Icon name="Plus" />
            </button>
          </div>
          <InterventionsList interventions={userDetail?.intervention || []} />
        </div>

        <InterventionNavigator />
      </div>
      <CustomDrawer
        open={drawerOpen}
        width={600}
        onClose={() => setDrawerOpen(false)}
        // footerEle={
        //   <FooterEle
        //     icon={"SquarePen"}
        //     label={"Pièce(s) jointe(s)"}
        //     paraIcon={"Link"}
        //     badge
        //     handleSubmit={handleSubmit}
        //     hideInput
        //   />
        // }
        title={
          <DrawerHeader title={t("workOrder.title")} icon={"UsersRound"} />
        }
      >
        <InterventionsDrawer
          handleChange={handleChange}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          formData={formData}
          handleAntDValues={handleAntDValues}
          locations={locations}
          intervenants={intervenants}
          handleFormSubmit={handleSubmit}
        />
      </CustomDrawer>
    </Page>
  );
};

export default Interventions;
