import React from "react";
import "./table.css";
const Switcher = ({state, onChange = () => {}}) => {
  return (
    <>
      <label className="switch" onClick={() => onChange(!state)}>
        <input type="checkbox" />
        <span className={`slider round ${state ? "active" : "inactive"}`} ></span>
      </label>
    </>
  );
};

export default Switcher;
