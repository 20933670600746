import React, { useEffect, useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import api from "../../utils/api";
import { useAppContext } from "../../context/Context";
const TableFilter = () => {
  const [filterModel, setFilterModel] = useState(false);
  const [stores, setStores] = useState();
  const [selectedStore, setSelectedStore] = useState("All Store");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.post(`/getAllStore`);
        const data = response.data.data;
        setStores(data);
        // setSelectedStore(data[0].shopName)
      } catch (e) {
        console.log("ERROR!");
        console.log(e);
      }
    };

    fetchData();
  }, []);

  const selectStorehandle = (id) => {
    localStorage.setItem("selectId", id);
    const findShopName = stores.find((item) => (item._id = id)).shopName;
    setSelectedStore(findShopName);
    setFilterModel(false);
  };
  const selectAllStoreHandle = () => {
    localStorage.removeItem("selectId");
    setSelectedStore("All Store");
    setFilterModel(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setFilterModel(!filterModel)}
        className="flex px-4 py-2 cursor-pointer rounded-lg gap-3 border border-[#E2E8F0] font-normal text-[#63657E] bg-white w-60 justify-between"
      >
        <h1 className="text-[14px]">{selectedStore}</h1>
        {filterModel ? <ChevronUp /> : <ChevronDown />}
      </button>
      {filterModel && (
        <ul className="absolute bg-white px-2 flex flex-col gap-2 w-full items-center top-12 rounded-lg py-2 shadow">
          <li className="cursor-pointer text-[#5A5C73] hover:bg-gray-200 rounded-lg  w-full">
            <button onClick={() => selectAllStoreHandle()}>All Store</button>
          </li>
          {stores?.map((store, index) => (
            <li
              key={index}
              className="cursor-pointer text-[#5A5C73] hover:bg-gray-200 rounded-lg w-full"
            >
              <button onClick={() => selectStorehandle(store._id)}>
                {" "}
                {store.shopName}
              </button>
            </li>
          ))}
          {/* <li className="cursor-pointer text-[#5A5C73] hover:bg-gray-200 rounded-lg text-center w-full">
            Recent
          </li>
          <li className="cursor-pointer text-[#5A5C73] hover:bg-gray-200 rounded-lg text-center w-full">
            Oldest
          </li>
          <li className="cursor-pointer text-[#5A5C73] hover:bg-gray-200 rounded-lg text-center w-full">
            Realest
          </li> */}
        </ul>
      )}
    </div>
  );
};

export default TableFilter;
