import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   userDetail: null,
   allLocations: null,
   allIntervenants: null,
   currentOpenedIntervenant: null,
}

const appConfigSlice = createSlice({
   name: 'appConfigSlice',
   initialState,
   reducers: {
      setUserDetail: (state, action) => {
         state.userDetail = action.payload;
      },
      addLocation: (state, action) => {
         if (!state.userDetail?.location) {
            state.userDetail.location = [action.payload];
         }
         else {
            const currentLocation = state.userDetail?.location || [];
            currentLocation.push(action.payload);
         }
      },
      updateLocation: (state, action) => {
         const locationId = action.payload._id;
         const locations = state.userDetail?.location || [];
         const index = locations.findIndex((location) => location._id === locationId);

         if (index !== -1) {
            const updatedLocations = [
               ...locations.slice(0, index),
               action.payload,
               ...locations.slice(index + 1)
            ];

            state.userDetail.location = updatedLocations;
         }
      },
      deleteLocation: (state, action) => {
         const locationId = action.payload;
         let locations = state.userDetail?.location || [];

         locations = locations.filter((location) => location._id !== locationId);

         state.userDetail.location = locations;
      },
      addIntervenant: (state, action) => {
         if (!state.userDetail?.intervenant) {
            state.userDetail.intervenant = [action.payload];
         }
         else {
            const currentIntervenant = state.userDetail?.intervenant || [];
            currentIntervenant.push(action.payload);
         }
      },
      updateIntervenant: (state, action) => {
         const intervenantId = action.payload._id;
         const intervenants = state.userDetail?.intervenant || [];
         const index = intervenants.findIndex((intervenant) => intervenant._id === intervenantId);

         if (index !== -1) {
            const updatedIntervenants = [
               ...intervenants.slice(0, index),
               action.payload,
               ...intervenants.slice(index + 1)
            ];

            state.userDetail.intervenant = updatedIntervenants;
         }
      },
      deleteIntervenant: (state, action) => {
         const intervenantId = action.payload;
         let intervenants = state.userDetail?.intervenant || [];

         intervenants = intervenants.filter((intervenant) => intervenant._id !== intervenantId);

         state.userDetail.intervenant = intervenants;
      },
      addEquipment: (state, action) => {
         if (!state.userDetail?.equipment) {
            state.userDetail.equipment = [action.payload];
         }
         else {
            const currentEquipment = state.userDetail?.equipment || [];
            currentEquipment.push(action.payload);
         }
      },
      updateEquipment: (state, action) => {
         const equipmentId = action.payload._id;
         const equipments = state.userDetail?.equipment || [];
         const index = equipments.findIndex((equipment) => equipment._id === equipmentId);

         if (index !== -1) {
            const updatedEquipments = [
               ...equipments.slice(0, index),
               action.payload,
               ...equipments.slice(index + 1)
            ];

            state.userDetail.equipment = updatedEquipments;
         }
      },
      deleteEquipment: (state, action) => {
         const equipmentId = action.payload;
         let equipments = state.userDetail?.equipment || [];

         equipments = equipments.filter((equipment) => equipment._id !== equipmentId);

         state.userDetail.equipment = equipments;
      },
      addIntervention: (state, action) => {
         if (!state.userDetail?.intervention) {
            state.userDetail.intervention = [action.payload];
         }
         else {
            const currentIntervention = state.userDetail?.intervention || [];
            currentIntervention.push(action.payload);
         }
      },
      updateIntervention: (state, action) => {
         const interventionId = action.payload._id;
         const interventions = state.userDetail?.intervention || [];
         const index = interventions.findIndex((intervention) => intervention._id === interventionId);

         if (index !== -1) {
            const updatedInterventions = [
               ...interventions.slice(0, index),
               action.payload,
               ...interventions.slice(index + 1)
            ];

            state.userDetail.intervention = updatedInterventions;
         }
      },
      setAllLocations: (state, action) => {
         state.allLocations = action.payload;
      },
      setAllIntervenants: (state, action) => {
         state.allIntervenants = action.payload;
      },
      setCurrentOpenedIntervenant: (state, action) => {
         state.currentOpenedIntervenant = action.payload;
      }
   }
})

export default appConfigSlice.reducer;

export const {
   setUserDetail,
   addLocation,
   updateLocation,
   deleteLocation,
   addIntervenant,
   updateIntervenant,
   deleteIntervenant,
   addEquipment,
   updateEquipment,
   deleteEquipment,
   addIntervention,
   updateIntervention,
   setAllLocations,
   setAllIntervenants,
   setCurrentOpenedIntervenant,
} = appConfigSlice.actions;