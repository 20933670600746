export const adConfiguration = [
  {
    type: "html",
    name: 'Name',
    value: ["image", "name"],
  },
  {
    type: "text",
    name: '#ID',
    value: "id"
  },
  {
    type: "text",
    name: 'Business Name',
    value: "businessName"
  },
  {
    type: "status",
    name: 'Status',
    value: "status"
  },
  {
    type: "actions",
    name: 'Actions',
  },
]


export const  campaignsConfiguration = [
  {
    type: "switch",
    onChange: console.log,
    name: "",
    value: "isActive",
  },
  {
    type: "html",
    name: "Product",
    value: ["images/tdImg.png", "title"],
  },
  {
    type: "text",
    name: "Store Name",
    value: "storeName",
  },
  {
    type: "text",
    name: "Pubslishers Name",
    value: "publisherName",
  },
  {
    type: "text",
    name: "Conversion",
    value: "conversion",
  },
  {
    type: "text",
    name: "Spent",
    value: "spent",
  },
  {
    type: "actions",
    name: "Actions",
  },
];



export const  storeProductsConfiguration = [
  {
    type: "html",
    name: "Product",
    value: ["coverImage", "title"], 
  },
  {
    type: "text",
    name: "Price",
    value: "price",
  },
];


export const staffConfiguration = [
  {
    type: "text",
    name: "Name",
    value: "fullname",
  },
  {
    type: "text",
    name: "Email",
    value: "email",
  },
  {
    type: "text",
    name: "Linked Products",
    value: "linkedProductsNumber",
  },
  {
    type: "text",
    name: "Linked Ad Accounts",
    value: "linkedAccountsNumber",
  },
  {
    type: "status",
    name: "Status",
    value: "status",
  },
  {
    type: "actions",
    name: "Actions",
  },
];

export const productstConfiguration = [
  {
    type: "html",
    name: "Product",
    value: ["coverImage", "title"],
  },
  {
    type: "text",
    name: "Store",
    value: "storeName",
    link: "adminLink"
  },
  {
    type: "text",
    name: "Cost",
    value: "cost",
  },
  {
    type: "status",
    name: "Status",
    value: "status",
  },
  {
    type: "actions",
    name: "Actions",
  },
];


export const invitationsConfiguration = [
  {
    type: "text",
    name: "Email",
    value: "email",
  },
  {
    type: "text",
    name: "Invited in",
    value: "createdAt",
  },
  {
    type: "text",
    name: "Link",
    value: "link",
    link: "link",
  },
  {
    type: "status",
    name: "Status",
    value: "status",
  },
  {
    type: "actions",
    name: "Actions",
  },
];

export const documentConfiguration = [
  {
    type: "text",
    name: "Name",
    value: "name",
  },
  {
    type: "text",
    name: "User",
    value: "user",
  },
  {
    type: "text",
    name: "Size",
    value: "size",
  },
  {
    type: "text",
    name: "Date",
    value: "date",
  },
]