
export const campaignsDummyData = [

];

export const mockedStaff = [
    {
        fullName: "Youssef El Idrissi",
        role:  "admin",
        status: Math.random() < 0.5 ? "actif" : "inactif",
        profileImg: `https://picsum.photos/200?random=${Math.random() * 100}`,
    },
    {
        fullName: "Fatima Zahra El Ouriaghli",
        role: "manager",
        status: Math.random() < 0.5 ? "actif" : "inactif",
        profileImg: `https://picsum.photos/200?random=${Math.random() * 100}`,
    },
    {
        fullName: "Omar Ait Moussa",
        role: "manager",
        status: Math.random() < 0.5 ? "actif" : "inactif",
        profileImg: `https://picsum.photos/200?random=${Math.random() * 100}`,
    },
    {
        fullName: "Aïcha Baadi",
        role: "technicien",
        status: Math.random() < 0.5 ? "actif" : "inactif",
        profileImg: `https://picsum.photos/200?random=${Math.random() * 100}`,
    },
    {
        fullName: "Driss Lachgar",
        role: "technicien",
        status: Math.random() < 0.5 ? "actif" : "inactif",
        profileImg: `https://picsum.photos/200?random=${Math.random() * 100}`,
    },
    {
        fullName: "Jack Bastard",
        role: "technicien",
        status: Math.random() < 0.5 ? "actif" : "inactif",
        profileImg: `https://picsum.photos/200?random=${Math.random() * 100}`,
    },
];