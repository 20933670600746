import { createContext, useContext, useState, useEffect } from 'react';

const tableContext = createContext();

export const TableContextProvider = ({
  children,
  tableConfiguration,
  data,
  countPerPage = 8,
  emptyState,
  selectable,
  actions = [],
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [tableData, setTableData] = useState(data);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filtredData, setFiltredData] = useState(tableData);
  const [paginatedList, setPaginatedList] = useState(
    tableData.slice(0, countPerPage)
  );
  const goToPage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setPaginatedList(filtredData.slice(from, to));
  };
  const handleSelectAll = () => {
    //TODO: handle select all (only filtred data) |  thinking of a better logic
    if (!selectedItems.length) {
      setSelectedItems(tableData);
    } else {
      setSelectedItems([]);
    }
  };

  const selectItem = (targetItem) => {
    if (selectedItems.find((item) => item.id === targetItem.id)) {
      setSelectedItems(
        selectedItems.filter((item) => item.id !== targetItem.id)
      );
    } else {
      setSelectedItems([...selectedItems, targetItem]);
    }
  };

  useEffect(() => {
    if (!!searchTerm) {
      const filtred = tableData.filter((row) =>
        Object.values(row).find((value) =>
          (value + '').toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFiltredData(filtred);
    } else {
      setFiltredData(tableData);
    }
  }, [searchTerm, tableData]);

  useEffect(() => {
    goToPage(1);
  }, [filtredData]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  return (
    <tableContext.Provider
      value={{
        tableConfiguration,
        selectedItems,
        setSelectedItems,
        handleSelectAll,
        selectItem,
        tableData,
        setTableData,
        currentPage,
        setCurrentPage,
        searchTerm,
        setSearchTerm,
        filtredData,
        setFiltredData,
        paginatedList,
        setPaginatedList,
        goToPage,
        countPerPage,
        emptyState,
        selectable,
        selectedItem,
        setSelectedItem,
        actions,
      }}
    >
      {children}
    </tableContext.Provider>
  );
};

export const useTableContext = () => {
  return useContext(tableContext);
};
