import React from "react";
import { Collapse } from "antd";
import Icon from "../Icon";
const EquipmentAcc = ({ equiptment, editEquipment = () => { }, deleteEquipment = () => { } }) => {
   return (
      <Collapse
         collapsible="header"
         defaultActiveKey={[]}
         expandIconPosition={"end"}
         accordion
         className="collapsy"
         items={refactorLocation(equiptment, editEquipment, deleteEquipment)}
      />
   );
};

export default EquipmentAcc;

const refactorLocation = (equiptment, editEquipment, deleteEquipment) => {
   return equiptment?.map((equiptment) => {
      const key = equiptment._id;
      const label = (
         <div
            className={`flex transition ease-in cursor-pointer relative w-full items-center`}
         >
            <div className="flex flex-col">
               <div className="flex items-center gap-4">
                  {
                     equiptment?.image &&
                     <img className="w-12 rounded-md" src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${equiptment.image}`} alt="image" />
                  }
                  <h3 className="text-[16px] font-bold">{equiptment?.name || "-"}</h3>
               </div>
               {/* <div className="mt-2 flex items-center gap-2">
                  <div onClick={() => editEquipment(equiptment)}>
                     <Icon name={"Pencil"} size={18} color={"gray"} />
                  </div>
                  <div onClick={() => deleteEquipment(equiptment)}>
                     <Icon name={"Trash2"} size={18} color={"red"} />
                  </div>
               </div> */}
            </div>
         </div>
      );
      const children = <AdditionalDetail
         equiptment={equiptment}
      />;
      return {
         key,
         label,
         children,
      };
   });
};

const AdditionalDetail = ({ equiptment }) => {
   return (
      <div className='flex flex-col w-full gap-5'>
         <div className='flex flex-col gap-5'>
            <div className='py-4 w-full flex gap-4'>
               <div className='px-1 flex flex-col gap-4'>

                  <div className='flex items-center gap-4'>
                     <Icon name={"BookUser"} color="gray" />
                     <div className=''>
                        <h4 className='text-slate-700 font-bold text-[14px] '>Description</h4>
                        <p className='text-gray-500 text-[12px]'>{equiptment?.description || "-"}</p>
                     </div>
                  </div>

                  <div className='flex items-center gap-4'>
                     <Icon name={"Link"} color="gray" />
                     <div className=''>
                        <h4 className='text-slate-700 font-bold text-[14px] '>Category</h4>
                        {
                           equiptment?.category?.map((cat, i) => (
                              <span key={i} className='text-gray-500 text-[12px]'>
                                 {cat}
                                 {i !== equiptment.category.length - 1 && ', '}
                              </span>
                           ))
                        }
                     </div>
                  </div>


                  <div className='flex items-center gap-4'>
                     <Icon name={"Plane"} color="gray" />
                     <div className=''>
                        <h4 className='text-slate-700 font-bold text-[14px] '>Place</h4>
                        <p className='text-gray-500 text-[12px]'>{equiptment?.place || "-"}</p>
                     </div>
                  </div>

                  <div className='flex items-center gap-4'>
                     <Icon name={"School"} color="gray" />
                     <div className=''>
                        <h4 className='text-slate-700 font-bold text-[14px] '>Profession</h4>
                        <p className='text-gray-500 text-[12px]'>{equiptment?.profession || "-"}</p>
                     </div>
                  </div>

                  <div className='flex items-center gap-4'>
                     <Icon name={"Hexagon"} color="gray" />
                     <div className=''>
                        <h4 className='text-slate-700 font-bold text-[14px] '>Brand</h4>
                        <p className='text-gray-500 text-[12px]'>{equiptment?.brand || "-"}</p>
                     </div>
                  </div>

                  <div className='flex items-center gap-4'>
                     <Icon name={"FileBox"} color="gray" />
                     <div className=''>
                        <h4 className='text-slate-700 font-bold text-[14px] '>ModelId</h4>
                        <p className='text-gray-500 text-[12px]'>{equiptment?.model || "-"}</p>
                     </div>
                  </div>

                  <div className='flex items-center gap-4'>
                     <Icon name={"FileDigit"} color="gray" />
                     <div className=''>
                        <h4 className='text-slate-700 font-bold text-[14px] '>Serial number</h4>
                        <p className='text-gray-500 text-[12px]'>{equiptment?.serialNumber || "-"}</p>
                     </div>
                  </div>

                  <div className='flex items-center gap-4'>
                     <Icon name={"Calendar"} color="gray" />
                     <div className=''>
                        <h4 className='text-slate-700 font-bold text-[14px] '>Year</h4>
                        <p className='text-gray-500 text-[12px]'>{equiptment?.year || "-"}</p>
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </div>
   )
}
