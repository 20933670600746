// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab {
    cursor: pointer;
    padding: 0.75rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.tab.active {
    background-color: #302f38;
    color: white;
}

/* extra field animation */

.container {
    position: relative;
    overflow: hidden;
    transition: max-height 0.5s ease; /* Change the transition to max-height */
    max-height: 0; /* Initially hide the container */
  }
  
  .slide-in {
    animation: slideIn 0.5s forwards;
  }
  
  .slide-out {
    animation: slideOut 0.5s forwards;
  }
  
  @keyframes slideIn {
    0% {
      height: 0px;
    }
    100% {
        height: max-content; 
    }
  }
  
  @keyframes slideOut {
    0% {
        height: max-content;
      }
      100% {
          height: 0px; 
      }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/drawer/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,uDAAuD;AAC3D;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA,0BAA0B;;AAE1B;IACI,kBAAkB;IAClB,gBAAgB;IAChB,gCAAgC,EAAE,wCAAwC;IAC1E,aAAa,EAAE,iCAAiC;EAClD;;EAEA;IACE,gCAAgC;EAClC;;EAEA;IACE,iCAAiC;EACnC;;EAEA;IACE;MACE,WAAW;IACb;IACA;QACI,mBAAmB;IACvB;EACF;;EAEA;IACE;QACI,mBAAmB;MACrB;MACA;UACI,WAAW;MACf;EACJ","sourcesContent":[".tab {\n    cursor: pointer;\n    padding: 0.75rem 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    transition: background-color 0.3s ease, color 0.3s ease;\n}\n\n.tab.active {\n    background-color: #302f38;\n    color: white;\n}\n\n/* extra field animation */\n\n.container {\n    position: relative;\n    overflow: hidden;\n    transition: max-height 0.5s ease; /* Change the transition to max-height */\n    max-height: 0; /* Initially hide the container */\n  }\n  \n  .slide-in {\n    animation: slideIn 0.5s forwards;\n  }\n  \n  .slide-out {\n    animation: slideOut 0.5s forwards;\n  }\n  \n  @keyframes slideIn {\n    0% {\n      height: 0px;\n    }\n    100% {\n        height: max-content; \n    }\n  }\n  \n  @keyframes slideOut {\n    0% {\n        height: max-content;\n      }\n      100% {\n          height: 0px; \n      }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
