import React from 'react'
import Icon from '../../Icon'
import { useSelector } from 'react-redux';

const ClientInterventionDetail = () => {
    const { selectedIntervention } = useSelector(state => state.interventionReducer);

    function convertDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
        
        return `${day}/${month}/${year}`;
      }

      if (!selectedIntervention) return <></>

  return (
    <div className="bg-white rounded-lg p-4">
         <div className="px-1 flex flex-col gap-4">
            <div className="flex items-center gap-4">
               <div><Icon name={"ShieldAlert"} color="gray" /></div>
               <div>
                  <h4 class="text-slate-700 font-bold text-[14px] ">
                     {selectedIntervention?.criticality}
                  </h4>
               </div>
            </div>
            <div className="flex items-center gap-4">
               <div><Icon name={"BookType"} color="gray" /></div>
               <div>
                  <h4 class="text-slate-700 font-bold text-[14px] ">
                     {selectedIntervention?.title}
                  </h4>
               </div>
            </div>
            <div className="flex items-center gap-4">
               <div><Icon name={"Quote"} color="gray" /></div>
               <div>
                  <h4 class="text-slate-700 font-bold text-[14px] ">
                     {selectedIntervention?.description}
                  </h4>
               </div>
            </div>
            <div className="flex items-center gap-4">
               <div><Icon name={"MapPin"} color="gray" /></div>
               <div>
                  <h4 class="text-slate-700 font-bold text-[14px] ">
                     {selectedIntervention?.place}
                  </h4>
               </div>
            </div>
            <div className="flex flex-col gap-4">
               {
                  selectedIntervention?.intervenant &&
                  <div className="flex gap-4">
                     <div><Icon name={"User"} color="gray" /></div>

                     <div>
                        <h4 class="text-slate-700 font-bold text-[14px] ">
                           {`${selectedIntervention?.intervenant?.firstName} ${selectedIntervention?.intervenant?.lastName}`}
                        </h4>
                     </div>

                  </div>
               }
               {
                  selectedIntervention?.startDate &&
                  <div className="flex items-center gap-4">
                  <div><Icon name={"Calendar"} color="gray" /></div>
                  <div>
                     <h4 class="text-slate-700 font-bold text-[14px] ">
                        Start:- {convertDate(selectedIntervention?.startDate)}
                     </h4>
                  </div>
               </div>
               }

               {
                  selectedIntervention?.endDate &&
                  <div className="flex items-center gap-4">
                  <div><Icon name={"Calendar"} color="gray" /></div>
                  <div>
                     <h4 class="text-slate-700 font-bold text-[14px] ">
                        End:- {convertDate(selectedIntervention?.endDate)}
                     </h4>
                  </div>
               </div>
               }               
            </div>
         </div>
      </div>
  )
}

export default ClientInterventionDetail