import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedLocation: "",
};

const locationSlice = createSlice({
  name: "locationSlice",
  initialState,
  reducers: {
    setLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
  },
});

export default locationSlice.reducer;

export const { setLocation } = locationSlice.actions;
