import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   interventions: null,
   selectedIntervention: null
}

const interventionSlice = createSlice({
   name: 'interventionSlice',
   initialState,
   reducers: {
      setInterventions: (state, action) => {
         state.interventions = action.payload;
      },
      setSelectedIntervention: (state, action) => {
         state.selectedIntervention = action.payload;
      },
      updateIntervention: (state, action) => {
         const interventionId = action.payload._id;
         const interventions = state.interventions || [];
         const index = interventions.findIndex((intervention) => intervention._id === interventionId);

         if (index !== -1) {
            const updatedInterventions = [
               ...interventions.slice(0, index),
               action.payload,
               ...interventions.slice(index + 1)
            ];

            state.interventions = updatedInterventions;
         }
      }
   }
})

export default interventionSlice.reducer;

export const {
   setInterventions,
   setSelectedIntervention,
   updateIntervention,
} = interventionSlice.actions;