import React, { useState } from "react";
import { useAppContext } from "../context/Context";
import { TableContextProvider, useTableContext } from "../context/TableContext";
import { storeProductsConfiguration as tableConfiguration } from "../constant/tablesParam";
import TableContainer from "../components/table/TableContainer";
import api from "../utils/api";
import Input from "./Input";
import Button from "./Button";
import Icon from "./Icon";
import { validShopifyDomain } from "../utils/helper";

const StoreModal = ({ showModal }) => {
  const { setShowModal } = useAppContext();
  const [products, setProducts] = useState(null);
  const [storeUrl, setStoreUrl] = useState("");

  const toggeleModal = () => {
    setShowModal(false);
    setProducts(null);
  };

  return (
    <>
      {!products ? (
        <AddStore
          {...{ setShowModal, showModal, setProducts, storeUrl, setStoreUrl }}
        />
      ) : (
        <TableContextProvider
          {...{
            tableConfiguration,
            data: products,
            countPerPage: 5,
            selectable: true,
          }}
        >
          <Products {...{ showModal, toggeleModal, storeUrl }} />
        </TableContextProvider>
      )}
    </>
  );
};

const AddStore = ({
  setShowModal,
  showModal,
  setProducts,
  storeUrl,
  setStoreUrl,
}) => {
  const [secretKey, setSecretKey] = useState("");
  const [loading, setLoading] = useState(false);

  const handleToggel = () => {
    setShowModal(false);
    setStoreUrl("");
    setSecretKey("");
    setLoading(false);
  };

  const handleAddStore = async () => {
    setLoading(true);
    if (!isValid()) return;
    try {
      const { data: newStore } = await api.post(`/add-storekey`, {
        storeUrl: storeUrl,
        accessToken: secretKey,
      });

      try {
        const response = await api.post(`/getProductsByStoreId`, {
          storeId: newStore.data._id,
          all: false,
        });
        if (response.status == 200) {
          setLoading(false);
        }
        const data = response.data.data;
        setProducts(data);
      } catch (e) {
        console.log("ERROR!");
        console.log(e);
      }
    } catch (e) {
      console.log("ERROR!");
      console.log(e);
    }
  };

  const isValid = () => {
    if (!secretKey) return false;

    if (!validShopifyDomain(storeUrl)) return false;

    return true;
  };

  return (
    <div
      className={` ${
        showModal ? "fixed" : "hidden"
      } inset-0 bg-black bg-opacity-50 z-10 flex duration-500 justify-center items-center bg-white`}
    >
      <div className=" bg-white w-[500px] rounded-lg">
        <div className=" flex flex-col gap-4">
          <div>
            <div className="flex px-5 py-3 rounded justify-between items-center  bg-white">
              <h1 className="text-[#090F4E] text-xl font-bold">
                Add new store
              </h1>
              <button onClick={handleToggel}>
                <Icon name="X" />
              </button>
            </div>
            <hr className="bg-[#CAD1DB]" />
          </div>
          <div className="flex px-5 py-3 flex-col gap-6">
            <Input
              value={storeUrl}
              onChange={(e) => setStoreUrl(e.target.value)}
              placeholder="example.myshopify.com"
              label="Shopify store URL"
            />
            <Input
              value={secretKey}
              onChange={(e) => setSecretKey(e.target.value)}
              placeholder="Write shopify api secret"
              label=" Shopify API secret"
              // error="Already exist"
            />
          </div>

          <div className="w-full flex justify-end px-5 py-3 border border-[#CAD1DB] bg-white rounded-b">
            <Button
              value={"Add store"}
              onClick={handleAddStore}
              disabled={!isValid()}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Products = ({ showModal, toggeleModal, storeUrl }) => {
  const { setShowModal, setProductData } = useAppContext();
  const { selectedItems } = useTableContext();
  const [isLoading, setIsLoading] = useState(false);

  // Fetch products when modal is closed
  const fetchProducts = async () => {
    try {
      const { data } = await api.post("/getAllProducts");
      setProductData(data.products);
    } catch (error) {
      // TODO: handle error
      console.log(error, "error dataaa");
    }
  };

  const handleProductsImport = async () => {
    try {
      setIsLoading(true);
      const response = await api.post(`/addProducts`, {
        products: selectedItems,
        storeName: storeUrl,
      });
      setIsLoading(false);
      setShowModal(false);
      fetchProducts();
    } catch (e) {
      setIsLoading(false);
      console.log("Error!!");
    }
  };

  return (
    <div
      className={` ${
        showModal ? "fixed" : "hidden"
      } inset-0 bg-black bg-opacity-50 z-10 flex duration-500 justify-center items-center `}
    >
      <div className=" bg-white w-[1000px] rounded-lg">
        <div className=" flex flex-col gap-4">
          <div>
            <div className="flex px-5 py-3 justify-between items-center rounded bg-white">
              <h1 className="text-[#090F4E] text-xl font-bold">
                Import products
              </h1>
              <button
                onClick={toggeleModal}
                className="hover:rotate-180 origin-center transition"
              >
                <Icon name="X" />
              </button>
            </div>
            <hr className="bg-[#CAD1DB]" />
          </div>

          <div className="flex px-5 py-3 flex-col gap-6">
            <TableContainer />
          </div>
          <div className="w-full flex justify-end px-5 py-3 border border-[#CAD1DB] bg-white rounded-b">
            <Button
              value={"Import"}
              onClick={() => handleProductsImport()}
              disabled={selectedItems.length > 0 ? false : true}
              icon={{
                name: "DownloadCloud",
              }}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default StoreModal;
