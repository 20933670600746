import React, { useState } from "react";
import { options } from "../constant/constant";
import Icon from "../components/Icon";
import Page from "./Page";
import CustomDrawer from "../components/CustomDrawer";
import InterventionsDrawer from "../components/drawer/InterventionsDrawer";
import DrawerHeader from "../components/drawer/DrawerHeader";
import FooterEle from "../components/drawer/FooterEle";
import UserlistNavigator from "../components/navigators/userlistNavigator";
import UsersList from "../components/lists/usersList";

const UserList = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");

  const [formData, setFormData] = useState({
    places: "",
    tie: "",
    description: "",
    equipements: "",
    professions: "",
    managers: "",
    users: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAntDValues = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Page>
      <div className="flex w-full h-[calc(100vh-25px)] gap-5">
        <div className="w-full md:w-[500px] lg:w-[400px] xl:w-[500px] rounded-lg  flex flex-col gap-4 p-0">
          <div className="flex items-center justify-between h-16">
            <h1 className="text-[#090F4E] text-2xl font-bold leading-10">
              Users
            </h1>
            <button
              onClick={() => setDrawerOpen(true)}
              className="p-2 bg-orange hover:shadow-lg h-10  transition ease-in text-white rounded flex items-center justify-center"
            >
              <Icon name="UserRoundPlus" />
            </button>
          </div>
          <UsersList />
        </div>
        <UserlistNavigator />
      </div>
      <CustomDrawer
        open={drawerOpen}
        width={600}
        onClose={() => setDrawerOpen(false)}
        footerEle={
          <FooterEle
            icon={"SquarePen"}
            label={"Pièce(s) jointe(s)"}
            paraIcon={"Link"}
            badge
          />
        }
        title={
          <DrawerHeader
            title={"Création d'une Interventions"}
            icon={"UsersRound"}
          />
        }
      >
        <InterventionsDrawer
          handleChange={handleChange}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          formData={formData}
          handleAntDValues={handleAntDValues}
          options={options}
        />
      </CustomDrawer>
    </Page>
  );
};

export default UserList;
