import React from "react";
import { useTranslation } from "react-i18next";

const HeroImg = () => {
  const { t } = useTranslation();
  return (       <div
    className=" flex justify-center items-center h-screen w-[60%] linearBG"
    // style={{ background: "linear-gradient(to right bottom, #051937, #062344, #052e50, #03395d, #00456a)" }} //#004854 #3E4778
  >
    <div className="flex h-screen flex-col   ">
      <div className=" flex flex-1 justify-center items-center gap-4 flex-col" >
        <div className="h-32">
          <img src="/images/plan-logo-vertical.png" alt="hero" className="rounded h-32" />
        </div>
        <div className="w-[70%] text-center color-white relative">
          <div className="glassy rounded p-4 m-4  ">
            <img src="/images/preview-1.png" alt="hero" className="rounded" />
          </div>
          {/* <img src="/images/preview-campaigns.png" alt="hero" className="rounded shadow absolute bottom-20" /> */}
        </div>
        <div className="w-[60%] text-center text-white gap-2 flex flex-col">
          <div className="font-bold text-[24px] " > {t("slogan")} </div>
          <div >{t("sloganSubtitle")}</div>
        </div>
      </div>

    </div>
  </div>
  );
};

export default HeroImg;
