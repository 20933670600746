import React from "react";
import TableContainer from "../table/TableContainer";
import { documentConfiguration } from "../../constant/tablesParam";
import { TableContextProvider } from "../../context/TableContext";
import Page from "../../pages/Page";
import { documentArray } from "../../constant/constant";

const LocalisationTable = () => {
  const actions = [
    {
      name: "Create new",
      action: () => console.log("add new store"),
      type: "primary",
      icon: {
        name: "Rocket",
        color: "white",
      },
    },
  ];

  return (
    <div>
      <TableContextProvider
        {...{ tableConfiguration: documentConfiguration, data: documentArray }}
      >
        <TableContainer title={"Campaign list"} />
      </TableContextProvider>
    </div>
  );
};

export default LocalisationTable;
