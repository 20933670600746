import React, { useState } from "react";
import { List, Button, Divider, Avatar, Badge } from "antd";
import { useAppContext } from "../context/Context";

const UserListCard = ({ data }) => {
  const { setSelectedUserId } = useAppContext();
  const handleItemClick = (userId) => {
    setSelectedUserId(userId);
  };
  return (
    <>
      <List
        itemLayout="horizontal"
        className="cursor-pointer"
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            onClick={() => handleItemClick(item.id)}
            className="user-list-item hover:bg-gray-100 transition"
          >
            <List.Item.Meta
              avatar={
                <Badge
                  status={item.status == "active" ? "success" : "warning"}
                  dot
                >
                  <Avatar src={item.avatar} />
                </Badge>
              }
              title={item.clientName}
            />

            <div
              type="button"
              class="py-1 px-2 my-2 text-[12px] font-medium  bg-gray-200 rounded-2xl border text-dark border-gray-200 "
            >
              {item.role}
            </div>
            {/* <button
              type="button"
              className="py-2.5 px-5 me-2 mb-2 text-sm font-small text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 "
            >
              {item.role}
            </button> */}
          </List.Item>
        )}
      />
    </>
  );
};

export default UserListCard;
