import React, { useEffect, useState } from "react";
import SelectButton from "../components/SelectButton";
import { buttonClients, clientsOption } from "../constant/constant";
import Page from "./Page";
import InputComp from "../components/InputComp";
import UserCard from "../components/UserCard";
import CustomDrawer from "../components/CustomDrawer";
import ClientDrawer from "../components/drawer/ClientDrawer";
import Icon from "../components/Icon";
import DrawerHeader from "../components/drawer/DrawerHeader";
import FooterEle from "../components/drawer/FooterEle";
import api from "../utils/api";
import { useAppContext } from "../context/Context";
import { useTranslation } from "react-i18next";
import ClientNavigator from "../components/navigators/ClientNavigator";
import { useDispatch, useSelector } from "react-redux";
import { addNewClient, setClientList } from "../redux/slices/clientSlice";
import useOrgDetail from "../hooks/useOrgDetail";
import useClients from "../hooks/useClients";
import { addLocation } from "../redux/slices/appConfigSlice";

const Clients = () => {

  useClients();

  const { t } = useTranslation();
  // const { clientList, setClientList } = useAppContext();
  const dispatch = useDispatch();
  const { clientList } = useSelector((state) => state.clientReducer);
  const [seletectedBtn, setSeletectedBtn] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [inputData, setInputData] = useState("");
  const [selectedTab, setSelectedTab] = useState("PROFESSIONAL");
  const [filteredClients, setFilteredClients] = useState([]);
  const [formData, setFormData] = useState({
    client: "",
    firstName: "",
    name: "",
    address: "",
    email: "",
    cellNumber: "",
    landlineNumber: "",
  });

  const [file, setFile] = useState(null);
  useOrgDetail();
  const { userDetail } = useSelector((state) => state.appConfigReducer);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleLocationChange = (e) => {
    setFormData({
      ...formData,
      address: e,
    });
  };
  const handelAntDValues = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    const {
      address,
      cellNumber,
      client,
      email,
      firstName,
      landlineNumber,
      name,
    } = formData;

    if (
      !address ||
      !cellNumber ||
      !client ||
      !email ||
      !firstName ||
      !name ||
      !landlineNumber
    )
      return;

    if (!file) return;

    const clientFormData = new FormData();

    const data = {
      firstName,
      lastName: name,
      email,
      phone: cellNumber,
      client,
      landLine: landlineNumber,
      interventionAdd: JSON.stringify(address),
      type: selectedTab
    };

    for (const key in data) {
      clientFormData.append(key, data[key]);
    }

    clientFormData.append("image", file);

    try {
      const res = await api.post("/client/createClient", clientFormData);
      dispatch(addNewClient(res.data.client));
      dispatch(addLocation(res.data.location));
      // setClientList((prev) => (prev = [...prev, res.data]));
      setFile(null);
      setDrawerOpen(false);
    } catch (error) {
      console.log("Error occured while creating new client", error);
    }
  };

  const filterClients = (searchValue) => {
    let filteredClients = [...clientList];
    if (!searchValue || !searchValue?.trim()) {
      setFilteredClients(filteredClients);
      return;
    }

    searchValue = searchValue?.toLowerCase();

    filteredClients = filteredClients.filter((client) => {
      if (
        client?.firstName?.toLowerCase()?.includes(searchValue) ||
        client?.lastName?.toLowerCase()?.includes(searchValue)
      ) {
        return client;
      }
    });

    setFilteredClients(filteredClients);
  };

  return (
    <>
      <Page>
        <div className="flex w-full h-[calc(100vh-25px)] gap-5">
          <div className="w-full md:w-[500px] lg:w-[400px] xl:w-[500px] rounded-lg  flex flex-col gap-4 p-0">
            <div className="flex items-center justify-between h-16 ">
              <h1 className="text-[#090F4E] text-2xl font-bold leading-10">
                {t("clients")}
              </h1>
              <button
                onClick={() => setDrawerOpen(true)}
                className="p-2 bg-orange hover:shadow-lg h-10  transition ease-in text-white rounded flex items-center justify-center"
              >
                <Icon name="UserRoundPlus" />
              </button>
            </div>
            <div className="h-full rounded-lg shadow Clientscrollbar bg-white flex flex-col gap-4 w-full overflow-auto">
              <div className="flex flex-col gap-4 w-full p-4">
                <InputComp
                  setInputData={setInputData}
                  inputData={inputData}
                  filterClients={filterClients}
                />
                <SelectButton
                  setSelecteBtn={setSeletectedBtn}
                  selecteBtn={seletectedBtn}
                  data={buttonClients}
                />
              </div>
              <UserCard clientList={clientList} />
            </div>
          </div>
          <ClientNavigator interventions={userDetail?.intervention || []} />
          {/* <div className="w-[500px] flex-1">
            <OptionSwiper options={clientsOption} />
          </div> */}
        </div>

        <CustomDrawer
          open={drawerOpen}
          width={600}
          onClose={() => setDrawerOpen(false)}
          footerEle={
            <FooterEle
              label={t("client.drawer.icon")}
              icon={"Image"}
              handleSubmit={handleSubmit}
              file={file}
              setFile={setFile}
            />
          }
          title={
            <DrawerHeader title={t("client.drawer.title")} icon={"Building2"} />
          }
        >
          <ClientDrawer
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            handleLocationChange={handleLocationChange}
            handelAntDValues={handelAntDValues}
            handleSubmit={handleSubmit}
          />
        </CustomDrawer>
      </Page>
    </>
  );
};

export default Clients;
