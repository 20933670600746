import { useState } from "react";
import { interventionOptions } from "../../../constant/constant";
import OptionSwiper from "../../OptionSwiper";
import ClientInterventionDetail from "./ClientInterventionDetail";


const RenderTabs = ({ activeTab }) => {
   switch (activeTab) {
      case "details":
         return (
            <ClientInterventionDetail />
         );
   }
};

const ClientInterventionNavigator = () => {
   const [activeTab, setActiveTab] = useState("details");
   return (
      <div className="w-[500px] flex flex-col flex-1 gap-2">
         <OptionSwiper
            options={interventionOptions}
            {...{ activeTab, setActiveTab }}
         />
         <div className="overflow-auto">
            <RenderTabs activeTab={activeTab} />
         </div>
      </div>
   );
};

export default ClientInterventionNavigator
