import React, { useEffect, useState } from "react";
import InputComp from "../InputComp";
import SelectButton from "../SelectButton";
import { buttonInterventions } from "../../constant/constant";
import IntervantionsCollapse from "../IntervantionsCard";
import { useSelector } from "react-redux";

const IntervantionsDetails = () => {
  const { activeClient } = useSelector((state) => state.clientReducer);
  const [selectedBtn, setSelectedBtn] = useState(0);
  const [inputData, setInputData] = useState("");
  const [checkedButtons, setCheckedButtons] = useState([]);
  const [intervantions, setIntervantion] = useState([]);

  useEffect(() => {
    if (activeClient) {
      setIntervantion(activeClient?.intervention || []);
    }
  }, [activeClient]);

  if (!activeClient) return <></>

  return (
    <div className="h-full rounded-lg shadow Clientscrollbar bg-white flex flex-col gap-4 w-full overflow-auto">
      <div className="flex flex-col gap-4 w-full p-4">
        <InputComp setInputData={setInputData} inputData={inputData} />
        <SelectButton
          setSelecteBtn={setSelectedBtn}
          selecteBtn={selectedBtn}
          data={buttonInterventions}
          checkedButtons={checkedButtons}
          setCheckedButtons={setCheckedButtons}
        />
      </div>
      <div className="listings">
        <IntervantionsCollapse intervantionList={intervantions} />
      </div>
    </div>
  );
};

export default IntervantionsDetails;
