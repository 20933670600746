import React from 'react'
import { Outlet } from 'react-router';
import useClientDetail from '../hooks/useClientDetail'

const MainClientLayout = () => {

    useClientDetail();

    return <Outlet />
}

export default MainClientLayout