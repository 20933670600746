import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Staff from "./pages/Staff";
import Invitation from "./pages/Invitation";
import Invitations from "./pages/Invitations";
import "./App.css";
import axios from "axios";
import PrivateRoute from "./pages/PrivateRoute";
import IfLoggedIn from "./pages/IfLoggedIn";
import Clients from "./pages/Clients";
import Intervenants from "./pages/Intervenants";
import Localisation from "./pages/Localisation";
import Equipment from "./pages/Equipment";
import Interventions from "./pages/Interventions";
import Users from "./pages/Users";
import Parameters from "./pages/Parameters";
import IntervenantCreatePassword from "./pages/IntervenantCreatePassword";
import MainOrgLayout from "./layouts/MainOrgLayout";
import RolebasedLayout from "./layouts/RolebasedLayout";
import MainIntervenantLayout from "./layouts/MainIntervenantLayout";
import AssignedInterventions from "./components/intervenants/AssignedInterventions";
import MainClientLayout from "./layouts/MainClientLayout";
import ClientInterventions from "./components/clients/ClientInterventions";
axios.defaults.baseURL = "http://localhost:8001/api";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Clients />} />

          <Route element={<MainOrgLayout />}>
            <Route element={<RolebasedLayout roles={["org"]} />}>
              <Route path="clients" element={<Clients />} />
              <Route path="intervenants" element={<Intervenants />} />
              <Route path="staff" element={<Staff />} />
              <Route path="equipment" element={<Equipment />} />
              <Route path="localisation" element={<Localisation />} />
              <Route path="interventions" element={<Interventions />} />
              <Route path="invitations" element={<Invitations />} />
              <Route path="/users" element={<Users />} />
              <Route path="/parameters" element={<Parameters />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route element={<MainIntervenantLayout />}>
            <Route element={<RolebasedLayout roles={["intervenant"]} />}>
              <Route path="/intervenant/interventions" element={<AssignedInterventions />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route element={<MainClientLayout/>}>
            <Route element={<RolebasedLayout roles={["client"]} />}>
              <Route path="/client/interventions" element={<ClientInterventions />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>

        </Route>

        <Route element={<IfLoggedIn />}>
          {/* <Route path="/signup" element={<SignUp />} /> */}
          <Route path="/invitation/:id" element={<Invitation />} />
          {/* <Route path="/signup/:id" element={<SignUp />} /> */}
          <Route path="/signin" element={<SignIn />} />
          <Route path="/intervenant/create-password/:id" element={<IntervenantCreatePassword />} />
        </Route>

        <Route path="*" element={<NotFound />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
