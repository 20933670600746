import React from "react";
import Icon from "./Icon";
import Button from "./Button";

// type Action = {
//   value: string,
//   onClick: any,
//   disabled: boolean
// }
// type IProps = {
//   children: any
//   showModal: boolean
//   setShowModal: (state: boolean) => void
//   actions: Action[]
//   title: string
// }

const Modal = ({
  children,
  showModal,
  setShowModal,
  actions,
  title,
  onClose,
}) => {
  return (
    <div
      className={` ${
        showModal ? "fixed" : "hidden"
      } inset-0 bg-black bg-opacity-50 z-10 flex duration-500 justify-center items-center `}
    >
      <div className=" bg-white rounded-lg">
        <div className=" flex flex-col gap-4">
          <div>
            <div className="flex px-5 py-3 rounded justify-between items-center  bg-dark">
              <h1 className="text-white text-xl font-bold">{title}</h1>
              <button
              className="hover:rotate-180 origin-center transition"
                onClick={() => {
                  onClose();
                  setShowModal(false);
                }}
              >
                <Icon name="X" className={"text-white"}/>
              </button>
            </div>
            <hr className="bg-[#CAD1DB]" />
          </div>
          <div className="flex px-5 py-3 flex-col gap-6">
            {children}
          </div>
          {actions?.length ? (
            <div className="w-full flex justify-end px-5 py-3  bg-white rounded-b">
              {actions.map((action) => {
                return <Button {...action} />;
              })}
            </div>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

export default Modal;
