import React, { useState, useEffect } from "react";
import Icon from "./Icon";
import Sidebar, { SidebarItem } from "./Sidebar";
import { useAppContext } from "../context/Context";
import { tabsLabel } from '../constant/constant'
import { getUser } from "../utils/localStorage";

const linkArr = [
  {
    id: 1,
    label: "Home",
    icon: <Icon name="LayoutDashboard" />,
    path: "/",
    roles: ["org", "intervenants"]
  },
  {
    id: 6,
    ...tabsLabel.clients,
    path: "/clients",
    roles: ["org"]
  },
  {
    id: 7,
    ...tabsLabel.intervenants,
    path: "/intervenants",
    roles: ["org"]
  },
  {
    id: 8,
    ...tabsLabel.locations,
    path: "/localisation",
    roles: ["org"]
  },
  {
    id: 9,
    ...tabsLabel.equipements,
    path: "/equipment",
    roles: ["org"]
  },
  {
    id: 10,
    ...tabsLabel.interventions,
    path: "/interventions",
    roles: ["org"]
  },
  {
    id: 11,
    ...tabsLabel.interventions,
    path: "/intervenant/interventions",
    roles: ["intervenant"]
  },
  {
    id: 12,
    ...tabsLabel.interventions,
    path: "/client/interventions",
    roles: ["client"]
  },
  {
    id: 13,
    label: "UserList",
    icon: <Icon name="Users" />,
    path: "/users",
    roles: ["org"]
  },
  {
    id: 14,
    label: "Parameters",
    icon: <Icon name="Settings2" />,
    path: "/parameters",
    roles: ["org"]
  },
];

const BigSidebar = ({ expanded, setExpanded }) => {
  const [tabName, setTabName] = useState("Home");
  const { userData } = useAppContext();
  const [sidebarTabs, setSidebarTabs] = useState(linkArr);


  const filterSiderTabs = () => {
    const user = getUser();

    if (!user) return linkArr;

    const role = user?.role || "org";
    const result = linkArr.filter((tab) => tab.roles.includes(role));
    setSidebarTabs(result);
  }

  useEffect(() => {
    const path = window.location.pathname;
    const activeTab = linkArr.find((link) => path === link.path);
    setTabName(activeTab?.label || "Home");
    filterSiderTabs();
  }, []);

  return (
    <main className="z-[100] h-screen">
      <Sidebar expanded={expanded} setExpanded={setExpanded}>
        {sidebarTabs.map((link) => {
          return (
            <SidebarItem
              role={userData.role}
              icon={link.icon}
              text={link.label}
              path={link.path}
              key={link.id}
              tabName={tabName}
              setTabName={setTabName}
            />
          );
        })}
      </Sidebar>
    </main>
  );
};

export default BigSidebar;
