import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { tabsInterventions } from '../../../constant/constant';
import InputField from '../../InputField';
import Icon from '../../Icon';

const ClientInterventionsDrawer = ({ formData, handleChange, handleAntDValues, locations, intervenants, handleFormSubmit }) => {
    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            intervenant: formData.intervenant || '',
            place: formData.place || null,
            description: formData.description || '',
        },
    });

    const { t } = useTranslation();

    const onSubmit = (data) => {
        handleFormSubmit(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-5 h-full'>
            <div className='flex flex-col gap-7'>
                <div className='flex flex-col gap-5'>

                    <Controller
                        name="criticality"
                        control={control}
                        rules={{ required: 'Criticality is required' }}
                        render={({ field }) => (
                            <div>
                                <Select
                                    {...field}
                                    style={{ width: '100%', height: '3rem' }}
                                    placeholder="criticality"
                                    options={tabsInterventions}
                                    onChange={(value) => {
                                        field.onChange(value);
                                        handleAntDValues(value, "criticality");
                                    }}
                                />
                                {errors.place && <span className='text-red-500 px-2 text-[12px]'>{errors.criticality.message}</span>}
                            </div>
                        )}
                    />

                    <Controller
                        name="title"
                        control={control}
                        rules={{ required: 'Title is required' }}
                        render={({ field }) => (
                            <InputField
                                {...field}
                                handleChange={(e) => {
                                    field.onChange(e);
                                    handleChange(e);
                                }}
                                placeholder="Title"
                                error={errors.title}
                            />
                        )}
                    />
                    <Controller
                        name="description"
                        control={control}
                        rules={{ required: 'Description is required' }}
                        render={({ field }) => (
                            <InputField
                                {...field}
                                handleChange={(e) => {
                                    field.onChange(e);
                                    handleChange(e);
                                }}
                                type="textarea"
                                placeholder="Description"
                                error={errors.description}
                            />
                        )}
                    />

                    <Controller
                        name="place"
                        control={control}
                        rules={{ required: 'Place is required' }}
                        render={({ field }) => (
                            <div>
                                <Select
                                    {...field}
                                    style={{ width: '100%', height: '3rem' }}
                                    placeholder="Lieux"
                                    options={locations}
                                    onChange={(value) => {
                                        field.onChange(value);
                                        handleAntDValues(value, "place");
                                    }}
                                />
                                {errors.place && <span className='text-red-500 px-2 text-[12px]'>{errors.place.message}</span>}
                            </div>
                        )}
                    />

                    {/* <Controller
                        name="intervenant"
                        control={control}
                        render={({ field }) => (

                            <Select
                                {...field}
                                style={{
                                    width: '100%',
                                    height: "3rem"
                                }}
                                placeholder="Utilisateurs"
                                options={intervenants}
                                value={formData.intervenant || null}
                                onChange={(value) => {
                                    field.onChange(value);
                                    handleAntDValues(value, "intervenant")
                                }}
                            />
                        )}
                    /> */}
                </div>
            </div>
            <button
                type='submit'
                className='mt-auto w-fit px-3 text-white items-center bg-orange rounded-lg py-2 flex gap-1 self-end'
            >

                <p>{t("createBtn")}</p>
                <Icon name={'Plus'} />

            </button>
        </form>
    );
};

export default ClientInterventionsDrawer;