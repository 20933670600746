import { Navigate } from "react-router";
import SharedLayout from "./dashboard/SharedLayout";
import { getUser } from "../utils/localStorage";

const PrivateRoute = () => {
   const localStorageToken = localStorage.getItem("authToken");
   const user = getUser();

   return (localStorageToken && user) ? <SharedLayout /> : <Navigate to="/signin" />;
};

export default PrivateRoute;