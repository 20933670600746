import React from 'react'
import Icon from '../Icon';
import { useTranslation } from 'react-i18next';

const ClientDetail = ({ client }) => {
    const { t } = useTranslation();
    return (
        <div className='flex flex-col w-full gap-5'>
            <div className='flex flex-col gap-5'>
                <div className='py-4 w-full flex gap-4'>
                    <div className='px-1 flex flex-col gap-4'>

                        <div className='flex items-center gap-4'>
                            <Icon name={"Mail"} color="gray" />
                            <div className=''>
                                <h4 className='text-slate-700 font-bold text-[14px] '>Email</h4>
                                <p className='text-gray-500 text-[12px]'>{client?.email || "-"}</p>
                            </div>
                        </div>

                        <div className='flex items-center gap-4'>
                            <Icon name={"Phone"} color="gray" />
                            <div className=''>
                                <h4 className='text-slate-700 font-bold text-[14px] '>{t("phoneNumber")}</h4>
                                <p className='text-gray-500 text-[12px]'>{client?.phoneNumber?.[0] || "-"}</p>
                            </div>
                        </div>

                        <div className='flex items-center gap-4'>
                            <Icon name={"Link"} color="gray" />
                            <div className=''>
                                <h4 className='text-slate-700 font-bold text-[14px] '>{t("address")}</h4>
                                <p className='text-gray-500 text-[12px]'>{client?.interventionAddress?.label || "-"}</p>
                            </div>
                        </div>
                    </div>
                    {
                        client?.picture && (
                            <img src={client?.picture} className='w-[50%] h-full ' alt="" />
                        )
                    }
                </div>

            </div>
        </div>
    )
}

export default ClientDetail