import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getUser } from "../utils/localStorage";
const IfLoggedIn = () => {
  const authToken = localStorage.getItem("authToken");
  const user = getUser();

  return (authToken && user) ? <Navigate to={"/"} /> : <Outlet />;
};

export default IfLoggedIn;
