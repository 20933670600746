import React from "react";
import { Search } from "lucide-react";
import TableFilter from "./TableFilter";
import TableCamp from "./Table";
import PaginationComp from "../PaginationComp";
import { useTableContext } from "../../context/TableContext";
import EmptyState from "../EmptyState";

const TableContainer = () => {
  const { searchTerm, setSearchTerm, emptyState, tableData } =
    useTableContext();
  return tableData.length ? (
    <>
      <div className="px-4 py-3 flex flex-col gap-4 rounded-lg">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <div className="px-2 h-10 justify-center bg-white border border-[#CAD1DB] rounded-lg flex items-center gap-2 w-80 transition-all focus-within:border-[#0D6EFD]">
              <Search style={{ color: "#63657E", width: "18px" }} />
              <input
                type="text"
                className="w-full text-[#63657E] text[14px] h-full outline-none border-none "
                placeholder="search here"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div>
            <TableFilter />
          </div>
        </div>
        <TableCamp />
        <PaginationComp />
      </div>
    </>
  ) : (
    <EmptyState {...emptyState} />
  );
};

export default TableContainer;
