import React from "react";
import { Card, Avatar, Checkbox } from "antd";
import { parametersData } from "../constant/constant";
import Icon from "../components/Icon";

const Parameters = () => {
  return (
    <div className="flex justify-center items-center min-h-screen overflow-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {parametersData.map((item, index) => (
          <Card
            key={index}
            size="small"
            classNames="text-blue-950"
            title={
              <div className="flex items-center gap-2">
                <Icon name={`${item.icon}`} />
                {item.key}
              </div>
            }
            extra={<Checkbox defaultChecked />}
            className="w-full sm:w-[400px] md:w-[400px] h-[12rem] p-5 rounded-lg"
          >
            <p className="p-5">{item.description}</p>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Parameters;
