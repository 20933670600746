import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setInterventions, setSelectedIntervention } from '../../redux/slices/interventionSlice';
import SelectButton from '../SelectButton';
import { buttonInterventions, options } from '../../constant/constant';
import ClientInterventionNavigator from './navigators/ClientInterventionNavigator';
import { Tag } from 'antd';
import Icon from '../Icon';
import InputComp from '../InputComp';
import CustomDrawer from '../CustomDrawer';
import DrawerHeader from '../drawer/DrawerHeader';
import InterventionsDrawer from '../drawer/InterventionsDrawer';
import api from '../../utils/api';
import { addIntervention } from '../../redux/slices/appConfigSlice';
import ClientInterventionsDrawer from './drawer/ClientInterventionsDrawer';

const ClientInterventions = () => {

    const { userDetail } = useSelector(state => state.appConfigReducer);
   const { interventions, selectedIntervention } = useSelector(state => state.interventionReducer);

   const dispatch = useDispatch();

   const [selectedBtn, setSelectedBtn] = useState(0);
   const [inputData, setInputData] = useState("");
   const [checkedButtons, setCheckedButtons] = useState([]);
   const [drawerOpen, setDrawerOpen] = useState(false);

   const initialFormState = {
    intervenant: "",
    place: "",
    description: "",
  }

  const [formData, setFormData] = useState(initialFormState);

   const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAntDValues = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

   const onClickHandler = (intervention) => {
      dispatch(setSelectedIntervention(intervention))
   }

   const statusTagColor = (status="new") => {
      if(status == 'new') return 'magenta';
      if(status == 'in progress') return 'orange';
      if(status == 'completed') return 'green';
      if(status == 'cancelled') return 'red';
    }

    const handleSubmit = async (formData) => {
        try {
          const res = await api.post("/intervention/client", formData);
          dispatch(addIntervention(res.data));
          setDrawerOpen(false);
        } catch (error) {
          console.log("error occured while creating interventions", error);
        }
      }

   useEffect(() => {
      if (userDetail && userDetail?.intervention) {
         dispatch(setInterventions(userDetail.intervention));
      }
   }, [userDetail])

  return (
    <div className="h-screen p-3 flex gap-7 w-full">
         <div className="h-full flex flex-col gap-4">
            <div className='flex items-center justify-between h-16'>
            <h1 className="text-[#090F4E] text-2xl font-bold leading-10 py-3">
               Interventions
            </h1>
            <button
              onClick={() => setDrawerOpen(true)}
              className="p-2 bg-orange hover:shadow-lg h-10  transition ease-in text-white rounded flex items-center justify-center"
            >
              <Icon name="Plus" />
            </button>
            </div>
            <div className="h-full rounded-lg shadow Clientscrollbar bg-white flex flex-col gap-4 w-[500px] overflow-auto">

               <div className="flex flex-col gap-4 w-full p-4">
                  <InputComp setInputData={setInputData} inputData={inputData} />
                  <SelectButton
                     setSelecteBtn={setSelectedBtn}
                     selecteBtn={selectedBtn}
                     data={buttonInterventions}
                     checkedButtons={checkedButtons}
                     setCheckedButtons={setCheckedButtons}
                  />
               </div>

               {
                  interventions?.map((intervention) => {
                     return (
                        <div
                           key={intervention._id}
                           className={`flex transition ease-in cursor-pointer relative w-full items-center px-4 py-8 border-b border-gray-200 hover:bg-gray-100 ${selectedIntervention?._id == intervention._id && "bg-gray-100"}`}
                           onClick={() => onClickHandler(intervention)}
                        >
                           <div className="flex flex-col">
                              <div className="flex items-center gap-2"> 
                              <h3 className="text-[16px] font-bold">{intervention?.title}</h3>
                              <Tag color={statusTagColor(intervention?.status)}>{intervention?.status}</Tag>
                              </div>
                              <p>{intervention?.description}</p>
                              {
                                 intervention?.intervenant &&
                                 <p>{`${intervention?.intervenant?.firstName} ${intervention?.intervenant?.lastName}`}</p>
                              }
                              <div className="flex w-full justify-self-auto gap-1 ">
                                 <p className="text-[12px] font-bold flex gap-1 items-center text-gray-500 absolute bottom-2 right-2">
                                    <Icon name={"MapPin"} size={15} color={"gray"} />
                                    {intervention?.place}
                                 </p>
                              </div>
                           </div>
                        </div>
                     )
                  })
               }
            </div>
         </div>

         <div className="flex-1 flex">
            <ClientInterventionNavigator />
         </div>

         <CustomDrawer
        open={drawerOpen}
        width={600}
        onClose={() => setDrawerOpen(false)}
        title={
          <DrawerHeader
            title={"Création d'une Interventions"}
            icon={"UsersRound"}
          />
        }
      >
        <ClientInterventionsDrawer
          handleChange={handleChange}
          formData={formData}
          handleAntDValues={handleAntDValues}
          locations={options}
          intervenants={options}
          handleFormSubmit={handleSubmit}
        />
      </CustomDrawer>
      </div>
  )
}

export default ClientInterventions