import { useEffect, useState } from "react";
import api from "../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { setClientList } from "../redux/slices/clientSlice";

const useClients = () => {

   const [loading, setLoading] = useState(true);
   const { clientList } = useSelector((state) => state.clientReducer);
   const dispatch = useDispatch();

   const fetchClients = async () => {
      setLoading(true);
      try {
         const res = await api.get("/client");
         dispatch(setClientList(res.data.clients));
      } catch (error) {
         console.log("Something went wrong file fetching client list", error);
      }
      setLoading(false);
   };

   useEffect(() => {
      if (!clientList) {
         fetchClients();
      }
   }, [clientList]);

   return { loading };
};

export default useClients;
