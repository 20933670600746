export const locationData = [
  {
    address: "875 North Michigan Avenue",
    billingAddressSameAsPlaceOfIntervention: false,
    category: ["food service"],
    contactOnSite: "Ayoub",
    digicode: "DIGI1234",
    email: "mc@gmail.com",
    image:
      "https://monsterocu-dev-publicfiles.s3.eu-west-2.amazonaws.com/96cfcb71-c9a6-44f0-9455-54a3dc56956b__274_Planigramme_logo-02.png",
    orgId: "667b9e8d192c2c65ff73e784",
    phoneNumber: "212993333399",
    place: "Mcdo ainsbaa",
    reference: "1234",
    _id: 1,
  },
  {
    address: "Hanking Center",
    billingAddressSameAsPlaceOfIntervention: false,
    category: ["food service"],
    contactOnSite: "Ayoub",
    digicode: "DIGI1234",
    email: "mc@gmail.com",
    image:
      "https://monsterocu-dev-publicfiles.s3.eu-west-2.amazonaws.com/96cfcb71-c9a6-44f0-9455-54a3dc56956b__274_Planigramme_logo-02.png",
    orgId: "667b9e8d192c2c65ff73e784",
    phoneNumber: "212993333399",
    place: "Mcdo ainsbaa",
    reference: "1234",
    _id: 2,
  },
  {
    address: "432 Park Avenue",
    billingAddressSameAsPlaceOfIntervention: false,
    category: ["food service"],
    contactOnSite: "Ayoub",
    digicode: "DIGI1234",
    email: "mc@gmail.com",
    image:
      "https://monsterocu-dev-publicfiles.s3.eu-west-2.amazonaws.com/96cfcb71-c9a6-44f0-9455-54a3dc56956b__274_Planigramme_logo-02.png",
    orgId: "667b9e8d192c2c65ff73e784",
    phoneNumber: "212993333399",
    place: "Mcdo ainsbaa",
    reference: "1234",
    _id: 3,
  },
];
