import React from "react";
import { useSelector } from "react-redux";

const StaticMap = () => {
  const selectedLocation = useSelector(
    (state) => state.locationReducer.selectedLocation
  );

  const encodedAddress = encodeURIComponent(selectedLocation);

  return (
    <>
      {selectedLocation != null ? (
        <iframe
          width="100%"
          height="500"
          src={`https://maps.google.com/maps?width=100%&height=600&hl=en&q=${encodedAddress}&ie=UTF8&t=&z=14&iwloc=B&output=embed`}
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      ) : (
        ""
      )}
    </>
  );
};

export default StaticMap;
