import React from 'react'
import { useTranslation } from "react-i18next";

const DrawerTabs = ({ data, setSelectedTab, selectedTab }) => {
    const { t } = useTranslation();
    return (
        <div className='flex w-full rounded-2xl border overflow-hidden'>
            {
                data.map(d => (
                    <div key={d.id} onClick={() => setSelectedTab(d.code)} className={`font-semibold tab w-full border border-y-0 border-l-0 border-r-2 capitalize ${selectedTab === d.code ? "bg-[#302f38] active text-white" : "bg-white text-black"} cursor-pointer divide-x py-3 flex items-center justify-center`}>
                        {t(d.name)}
                    </div>
                ))
            }
        </div>
    )
}

export default DrawerTabs