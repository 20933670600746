import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const PhoneInputField = ({ placeholder, name, handleChange, value }) => {
    return (
        <PhoneInput
            country={'ma'}
            value={value}
            inputStyle={{ width: "100%", padding: "23px 55px" }}
            buttonStyle={{ padding: "20px 5px", backgroundColor: "white" }}
            placeholder={placeholder}
            onChange={(phone) => handleChange(phone, name)}
            onlyCountries={['ma']}
        />


    )
}

export default PhoneInputField