import React, { useState } from "react";
import OptionSwiper from "../OptionSwiper";
import { userListData, userListOptions } from "../../constant/constant";
import { useAppContext } from "../../context/Context";
import { Avatar } from "antd";
import LocationsList from "../lists/locationsList";
import InterventionsList from "../lists/interventionsList";

export default () => {
  const [activeTab, setActiveTab] = useState("details");

  return (
    <div className="w-[500px] flex flex-col flex-1 gap-2">
      <OptionSwiper
        options={userListOptions}
        {...{ activeTab, setActiveTab }}
        sliderSettings={{ slidesToShow: 3 }}
      />
      <div className="overflow-auto">
        <RenderTabs activeTab={activeTab} />
      </div>
    </div>
  );
};

const RenderTabs = ({ activeTab }) => {
  const { selectedUserId } = useAppContext();

  const filteredData = userListData.filter((item) => item.id == selectedUserId);

  switch (activeTab) {
    case "location":
      return <LocationsList />;
    case "details":
      return (
        <div className="bg-white rounded-lg p-4">
          {filteredData.map((data) => (
            <div className="px-1 flex flex-col  gap-4" key={data.id}>
              <div className="flex items-center text-bold gap-4 leading-normal">
                <Avatar src={data.avatar} />
                <div className="flex items-center gap-2">
                  <h6 className="font-bold">{data.clientName}</h6>
                  <div
                    type="button"
                    class="py-1 px-2 my-2 text-[12px] font-medium  bg-gray-200 rounded-2xl border text-dark border-gray-200 "
                  >
                    {data.role}
                  </div>
                </div>
              </div>
              {data.details &&
                data.details.map((d, i) => (
                  <div key={i} className="flex items-center gap-4">
                    <div>{d.icon}</div>
                    <div>
                      <h4 class="text-slate-700 font-bold text-[14px] ">
                        {d.name}
                      </h4>
                      <p class="text-gray-500 text-[12px]">{d.value}</p>
                    </div>
                  </div>
                ))}
            </div>
          ))}
        </div>
      );

    case "interventions":
      return <InterventionsList />;
  }
};
